import { DOCUMENTOS_TRANSPARENCIA_SET, DOCUMENTO_TRANSPARENCIA_SET, DOCUMENTO_TRANSPARENCIA_ID_SET, DOCUMENTOS_TRANSPARENCIA_REQUEST, DOCUMENTO_TRANSPARENCIA_UPDATED, DELETE_DOCUMENTO_TRANSPARENCIA } from "../actions/types";

const INITIAL_STATE = {
  documentosTransparencia: [],
  documentoTransparencia: null,
  documentoTransparenciaId: null,
  historico: [],
  loading: false
};

function documentoTransparenciaReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DOCUMENTOS_TRANSPARENCIA_SET: {
      return { ...state, documentosTransparencia: action.documentosTransparencia, loading: false }
    }
    case DOCUMENTOS_TRANSPARENCIA_REQUEST: {
      return { ...state, loading: true }
    }
    case DOCUMENTO_TRANSPARENCIA_SET: {
      return { ...state, documentoTransparencia: action.documentoTransparencia, loading: false }
    }
    case DOCUMENTO_TRANSPARENCIA_ID_SET: {
      return { ...state, documentoTransparenciaId: action.documentoTransparenciaId }
    }
    case DOCUMENTO_TRANSPARENCIA_UPDATED: {
      const { documentosTransparencia } = state
      const { id, data } = action.payload
      const index = documentosTransparencia.findIndex(f => f.id === id)
      const documentoTransparencia = { ...documentosTransparencia[index], ...data }
      documentosTransparencia.splice(index, 1, documentoTransparencia)
      return { ...state, documentosTransparencia: [...documentosTransparencia] }
    }
    case DELETE_DOCUMENTO_TRANSPARENCIA: {
      const { documentosTransparencia } = state
      const index = documentosTransparencia.findIndex(f => f.id === action.payload.id)
      documentosTransparencia.splice(index, 1)
      return { ...state, documentosTransparencia: [...documentosTransparencia] }
    }
    default:
      return state;
  }
}

export default documentoTransparenciaReducer;
