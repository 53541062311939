export default theme => ({
    root: {
      flexGrow: 1,
    },
    relatorio: {
      flexGrow: 1,
    },
    demo: {
      backgroundColor: theme.palette.background.paper
    },
    title: {
      margin: `${theme.spacing(1) * 4}px 0 ${theme.spacing(1) * 2}px`
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    button: {
      marginTop: 18
    },
    areaOperacoes: {
      marginTop: 16,
      marginBottom: 16
    },
    containerBottomPadding: {
      paddingBottom: 80
    }  
  });
  