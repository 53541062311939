import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { compose } from "recompose";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import CheckboxGroup from "../commons/CheckboxGroup";
import InputText from "../commons/InputText";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import styles from "./styles";
import { getEscritoriosAdvocacia } from "../../actions/juridico";
import { saveUser, getUser } from "../../actions/user";
import { functions } from "../../firebase";
import { validateEmail } from "../commons/util";


class Usuario extends React.Component {
  componentDidMount() {
    this.props.getEscritoriosAdvocacia();
  }

  componentDidUpdate() {
    if (this.props.userId) {
      this.props.getUser(this.props.userId);
    }
  }

  save(values) {
    const { userId, saveUser, email } = this.props;
    const currentEmail = email

    if (currentEmail !== values.email) {
      this.updateEmailAuth(values.email)
    }

    saveUser(userId, values, () => this.successHandler());
  }

  updateEmailAuth = (email) => {
    const { enqueueSnackbar, reset } = this.props
    const uid = this.props?.userId
    functions.changeUserEmail(uid, email).then(() => {
      enqueueSnackbar("Email alterado com sucesso!", { variant: "success" })
      reset();
    })
      .catch((error) => {
        enqueueSnackbar(`Erro oa alterar o email: ${error.message}`, { variant: "error" })
      })

  }

  successHandler(reload) {
    const { reset, handleClose, enqueueSnackbar } = this.props;
    enqueueSnackbar("Usuário alterado com sucesso!", {
      variant: "success",
    });
    reset();
    handleClose(reload);
  }

  render() {
    const {
      reset,
      classes,
      handleSubmit,
      pristine,
      submitting,
      escritoriosAdvocacia,
      canEdit
    } = this.props;

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        fullWidth
        maxWidth="md"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Editar usuário</DialogTitle>
        <form
          className={classes.container}
          onSubmit={handleSubmit(this.save.bind(this))}
        >
          <DialogContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item sm={3}>
                <Field name="displayName" component={InputText} label="Nome" disabled={!canEdit} />
              </Grid>
              <Grid item sm={4}>
                <Field name="email" component={InputText} label="Email" disabled={!canEdit} validate={validateEmail} />
              </Grid>
              <Grid item sm={12}>
                <CheckboxGroup
                  name="escritorios"
                  title="Escritórios associados"
                  options={escritoriosAdvocacia.map((e) => ({
                    label: e.nome,
                    value: e.id,
                  }))}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => reset() && this.props.handleClose(null)}
              color="default"
            >
              Fechar
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={pristine || submitting}
              color="primary"
            >
              Salvar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

const validate = (values) => {
  const errors = {}
  const requiredFields = [
    "displayName",
    "email"
  ]
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Obrigatório"
    }
  })
  return errors;
}

const mapStateToProps = (state) => ({
  escritoriosAdvocacia: state.juridico.escritoriosAdvocacia,
  userId: state.userState.userId,
  email: state.userState.user?.email,
  initialValues: state.userState.user,
});

Usuario.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getEscritoriosAdvocacia, getUser, saveUser })
)(
  reduxForm({
    form: "usuarioEscritorio",
    validate,
    enableReinitialize: true,
  })(withSnackbar(Usuario))
);
