import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import DateFnsUtils from "@date-io/date-fns";
import ptLocale from "date-fns/locale/pt-BR";
import Grid from "@material-ui/core/Grid";
import { KeyboardDatePicker } from '@material-ui/pickers';

const dateFns = new DateFnsUtils({ locale: ptLocale });

class RangeDatePicker extends PureComponent {

    handlePickDate(date) {
        const { fromDate, toDate, onChange } = this.props;
        onChange({ fromDate, toDate, ...date })
    }

    render() {
        const { fromDate, toDate, dateFormat } = this.props;
        return (
            <React.Fragment>
                <Grid item md={6}>
                    <KeyboardDatePicker autoOk clearable label="Data inicial" format={dateFormat} value={fromDate} onChange={(date) => this.handlePickDate({ fromDate: dateFns.startOfDay(date) })} />
                </Grid>
                <Grid item md={6}>
                    <KeyboardDatePicker autoOk clearable label="Data final" format={dateFormat} value={toDate} onChange={(date) => this.handlePickDate({ toDate: dateFns.endOfDay(date) })} />
                </Grid>
            </React.Fragment>
        );
    }
}

RangeDatePicker.propTypes = {
    fromDate: PropTypes.instanceOf(Date),
    toDate: PropTypes.instanceOf(Date),
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    dateFormat: PropTypes.string,
    closeDialogOnSelection: PropTypes.bool
};

RangeDatePicker.defaultProps = {
    fromDate: null,
    toDate: null,
    className: '',
    dateFormat: 'dd/MM/yyyy'
};

export default RangeDatePicker;