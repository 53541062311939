import React from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { getItensReceita, setItemReceitaId, setItemReceita, saveItemReceita, deleteItemReceita } from "../../actions/item_receita";
import { getReceitas } from "../../actions/financial";
import tableI18n from "../TableLocalization";
import ItemReceitaForm from "./item_receita";
import ConfirmDialog from "../commons/ConfirmDialog";
import SindilegisBar from "../commons/SindilegisBar";
import styles from "./styles";
import Loading from "../commons/Loading";
import * as routes from "../../constants/routes";
import * as roles from "../../constants/roles";
import ButtonComponent from "../commons/ButtonComponent";
import { getRoutes } from "../commons/util";
import MenuButton from "../commons/MenuButton";
import _ from 'lodash'

class ItensReceita extends React.Component {
  state = {
    open: false,
    confirmOpen: false,
    confirmAction: Function,
    confirmMessage: ''
  };

  componentDidMount() {
    this.props.getReceitas();
    this.props.getItensReceita();
  }

  handleItemReceitaClickOpen = () => {
    this.setState({ open: true });
  };

  handleItemReceitaClose = reload => {
    this.setState({ open: false });
    this.props.setItemReceita(null);
    this.props.setItemReceitaId(null);
    if (reload) {
      this.reloadItensItemReceita();
    }
  };

  handleConfirmOpen = (confirmAction, confirmMessage) => {
    this.setState({ confirmOpen: true, confirmAction, confirmMessage });
  };

  handleConfirmClose = confirm => {
    if (confirm) {
      this.state.confirmAction();
      this.setState({ confirmOpen: false });
    } else {
      this.setState({ confirmOpen: false });
    }
  };

  reloadItensItemReceita() {
    this.props.getItensReceita()
  }

  render() {
    const { classes, itens_receita, loading, authUser, location } = this.props;
    const canEdit = _.includes(authUser.roles, roles.ADMIN_FINANCEIRO);
    const currentPath = location.pathname
    const buttonsRoutes = getRoutes( routes, currentPath)
    return (
      <div className={classes.root}>
        <SindilegisBar />
        <Loading loading={loading} />
        <MenuButton buttonsRoutes={buttonsRoutes} location={location}/>
        <Paper className={classes.paper} elevation={1} hidden={loading}>
          {canEdit && (
            <Grid container item md={12} justifyContent="flex-end" spacing={2}>
              <Grid item>
                <ButtonComponent
                  variant="insert"
                  onClick={() => this.handleItemReceitaClickOpen()}
                >Cadastrar item de receita</ButtonComponent>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <Typography variant="h6" className={classes.title} />
            <div className={classes.demo}>
              <MaterialTable
                columns={[
                  { title: "Tipo", field: "tipo" },
                  { title: "Item de receita", field: "nome" },
                  { title: "Descrição", field: "descricao" }
                ]}
                data={itens_receita}
                title="Itens de receita"
                actions={canEdit ? [
                  {
                    icon: "edit",
                    tooltip: "Editar",
                    onClick: (event, row) => {
                      this.props.setItemReceitaId(row.id);
                      this.handleItemReceitaClickOpen();
                    }
                  },
                  {
                    icon: "delete",
                    tooltip: "Excluir",
                    onClick: (event, row) => this.handleConfirmOpen(
                      () => this.removeItemReceita(row),
                      'Confirma a exclusão permanente do item de receita?'
                    )
                  },
                  row => ({
                    icon: row.desabilitado ? "add" : "block",
                    iconProps: {
                      color: row.desabilitado ? "primary" : "error"
                    },
                    tooltip: row.desabilitado ? "Habilitar" : "Desabilitar",
                    onClick: (event, row) => {
                      const action = row.desabilitado
                        ? () => this.habilitarItemReceita(row.id)
                        : () => this.desabilitarItemReceita(row.id);
                      const message = row.desabilitado
                        ? 'Deseja habilitar o item de receita?'
                        : 'Deseja desabilitar o item de receita?';
                      this.handleConfirmOpen(action, message);
                    }
                  })
                ] : []}
                options={{
                  actionsColumnIndex: -1
                }}
                localization={tableI18n}
              />
            </div>
          </Grid>
          <ItemReceitaForm
            open={this.state.open}
            handleClose={this.handleItemReceitaClose}
          />
          <ConfirmDialog
            open={this.state.confirmOpen}
            message={this.state.confirmMessage}
            onClose={this.handleConfirmClose}
          />
        </Paper>
      </div>
    );
  }

  habilitarItemReceita(id) {
    const { enqueueSnackbar } = this.props;
    this.props.saveItemReceita(id, { desabilitado: false }, () => enqueueSnackbar("Item de receita habilitado com sucesso.", {
      variant: "success"
    }))
  }

  desabilitarItemReceita(id) {
    const { enqueueSnackbar } = this.props;
    this.props.saveItemReceita(id, { desabilitado: true }, () => enqueueSnackbar("Item de receita desabilitado com sucesso.", {
      variant: "success"
    }))
  }

  removeItemReceita(item) {
    const { enqueueSnackbar, receitas } = this.props;
    if (receitas.some(e => e.item_receita === item.nome)) {
      enqueueSnackbar("O item de receita está associado a uma ou mais receitas e não pode ser excluído.", {
        variant: "error"
      })
      return;
    }

    this.props.deleteItemReceita(item.id)
  }
}

const mapStateToProps = ({ item_receita: { itens_receita }, financial: { receitas, loading } }) => ({ itens_receita, loading, receitas });

ItensReceita.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    { getItensReceita, setItemReceitaId, setItemReceita, saveItemReceita, deleteItemReceita, getReceitas }
  ),
)(withRouter(withSnackbar(ItensReceita)));
