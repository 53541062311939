import { DOCUMENTOS_SET, DOCUMENTO_SET, DOCUMENTO_ID_SET, DOCUMENTOS_REQUEST, DOCUMENTO_UPDATED, DELETE_DOCUMENTO } from "../actions/types";

const INITIAL_STATE = {
  documentos: [],
  documento: null,
  documentoId: null,
  historico: [],
  loading: false
};

function documentoReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DOCUMENTOS_SET: {
      return { ...state, documentos: action.documentos, loading: false }
    }
    case DOCUMENTOS_REQUEST: {
      return { ...state, loading: true }
    }
    case DOCUMENTO_SET: {
      return { ...state, documento: action.documento, loading: false }
    }
    case DOCUMENTO_ID_SET: {
      return { ...state, documentoId: action.documentoId }
    }
    case DOCUMENTO_UPDATED: {
      const { documentos } = state
      const { id, data } = action.payload
      const index = documentos.findIndex(f => f.id === id)
      const documento = { ...documentos[index], ...data }
      documentos.splice(index, 1, documento)
      return { ...state, documentos: [...documentos] }
    }
    case DELETE_DOCUMENTO: {
      const { documentos } = state
      const index = documentos.findIndex(f => f.id === action.payload.id)
      documentos.splice(index, 1)
      return { ...state, documentos: [...documentos] }
    }
    default:
      return state;
  }
}

export default documentoReducer;
