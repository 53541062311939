import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import deepOrange from "@material-ui/core/colors/deepOrange";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { auth } from "../../firebase";
import rolePage from "../ProfilePage/rolePage";
import { AuthConsumer } from '../Auth/AuthContext';
import * as routes from "../../constants/routes";
import Tooltip from "@material-ui/core/Tooltip";
import _ from "lodash";
import Home from '@material-ui/icons/Home';
import Box from '@material-ui/core/Box';
import Settings from "@material-ui/icons/Settings"
import { Button, Typography } from "@material-ui/core";

const styles = {
  root: {
    width: "100%"
  },
  rightToolbar: {
    marginLeft: "auto",
    marginRight: -12
  },
  purpleAvatar: {
    color: "#fff",
    backgroundColor: deepOrange[500]
  },
  gradientLine: {
    height: '4px',
    background: 'linear-gradient(to right, #F58A75, #78CDD1, #82B450, #EBD539)',
    marginTop: '-5px'
  }
};

class SindilegisBar extends Component {
  state = {
    pages: [],
    letter: "S"
  };

  handleChange = event => {
    this.setState({ auth: event.target.checked });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <AuthConsumer>
        {({ authUser }) => {
          const { roles, email } = authUser;
          const pages = _.uniqBy(roles.map(r => rolePage[r]), "name");
          const letter = email ? _.toUpper(email).slice(0, 3) : "S";

          return (
            <div className={classes.root}>
              <AppBar position="static" color="default" elevation={0}>
                <Toolbar>
                  <Link to={routes.PROFILE}>
                    <img src="/img/sindilegis-logo.svg" height="50" alt="Sindilegis" />
                  </Link>
                  <section className={classes.rightToolbar}>
                    <IconButton style={{ padding: 15 }} component={Link} to={routes.PROFILE}>
                      <Home fontSize="medium" color="primary" />
                    </IconButton>
                    <Tooltip title="Service desk" placement="top">
                      <Button style={{ padding: 15, textTransform: 'none' }}>
                        <Link to={{ pathname: "https://ticketsindilegis.com/" }} target="_blank" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                          <Settings color="primary" fontSize="small" />
                          <Typography style={{ color: 'black', marginLeft: 8 }}>Service Desk</Typography>
                        </Link>
                      </Button>
                    </Tooltip>

                    <IconButton
                      aria-owns={open ? "menu-appbar" : undefined}
                      aria-haspopup="true"
                      onClick={this.handleMenu}
                    >
                      <Avatar className={classes.purpleAvatar}>
                        {letter}
                      </Avatar>
                    </IconButton>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right"
                      }}
                      open={open}
                      onClose={this.handleClose}
                    >
                      {pages.map(page => page ? this.renderMenuItem(page.name, page.route) : console.error('perfil não encontrado', roles))}
                      <MenuItem onClick={() => auth.doSignOut()}>Sair</MenuItem>
                    </Menu>
                  </section>
                </Toolbar>
                <Box className={classes.gradientLine} />
              </AppBar>
            </div>
          )
        }}
      </AuthConsumer>
    );
  }

  renderMenuItem(name, route) {
    return (
      <MenuItem key={route} component={Link} to={route}>{name}</MenuItem>
    );
  }
}

export default compose(
  withStyles(styles),
)(SindilegisBar);
