import {COMUNICACAO_FILIADOS_SET} from "../actions/types";

const INITIAL_STATE = {
    filiadosAComunicar: []
};

function comunicacaoReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case COMUNICACAO_FILIADOS_SET: {
      return { ...state, filiadosAComunicar: action.filiadosAComunicar, loading: false }
    }
    default:
      return state;
  }
}

export default comunicacaoReducer;
