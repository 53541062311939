import React from "react";
import {
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import FolderIcon from "@material-ui/icons/Folder";

function PerfilPolitico(props) {
  const {
    nome,
    nomePolitico,
    idade,
    filiacao,
    biografia,
    exerceMandato,
    cargos,
    atividades,
    noticias,
    tel1,
    tel2,
    email1,
    email2,
    redesSociais,
    almaMater,
    premios,
    projetos,
    comissoes,
    votacoes,
    interesses,
    afiliacoes,
    publicacoes,
    indices,
    endossos,
    parentesco,
    aliados,
    religiao,
    linguas,
    formacao,
    atividadesProfissionais,
    agendaSindilegis,
    agendaFutura,
    curiosidades,
    anexos,
  } = props;

  const formatCargos = (cargos) => {
    if (Array.isArray(cargos)) {
      return cargos
        .map((cargo) => `- ${cargo.cargo}, ${cargo.tempo}`)
        .join("\n");
    }
    return cargos;
  };

  const renderAnexos = (anexos, handleArquivosOpen) => {
    return (
      <Tooltip title={anexos ? "Documentos" : "Não há documentos"}>
        <IconButton
          color={anexos ? "primary" : "default"}
          component="span"
          size="small"
          onClick={anexos ? handleArquivosOpen : null}
        >
          <FolderIcon />
        </IconButton>
      </Tooltip>
    );
  };

  const itens = [
    { label: "Nome", value: nome },
    { label: "Nome Político", value: nomePolitico },
    { label: "Idade", value: idade },
    { label: "Histórico de Filiação Partidária", value: filiacao },
    { label: "Biografia", value: biografia },
    { label: "Exerce mandato atualmente?", value: exerceMandato },
    {
      label: "Cargos e tempo de mandato de cada cargo",
      value: formatCargos(cargos),
    },
    { label: "Principais atividades políticas", value: atividades },
    { label: "Notícias", value: noticias },
    { label: "Telefone Primário", value: tel1 },
    { label: "Telefone Secundário", value: tel2 },
    { label: "Email Primário", value: email1 },
    { label: "Email Secundário", value: email2 },
    { label: "Redes Sociais", value: redesSociais },
    { label: "Alma Mater", value: almaMater },
    { label: "Prêmios", value: premios },
    { label: "Projetos de Lei Propostos", value: projetos },
    { label: "Comissões", value: comissoes },
    { label: "Histórico de Votação", value: votacoes },
    { label: "Interesses Políticos Específicos", value: interesses },
    { label: "Afiliações a Grupos ou Blocos Políticos", value: afiliacoes },
    { label: "Publicações", value: publicacoes },
    { label: "Índices de Aprovação", value: indices },
    { label: "Endosso de Organizações", value: endossos },
    { label: "Parentesco", value: parentesco },
    { label: "Aliados políticos", value: aliados },
    { label: "Religião", value: religiao },
    { label: "Línguas Faladas", value: linguas },
    { label: "Formação Adicional", value: formacao },
    { label: "Atividades profissionais", value: atividadesProfissionais },
    { label: "Agendas com o Sindilegis", value: agendaSindilegis },
    { label: "Possível agenda futura com o Sindilegis", value: agendaFutura },
    { label: "Curiosidades", value: curiosidades },
    { label: "Anexos", value: renderAnexos(anexos, props.handleArquivosOpen) },
  ];

  return (
    <Paper
      elevation={3}
      style={{
        borderRadius: 15,
        padding: 20,
        marginBottom: 20,
        width: "auto",
        margin: "50px auto",
      }}
    >
      <Typography variant="h5" gutterBottom>
        <strong>Perfil</strong>
      </Typography>
      <List>
        {itens.map((item, index) => (
          <ListItem key={index}>
            <ListItemText
              primary={
                <Typography style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                  {item.label}
                </Typography>
              }
              secondary={
                <Typography
                  style={{ fontSize: "1rem", whiteSpace: "pre-line" }}
                >
                  {item.value}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
}

export default PerfilPolitico;
