import { USERS_SET, USERS_REQUEST, USER_SET, USER_ID_SET } from "../actions/types";

const INITIAL_STATE = {
  users: [],
  user: {},
  userId: null,
  loading: false
};

function userReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case USERS_SET:
      return { ...state, users: action.users, loading: false }
    case USERS_REQUEST:
      return { ...state, loading: true }
    case USER_SET:
      return { ...state, user: action.user }
    case USER_ID_SET:
      return { ...state, userId: action.userId }
    default:
      return state;
  }
}

export default userReducer;
