import React, { useEffect, useState } from "react";
import { Grid, TextField, Typography, Paper } from "@material-ui/core";
import { getFiliados } from "../../../actions/filiado";
import { useDispatch, useSelector } from "react-redux";
import { formatarValor } from "./JuriFiliCalculator";
import ButtonComponent from "../../commons/ButtonComponent";
import styles from "./Calculators.module.css";

const BeneficiosFiliado = () => {
  const dispatch = useDispatch();
  const { filiados } = useSelector((state) => ({
    filiados: state.filiado.filiados,
  }));
  const filiadosAtivos = filiados.filter(
    (filiado) => filiado.situacao_filiado !== "INATIVO"
  );

  useEffect(() => {
    dispatch(getFiliados());
  }, [dispatch]);

  const [mf, setMf] = useState("");
  const [cp, setCp] = useState("");
  const [rc, setRc] = useState("");
  const [uf, setUf] = useState("");
  const [result, setResult] = useState(0);

  const handleCalcular = () => {
    const mfNumerico = Number(mf.replace(/\D/g, "")) / 100;
    const cpNumerico = Number(cp.replace(/\D/g, "")) / 100;
    const rcNumerico = Number(rc.replace(/\D/g, "")) / 100;

    const calculoResult =
      mfNumerico - (cpNumerico - rcNumerico) / filiadosAtivos.length;
    setResult(calculoResult);
  };

  const handleLimpar = () => {
    setMf("");
    setCp("");
    setRc("");
    setUf("");
    setResult(0);
  };

  return (
    <Paper elevation={3} className={styles.bodyCalculator}>
      <Typography variant="h5" align="start" gutterBottom>
        Cálculo do Custo-Filiado para o Núcleo de Benefícios - R${" "}
        {result.toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Valor da mensalidade paga pelo filiado"
            value={mf}
            onChange={(e) => setMf(formatarValor(e.target.value))}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label=" Custo total pago à Porto Seguro"
            value={cp.toLocaleString("pt-BR")}
            onChange={(e) => setCp(formatarValor(e.target.value))}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Recebimento da clínica odontológica pelo aluguel do espaço"
            value={rc.toLocaleString("pt-BR")}
            onChange={(e) => setRc(formatarValor(e.target.value))}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Frequência de uso do serviço pelo filiado"
            value={uf}
            onChange={(e) => setUf(e.target.value)}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={12} container justifyContent="center">
          <Grid item xs={12} sm={3} container justifyContent="center">
            <ButtonComponent
              variant="insert"
              size="large"
              onClick={handleCalcular}
            >
              Calcular
            </ButtonComponent>
            <ButtonComponent
              variant="secondary"
              size="large"
              onClick={handleLimpar}
            >
              Limpar
            </ButtonComponent>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default BeneficiosFiliado;
