import React from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Typography,
  Grid,
  Box,
  Container,
} from '@material-ui/core';
import MaterialTable from "material-table";
import { withStyles } from "@material-ui/core/styles";
import tableI18n from "../TableLocalization";
import SindilegisBar from "../commons/SindilegisBar";
import styles from "./styles";
import Loading from "../commons/Loading";
import AutoridadeForm from "./autoridade";
import { getAutoridadesPoliticas, deleteAutoridadePolitica, setAutoridadePolitica, setAutoridadePoliticaId } from "../../actions/autoridadePolitica";
import _ from 'lodash'
import * as roles from "../../constants/roles"
import ConfirmDialog from "../commons/ConfirmDialog";
import { storage } from "../../firebase";
import { CircularProgress } from "@material-ui/core";
import { IMG_DEFAULT } from "./consts";
import ButtonComponent from "../commons/ButtonComponent";

class Autoridades extends React.Component {
  state = {
    open: false,
    confirmOpen: false,
    confirmAction: Function,
    confirmMessage: "",
    isCreatingNew: false,
    imageUrls: {},
    loadingImages: {}
  };

  componentDidMount() {
    this.props.getAutoridadesPoliticas();
    this.fetchImageUrls();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.fetchImageUrls()
    }
  }

  fetchImageUrls = () => {
    const { politicos } = this.props;

    Object.values(politicos).forEach(politico => {
      if (politico.photo && politico.photo.url) {
        const photoPath = politico.photo.url;

        this.setState(prevState => ({
          loadingImages: {
            ...prevState.loadingImages,
            [politico.id]: true
          }
        }));

        storage.fetchImageUrlStorage(photoPath)
          .then((url) => {
            this.setState(prevState => ({
              imageUrls: {
                ...prevState.imageUrls,
                [politico.id]: url
              },
              loadingImages: {
                ...prevState.loadingImages,
                [politico.id]: false
              }
            }));
          })
          .catch((error) => {
            console.error("Erro ao recuperar a URL da imagem:", error);
            this.setState(prevState => ({
              loadingImages: {
                ...prevState.loadingImages,
                [politico.id]: false
              }
            }));
          });
      }
    });
  };

  handlePerfilClickOpen = () => {
    this.setState({ open: true, isCreatingNew: true });
  }

  handlePerfilClickClose = (reload) => {
    this.props.setAutoridadePolitica(null);
    this.props.setAutoridadePoliticaId(null);
    this.setState({ open: false, isCreatingNew: false });
    if (reload) {
      this.props.getAutoridadesPoliticas();
    }
  }

  handleRowClick = (rowData) => {
    this.props.history.push({
      pathname: `/Autoridades/${rowData.id}`, state: { politicoId: rowData.id }
    });
  }

  handleConfirmOpen = (confirmAction, confirmMessage) => {
    this.setState({ confirmOpen: true, confirmAction, confirmMessage });
  }

  handleConfirmClose = (confirm) => {
    if (confirm) {
      this.state.confirmAction();
    }
    this.setState({ confirmOpen: false });
  };

  renderImage(rowData) {
    const { imageUrls, loadingImages } = this.state
    const loading = loadingImages[rowData.id];
    return (
      <Box display="flex" alignItems="flex-start" justifyContent="flex-start">
        {loading ? (
          <CircularProgress size={50} />
        ) : (
          <img
            src={imageUrls[rowData.id] || IMG_DEFAULT}
            alt={rowData.nome}
            style={{ width: 50, height: 50, borderRadius: '50%' }}
          />
        )}
      </Box>
    );
  }

  fetchCargoAtual(row) {
    const cargos = row?.cargos || [];
    const cargoAtual = cargos.find(cargo => cargo.atual);
    return cargoAtual?.cargo || '';
  }

  render() {
    const { classes, loading, authUser, politicos } = this.props;
    const sortedPoliticos = Object.values(politicos).sort((a, b) => a.nome.localeCompare(b.nome));
    const localization = tableI18n;
    const canDelete = _.includes(authUser.roles, roles.GESTOR_SISTEMA);

    return (
      <div className={classes.root}>
        <SindilegisBar />
        <Loading loading={loading} />
        <Container maxWidth="xl">
          <Grid container
            item
            md={12}
            justifyContent="flex-end"
            spacing={2}
            className={classes.areaOperacoes}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Typography variant="h5" align="start" gutterBottom style={{ marginLeft: 10 }}>
                  PERFIS DE AUTORIDADES
                </Typography>
                <ButtonComponent
                  variant="insert"
                  onClick={this.handlePerfilClickOpen}
                >
                  Inserir novo perfil
                </ButtonComponent>
              </Grid>
              <Grid item xs={12}>
                <MaterialTable
                  columns={[
                    {
                      title: "Foto",
                      field: "photo",
                      render: rowData => this.renderImage(rowData)
                    },
                    {
                      title: (
                        <Box>
                          <Typography variant="h9">Nome</Typography>
                          <Typography variant="subtitle2">(Cargo)</Typography>
                        </Box>
                      ),
                      field: 'nome',
                      render: rowData => (
                        <Box>
                          <Typography
                            variant="body1"
                            style={{ cursor: 'pointer' }}
                            onClick={() => this.handleRowClick(rowData)}
                          >
                            {rowData.nome}
                          </Typography>
                          <Typography variant="body2"><i>{this.fetchCargoAtual(rowData)}</i></Typography>
                        </Box>
                      )
                    },
                    { title: "Idade", field: "idade" },
                    { title: "Partido", field: "partido" },
                    { title: "Exerce Mandato", field: "exerce_mandato" },
                  ]}
                  data={sortedPoliticos}
                  title=''
                  actions={[
                    canDelete ? {
                      icon: 'delete',
                      tooltip: 'Excluir',
                      onClick: (event, row) =>
                        this.handleConfirmOpen(
                          () => this.props.deleteAutoridadePolitica(row.id),
                          `Confirma a exclusão permanente de ${row.nome}?`
                        )
                    } : null
                  ]}
                  options={{
                    actionsColumnIndex: -1,
                    padding: 'dense'
                  }}
                  localization={localization}
                />
              </Grid>
            </Grid>
          </Grid>
          <AutoridadeForm
            open={this.state.open}
            handleClose={this.handlePerfilClickClose}
            isCreatingNew={this.state.isCreatingNew}
          />
          <ConfirmDialog
            open={this.state.confirmOpen}
            message={this.state.confirmMessage}
            onClose={this.handleConfirmClose}
          />
        </Container>
      </div>
    );
  }


}

const mapStateToProps = ({ autoridade_politica: { politicos } }) => ({ politicos });

Autoridades.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    { getAutoridadesPoliticas, deleteAutoridadePolitica, setAutoridadePoliticaId, setAutoridadePolitica }
  ),
)(withRouter(withSnackbar(Autoridades)));
