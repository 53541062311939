import _ from "lodash";
import {
  OBJETOS_PROCESSO_SET,
  OBJETO_PROCESSO_SET,
  OBJETO_PROCESSO_ID_SET,
  OBJETOS_PROCESSO_REQUEST,
  OBJETO_PROCESSO_UPDATED,
  DELETE_OBJETO_PROCESSO,
} from "./types";
import { db } from "../firebase";

export const saveObjetoProcesso = (id, data, callback) => {
  return (dispatch) => {
    db.doSaveObjetoProcesso(id, data).then(() => {
      dispatch({ type: OBJETO_PROCESSO_UPDATED, payload: { id, data } });
      callback && callback();
    });
  };
};

export const criarObjetoProcesso = (data, callback) => {
  return (dispatch) => {
    db.doCreateObjetoProcesso(data).then(() => {
      callback && callback();
    });
  };
};

export const getObjetosProcesso = () => {
  return (dispatch) => {
    dispatch({ type: OBJETOS_PROCESSO_REQUEST });
    db.onceGetObjetosProcesso().then((snapshot) => {
      let items = snapshot.val();
      if (items) {
        Object.keys(items).map((id) => {
          items[id].id = id;
          return items[id];
        });
        items = _.values(items);
      } else {
        items = [];
      }

      dispatch({ type: OBJETOS_PROCESSO_SET, objetosProcesso: items });
    });
  };
};

export const getObjetoProcesso = (id) => {
  return (dispatch) => {
    db.onceGetObjetoProcesso(id).then((snapshot) => {
      const objetoProcesso = snapshot.val();
      dispatch({ type: OBJETO_PROCESSO_SET, objetoProcesso });
    });
  };
};

export const deleteObjetoProcesso = (id) => {
  return (dispatch) => {
    db.doDeleteObjetoProcesso(id).then(() => {
      dispatch({ type: DELETE_OBJETO_PROCESSO, payload: { id } });
    });
  };
};

export const setObjetoProcesso = (objetoProcesso) => (dispatch) =>
  dispatch({ type: OBJETO_PROCESSO_SET, objetoProcesso });

export const setObjetoProcessoId = (objetoProcessoId) => (dispatch) =>
  dispatch({ type: OBJETO_PROCESSO_ID_SET, objetoProcessoId });
