export default theme => ({
  areaOperacoes: {
    marginTop: 16,
    marginBottom: 16
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
      margin: 0
    }
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  grow: {
    flexGrow: 1
  },
  button: {
    marginBottom: 10
  }
});
