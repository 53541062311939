import React, { useState } from "react";
import { Grid, TextField, Typography, Paper } from "@material-ui/core";
import ButtonComponent from "../../commons/ButtonComponent";
import styles from "./Calculators.module.css";

export const formatarValor = (valor) => {
  if (!valor) return "";
  const numero = Number(valor.replace(/\D/g, "")) / 100;
  return numero.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
};

const JuridicoFiliados = () => {
  const [mf, setMf] = useState("");
  const [cj, setCj] = useState("");
  const [pf, setPf] = useState("");
  const [pt, setPt] = useState("");
  const [nu, setNu] = useState("");
  const [result, setResult] = useState(0);

  const handleCalcular = () => {
    const mfNumerico = Number(mf.replace(/\D/g, "")) / 100;
    const cjNumerico = Number(cj.replace(/\D/g, "")) / 100;
    const pfNumerico = Number(pf);
    const ptNumerico = Number(pt);
    const nuNumerico = Number(nu);

    const calculoResult =
      mfNumerico - (pfNumerico / ptNumerico) * (cjNumerico / nuNumerico);
    setResult(calculoResult);
  };

  const handleLimpar = () => {
    setMf("");
    setCj("");
    setPf("");
    setPt("");
    setNu("");
    setResult(0);
  };

  return (
    <Paper elevation={3} className={styles.bodyCalculator}>
      <Typography variant="h5" align="start" gutterBottom>
        Cálculo do Custo-Filiado para o Núcleo Jurídico - R${" "}
        {result.toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Valor da mensalidade paga pelo filiado"
            value={mf}
            onChange={(e) => setMf(formatarValor(e.target.value))}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Custo total do escritório de advocacia contratado"
            value={cj}
            onChange={(e) => setCj(formatarValor(e.target.value))}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Número de ações judiciais abertas pelo filiado"
            value={pf}
            onChange={(e) => setPf(e.target.value)}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Número de filiados que utilizam o Núcleo Jurídico"
            value={nu}
            onChange={(e) => setNu(e.target.value)}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Número total de ações judiciais abertas por todos os filiados"
            value={pt}
            onChange={(e) => setPt(e.target.value)}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6} container justifyContent="center">
          <ButtonComponent
            variant="insert"
            size="large"
            onClick={handleCalcular}
          >
            Calcular
          </ButtonComponent>
          <ButtonComponent
            variant="secondary"
            size="large"
            onClick={handleLimpar}
          >
            Limpar
          </ButtonComponent>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default JuridicoFiliados;
