export default [
    '#F44336',
    '#4CAF50',
    '#00ACC1',
    '#FBC02D',
    '#F06292',
    '#AED581',
    '#7C4DFF',
    '#3949AB',
    '#EA80FC',
    '#4DB6AC',
    '#B388FF',
    '#64B5F6',
    '#2962FF',
    '#0D47A1',
    '#00B0FF',
    '#8C9EFF',
    '#536DFE',
    '#F48FB1',
    '#D4E157',
    '#FFD600',
    '#BA68C8',
    '#FF8F00',
    '#FFD180',
    '#4E342E',
    '#CE93D8',
]