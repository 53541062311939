export default theme => ({
  detail: {
    padding: theme.spacing(2),
  },
  root: {
    flexGrow: 1
  },
  button: {
    marginBottom: 10
  },
  areaOperacoes: {
    marginTop: 16,
    marginBottom: 16
  },
});
