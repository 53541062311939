import { ITENS_INTERACAO_SET, ITEM_INTERACAO_SET, ITEM_INTERACAO_ID_SET, ITENS_INTERACAO_REQUEST, ITEM_INTERACAO_UPDATED, DELETE_ITEM_INTERACAO } from "../actions/types";

const INITIAL_STATE = {
  itens_interacao: [],
  item_interacao: null,
  item_interacao_id: null,
  loading: false
};

function item_interacaoReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ITENS_INTERACAO_SET: {
      return { ...state, itens_interacao: action.itens_interacao, loading: false }
    }
    case ITENS_INTERACAO_REQUEST: {
      return { ...state, loading: true }
    }
    case ITEM_INTERACAO_SET: {
      return { ...state, item_interacao: action.item_interacao, loading: false }
    }
    case ITEM_INTERACAO_ID_SET: {
      return { ...state, item_interacao_id: action.item_interacao_id }
    }
    case ITEM_INTERACAO_UPDATED: {
      const { itens_interacao } = state
      const { id, data } = action.payload
      const index = itens_interacao.findIndex(f => f.id === id)
      const item_interacao = { ...itens_interacao[index], ...data }
      itens_interacao.splice(index, 1, item_interacao)
      return { ...state, itens_interacao: [...itens_interacao] }
    }
    case DELETE_ITEM_INTERACAO: {
      const { itens_interacao } = state
      const index = itens_interacao.findIndex(f => f.id === action.payload.id)
      itens_interacao.splice(index, 1)
      return { ...state, itens_interacao: [...itens_interacao] }
    }
    default:
      return state;
  }
}

export default item_interacaoReducer;
