import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AttachFile from "@material-ui/icons/AttachFile";
import LinearProgress from "@material-ui/core/LinearProgress";
import _ from "lodash";
import { uploadAnexoFiliado, removerAnexo, downloadAnexo } from "../../../firebase/storage";
import ConfirmDialog from "../../commons/ConfirmDialog";
import { withSnackbar } from "notistack";

const styles = (theme) => ({
    input: {
        display: "none"
    },
    progress: {
        width: '100%',
        marginTop: theme.spacing(2),
    }
});


class AnexoFiliado extends Component {
    state = {
        confirmOpen: false,
        confirmAction: () => { },
        confirmMessage: '',
        anexos: [],
        uploadProgress: 0,
    }

    constructor(props) {
        super(props);
        this.state.anexos = props.anexos || [];
    }

    componentDidUpdate(prevProps) {
        if (prevProps.anexos !== this.props.anexos) {
            this.setState({ anexos: this.props.anexos || [] });
        }
        if (!this.props.open && this.state.anexos) {
            this.setState({ anexos: [], uploadProgress: 0 });
        }
    }

    handleCapture = (event) => {
        const file = event.target.files[0];
        const { filiadoId } = this.props;

        if (!file) return;

        const uploadTask = uploadAnexoFiliado(filiadoId, file);

        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                this.setState({ uploadProgress: progress });
            },
            (error) => {
                console.error("Erro ao fazer upload do anexo:", error);
            },
            () => {
                uploadTask.snapshot.ref.getMetadata().then(({ fullPath }) => {
                    const anexo = { nome: file.name, url: fullPath, id: fullPath };
                    const anexos = Object.values(this.state.anexos);
                    const updatedAnexos = [...anexos, anexo];
                    this.setState({ anexos: updatedAnexos, uploadProgress: 0 });
                    this.props.onChange(updatedAnexos);
                });
            }
        );
    };

    handleDelete = (item) => {
        this.handleConfirmOpen(() => {
            removerAnexo(item.url)
                .then(() => {
                    const novosAnexos = this.state.anexos.filter((a) => a.url !== item.url);
                    this.setState({ anexos: novosAnexos }, () => {
                        this.props.onChange(novosAnexos);
                        this.props.enqueueSnackbar("Anexo excluído com sucesso!", { variant: "success" });
                    });
                })
                .catch((e) => console.error("Erro ao remover o anexo:", e));
        }, "Confirma a exclusão do anexo?");
    };


    handleConfirmOpen = (confirmAction, confirmMessage) => {
        this.setState({ confirmOpen: true, confirmAction, confirmMessage });
    };

    handleConfirmClose = (confirm) => {
        if (confirm && this.state.confirmAction) {
            this.state.confirmAction();
        }
        this.setState({ confirmOpen: false });
    };

    renderAnexo(item) {
        return (
            <Chip
                key={item.url}
                onDelete={() => this.handleDelete(item)}
                onClick={() => downloadAnexo(item.url)}
                label={item.nome}
                clickable
                variant="outlined"
            />
        );
    }

    render() {
        const { classes } = this.props;
        const { anexos, uploadProgress } = this.state;
        const isAnexosEmpty = !Array.isArray(anexos) || anexos.every(item => !item);

        return (
            <Fragment>
                {uploadProgress > 0 && (
                    <LinearProgress
                        variant="determinate"
                        value={uploadProgress}
                        className={classes.progress}
                    />
                )}
                <Grid item sm={12} spacing={2}>
                    {_.map(anexos, (item, index) => this.renderAnexo(item, index))}
                </Grid>
                {isAnexosEmpty && (
                    <Grid item sm={12} spacing={2}>
                        <input
                            className={classes.input}
                            id="upload-anexo-filiado"
                            onChange={this.handleCapture}
                            type="file"
                        />
                        <label htmlFor="upload-anexo-filiado">
                            <Button
                                color="primary"
                                component="span"
                                size="small"
                                style={{ marginRight: 8 }}
                            >
                                <AttachFile />
                                Anexar Ficha
                            </Button>
                        </label>
                    </Grid>
                )
                }
                <ConfirmDialog
                    open={this.state.confirmOpen}
                    message={this.state.confirmMessage}
                    onClose={this.handleConfirmClose}
                />
            </Fragment>

        );

    }


}

export default connect(null, {})(
    withStyles(styles, { withTheme: true })(withSnackbar(AnexoFiliado))
);
