import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import Link from '@material-ui/icons/Link'
import AttachFile from '@material-ui/icons/AttachFile'
import { withSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { reduxForm } from 'redux-form'
import { storage } from '../../firebase'
import { ACCEPT_INPUT_FILE, getDocumentosBy, fileUploadCheck, steps, getObjectByKey } from '../../controllers/temaController'

import { createTema, getTema, saveTema, setTema, setTemaId, incluirDocumentoTema, incluirLinkTema } from '../../actions/politico_sindical'
import styles from './styles'
import validate from './validate'
import ConfirmDialog from '../commons/ConfirmDialog'
import TemaLinkFormDialog from './TemaLinkFormDialog'
import LinkChip from './LinkChip'

class TemaStepInfoGeraisForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      confirmOpen: false,
      confirmAction: Function,
      confirmMessage: '',
    }
  }

  componentDidMount () {
    const { temaId } = this.props
    if (temaId) {
      this.props.getTema(temaId)
    }
  }

  handleCapture = (event) => {
    const file = event.target.files[0]
    const { temaId, tema } = this.props

    const documentos = getDocumentosBy(tema)
    const errorsCheck = fileUploadCheck(documentos, file)
    if (_.isEmpty(errorsCheck)) {
      storage.uploadDocumentoTema(`${temaId}/${file.name}`, file).then(({ metadata }) => {
        const documento = { nome: file.name, url: metadata.fullPath }
        this.props.incluirDocumentoTema(temaId, documento)
        this.props.getTema(temaId)
      })
    } else {
      const { enqueueSnackbar } = this.props
      _.each(errorsCheck, err => {
        enqueueSnackbar(err.msg, { variant: err.variant })
      })
    }
  }

  handleConfirmClose = confirm => {
    if (confirm) {
      this.state.confirmAction()
      this.setState({ confirmOpen: false })
    } else {
      this.setState({ confirmOpen: false })
    }
  }

  handleConfirmOpen = (confirmAction, confirmMessage) => {
    this.setState({ confirmOpen: true, confirmAction, confirmMessage })
  }

  handleDeleteDocumento(documento) {
    const { tema, temaId } = this.props
    let documentosAtualizados = {}
    this.handleConfirmOpen(() => {
      if (tema.documentos && tema.documentos[documento.id]) {
        documentosAtualizados = _.omit(tema.documentos, [documento.id])
      }

      this.props.saveTema(temaId, { documentos: documentosAtualizados })
      storage.removerAnexo(documento.url)
      this.props.getTema(temaId)
    }, `Confirma exclusão do documento ${documento.nome}?`)
  }

  handleDialogLinkOpen = () => {
    this.setState({ open: true })
  }

  handleDialogLinkClose = () => {
    this.setState({ open: false })
  }

  handleSaveLinkTema = (link, callback) => {
    const { temaId } = this.props
    if (temaId) {
      this.props.incluirLinkTema(temaId, link)
      this.handleDialogLinkClose()
      this.props.getTema(temaId)
    }
    callback && callback()
  }

  handleDeleteLink(link) {
    const { tema, temaId } = this.props
    let linksAtualizados = {}
    this.handleConfirmOpen(() => {
      if (tema.links && tema.links[link.id]) {
        linksAtualizados = _.omit(tema.links, [link.id])
      }

      this.props.saveTema(temaId, { links: linksAtualizados })
      this.props.getTema(temaId)
    }, `Confirma exclusão do link ${link.nome}?`)
  }

  save(values) {
    const { handleSubmit, temaId } = this.props
    handleSubmit && handleSubmit()
    if (temaId) {
      this.props.saveTema(temaId, values, () => this.successHandler(true))
    }
  }

  successHandler() {
    const { enqueueSnackbar } = this.props
    enqueueSnackbar('Tema informações gerais salvo com sucesso!', {
      variant: 'success'
    })
  }

  renderDocumentoAnexo(item, id) {
    const { canDelete } = this.props

    return canDelete
      ? <Chip
          key={id} 
          onDelete={() => this.handleDeleteDocumento({ ...item, id })} onClick={() => storage.downloadAnexo(item.url)} 
          label={item.nome} 
          clickable 
          variant='outlined'
        />
      : <Chip 
          key={id} 
          onClick={() => storage.downloadAnexo(item.url)} 
          label={item.nome} 
          clickable 
          variant='outlined' 
        />
  }

  render() {
    const {
      classes,
      handleSubmit,
      reset,
      submitting,
      previousPage,
      canEdit,
      canDelete,
      temaId,
      tema
    } = this.props
    const documentos = getDocumentosBy(tema)
    const links = getObjectByKey(tema, 'links')

    return (
      <React.Fragment>
        <form className={classes.container} onSubmit={handleSubmit(this.save.bind(this))}>
          <DialogTitle id='form-dialog-title'>Inclusão de novo tema</DialogTitle>
          <DialogContent style={{ minHeight: '50vh' }}>
            <Grid
              container
              spacing={2}
            >
              <Grid item sm={12}>
                <Typography variant={'body1'}>
                  Informações gerais relacionadas ao tema
                </Typography>
                <Typography variant={'caption'}>
                  proposições, artigos, estudos, projetos e produções de conhecimento em geral.
                </Typography>
              </Grid>

              <Grid item container sm={12} spacing={2}>
                <Grid item sm={6}>
                {_.map(documentos, (item, index) => this.renderDocumentoAnexo(item, index))}
                </Grid>
                <Grid item sm={6}>
                {_.map(links, (item, index) => (
                  <LinkChip 
                    key={index} 
                    item={item} 
                    id={index} 
                    canDelete={canDelete} 
                    handleDeleteLink={this.handleDeleteLink.bind(this)}
                  />
                ))}
                </Grid>
              </Grid>
              <Grid item container sm={12} spacing={2}>
                <Grid item sm={6}>
                  <input
                    accept={ACCEPT_INPUT_FILE}
                    className={classes.input}
                    id={temaId}
                    style={{ display: 'none' }}
                    onChange={(event) => this.handleCapture(event)}
                    type='file'
                  />
                  <label htmlFor={temaId}>
                    <Button color='primary' component='span' size='medium' style={{ marginRight: 8 }}>
                        <AttachFile /> Anexar Documento
                    </Button>
                  </label>
                </Grid>
                <Grid item sm={6}>
                  <Button 
                    color='primary' 
                    component='span' 
                    size='medium' 
                    style={{ marginRight: 8 }}
                    onClick={() => this.handleDialogLinkOpen()}
                  >
                    <Link /> Adicionar Link
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => reset() && this.props.handleClose(true)}
              color='default'
            >
              Cancelar
            </Button>
            <Button
              onClick={previousPage}
              color='default'
            >
              Voltar
            </Button>
            <Button
              type='submit'
              disabled={!canEdit || submitting}
              color='primary'
            >
              Próximo passo
            </Button>
          </DialogActions>
          <ConfirmDialog
            open={this.state.confirmOpen}
            message={this.state.confirmMessage}
            onClose={this.handleConfirmClose}
          />
        </form>
        <TemaLinkFormDialog
          open={this.state.open}
          handleClose={this.handleDialogLinkClose}
          handleSave={this.handleSaveLinkTema}
          handleReload={this.handleReload}
          canEdit={canEdit}
          canDelete={canDelete}
        />
      </React.Fragment>
      
    )
  }
}

const mapStateToProps = ({ politico_sindical: { tema, temaId } }) => ({
  tema: tema,
  temaId: temaId,
  initialValues: { ...tema, page: steps.INFORMACOES_GERAIS }
})

TemaStepInfoGeraisForm.propTypes = {
  classes: PropTypes.object.isRequired
}

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    { getTema, createTema, saveTema, setTema, setTemaId, incluirDocumentoTema, incluirLinkTema }
  )
)(
  reduxForm({
    form: 'tema',
    validate,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
  })(withSnackbar(TemaStepInfoGeraisForm))
)
