import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import styles from "./Charts.module.css";
import { Typography } from "@material-ui/core";

const StackedBarChart = ({ titulo, data, dataKeyX, dataKeyBar, colors }) => {
  return (
    <div className={styles.chartContainer}>
      <div className={styles.chart}>
        <Typography
          style={{
            padding: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <strong>{titulo}</strong>
        </Typography>
        <ResponsiveContainer>
          <BarChart
            data={data}
            margin={{ top: 5, right: 30, left: 30, bottom: 60 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={dataKeyX} />
            <YAxis />
            <Tooltip formatter={(value) => value.toLocaleString("pt-BR")} />
            <Legend wrapperClassName={styles.legendWrapper} />
            {dataKeyBar.map((key, index) => (
              <Bar key={key} dataKey={key} fill={colors[index]} stackId="a" />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default StackedBarChart;
