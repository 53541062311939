import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./CardArchive.module.css";
import {
  Button,
  Card,
  CircularProgress,
  Grid,
  Typography,
  styled,
} from "@material-ui/core";
import CloudUploadOutlined from "@material-ui/icons/CloudUploadOutlined";
import { uploadAnexoConsignacao } from "../../../firebase/storage";
import ButtonComponent from "../../commons/ButtonComponent";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const CardArchive = ({
  title,
  baseFileName,
  onFileUpload,
  selectedYear,
  selectedMonth,
  onClearFiles,
}) => {
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const [error, setError] = useState("");
  const [file, setFile] = useState(null);
  const [hasFiles, setHasFiles] = useState(false);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (
        selectedFile.type !== "text/csv" &&
        selectedFile.type !== "text/plain"
      ) {
        setError(
          "Formato de arquivo inválido. Por favor, selecione um arquivo .csv ou .txt."
        );
        return;
      }
      setFileName(selectedFile.name);
      setFile(selectedFile);
      setError("");
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile) {
      if (
        droppedFile.type !== "text/csv" &&
        droppedFile.type !== "text/plain"
      ) {
        setError(
          "Formato de arquivo inválido. Por favor, selecione um arquivo .csv ou .txt."
        );
        return;
      }
      setFileName(droppedFile.name);
      setFile(droppedFile);
      setError("");
    }
    setIsDragOver(false);
  };

  const handleSave = () => {
    if (!file) {
      setError("Nenhum arquivo selecionado para upload.");
      return;
    }

    setLoading(true);
    uploadAnexoConsignacao(file, selectedYear, selectedMonth, baseFileName)
      .then(() => {
        setLoading(false);
        setError("");
        onFileUpload();
        setHasFiles(true);
      })
      .catch((error) => {
        setError("Erro ao fazer upload. Tente novamente.");
        console.error(error);
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setLoading(false);
    setFile(null);
    setFileName("");
    setError("");
  };

  return (
    <Card
      className={`${styles.cardArea} ${isDragOver ? styles.dragOver : ""}`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <Grid className={styles.titleArea}>
        <Typography variant="h6">{title}</Typography>
      </Grid>
      <Grid className={styles.captionArea}>
        <Typography variant="caption">
          Clique para inserir ou arraste e solte
        </Typography>
      </Grid>
      {error && (
        <Typography color="error" variant="caption">
          {error}
        </Typography>
      )}
      <Grid className={styles.archiveArea}>
        <Button
          component="label"
          className={styles.button}
          disableElevation
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <Grid className={styles.fileUploadArea}>
              <CloudUploadOutlined
                style={{ backgroundColor: "transparent" }}
                fontSize="large"
              />
              <Grid className={styles.archiveName}>
                {fileName || "Nenhum arquivo selecionado"}
              </Grid>
            </Grid>
          )}
          <VisuallyHiddenInput
            type="file"
            onChange={handleFileChange}
            accept=".csv,.txt"
          />
        </Button>
      </Grid>
      <Grid className={styles.detailArea}>
        <Typography variant="caption">
          Formato esperado: .csv ou .txt
        </Typography>
      </Grid>
      <Grid className={styles.buttonArea}>
        <ButtonComponent size="small" onClick={handleCancel}>
          Cancelar
        </ButtonComponent>
        <ButtonComponent
          size="small"
          variant="insert"
          onClick={handleSave}
          disabled={!file || !selectedMonth || !selectedYear || hasFiles}
        >
          {loading ? "Salvando..." : "Salvar"}
        </ButtonComponent>
      </Grid>
    </Card>
  );
};

CardArchive.propTypes = {
  title: PropTypes.string.isRequired,
  baseFileName: PropTypes.string.isRequired,
  onFileUpload: PropTypes.func.isRequired,
};

export default CardArchive;
