import React from 'react'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { Chip, IconButton, Tooltip, Typography } from '@material-ui/core'
import Link from '@material-ui/icons/Link'
import AttachFile from '@material-ui/icons/AttachFile'
import classNames from 'classnames'

import Loading from '../commons/Loading'
import SindilegisBar from '../commons/SindilegisBar'
import { storage } from '../../firebase'
import { getTema, descartarTema, startRequest, getTemas } from '../../actions/politico_sindical'
import styles from './styles'
import _ from 'lodash'
import {getDocumentosBy, smoothScrolling, LABELS_TEMA, DADOS_GERAIS, ITENS, PESSOAS_INSTITUICOES, PROCESSOS, ACOES, CUSTOS, getObjectByKey } from '../../controllers/temaController'
import { formataData } from '../Financial/util'
import FolderIcon from '@material-ui/icons/Folder'
import LinkIcon from '@material-ui/icons/Link'
import Arquivos from './ArquivosELinks/Arquivos'
import ArquivosAcoes from './ArquivosELinks/ArquivosAcoes';
import ArquivosItens from './ArquivosELinks/Arquivos'
import Links from './ArquivosELinks/Links'
import LInksAcoes from './ArquivosELinks/LInksAcoes'
import LinksItens from './ArquivosELinks/LinksItens'


class PainelDetalharTema extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedSection: this.getInitialSectionValue(),
      temaId: null,
      arquivosOpen: false, 
      linksOpen: false,
      itemId: null,
      arquivosItensOpen: false,
      linksItensOpen:false,
      AcaoId: null,
      arquivosAcoesOpen: false,
      linksAcoesOpen: false
    }
  }

  componentDidMount() {
    const { temaId, sectionIndex } = this.props.match.params
    if (temaId) {
      this.props.startRequest()
      this.props.getTema(temaId)
    }
    if (sectionIndex) {
      this.initSectionIndexParameter()
    }
    this.props.getTemas()
  }

  componentWillUnmount () {
    this.props.descartarTema()
  }

  handleSelectedSection (labelKey) {
    this.setState({ selectedSection: labelKey })
    smoothScrolling.scrollTo(labelKey)
  }

  getInitialSectionValue() {
    const { sectionIndex } = this.props.match.params
    if ( sectionIndex && _.inRange(sectionIndex, 1, _.size(LABELS_TEMA)) ) {
      return _.get(_.keys(LABELS_TEMA), sectionIndex , 0)
    }
    return _.first(_.keys(LABELS_TEMA))
  }

  initSectionIndexParameter () {
    const { selectedSection } = this.state
    _.delay(() => smoothScrolling.scrollTo(selectedSection), 2000)
  }

  handleAquivoOpen=(temaId)=>{
    this.setState({arquivosOpen: true, temaId})
  }

  handleLinkOpen=(temaId)=>{
    this.setState({linksOpen: true, temaId})
  }

  handleArquivoItenOpen=(itemId)=>{
    this.setState({arquivosItensOpen:true, itemId})
  }

  handleLinkItenOpen=(itemId)=>{
    this.setState({linksItensOpen: true, itemId})
  }

  handleArquivoAcaoOpen=(acaoId)=>{
    this.setState({arquivosAcoesOpen: true,acaoId})
  }

  handleLinkAcaoOpen=(acaoId)=>{
    this.setState({linksAcoesOpen: true,acaoId})
  }

  handleArquivoClose=()=>{
    this.setState({arquivosOpen: false, temaId: null})
  }

  handleLinkClose=()=>{
    this.setState({linksOpen: false, temaId: null})
  }

  handleArquivoItenClose=()=>{
    this.setState({arquivosItensOpen: false})
  }

  handleLinkItenClose=()=>{
    this.setState({linksItensOpen: false})
  }

  handdleArquivoAcaoClose=()=>{
    this.setState({arquivosAcoesOpen: false})
  }

  handleLinkAcaoClose=()=>{
    this.setState({linksAcoesOpen: false})
  }

  renderStepsButtonGroup () {
    const { classes } = this.props
    const { selectedSection } = this.state
    return (
      <ButtonGroup variant='outlined' color='primary' aria-label='contained primary button group'>
        {_.map(_.keys(LABELS_TEMA), key => (
          <Button key={key} onClick={() => this.handleSelectedSection(key)} className={selectedSection === key && classes.selectedButton}>
            {LABELS_TEMA[key]}
          </Button>
        ))}
      </ButtonGroup>
    )
  }

  renderLabelsSectionGrid (label) {
    const { classes } = this.props
    const keyLabel = _.findKey(LABELS_TEMA, lt => lt === label)
    return (
      <Grid item id={keyLabel}>
        <Typography className={classes.leftTitles} variant='h6' color='primary'>
          {label}
        </Typography>
      </Grid>
    )
  }

  renderDocumentoAnexo (item, id) {
    return (
      <Chip 
        key={id} 
        size='small'
        icon={<AttachFile />}
        onClick={() => storage.downloadAnexo(item.url)}
        label={item.nome} 
        clickable
        style={{marginLeft: 8}}
        variant='outlined' 
      />
    )
  }

  renderLinkChip (item, id) {
    return (
      <Tooltip 
        key={id} 
        title={`Clique e acesse: ${item.url}`} 
        placement='left'>
          <Chip 
            key={id} 
            size='small'
            icon={<Link />}
            clickable 
            variant='outlined' 
            label={item.nome} 
            style={{marginLeft: 8}}
            onClick={() => window.open(item.url, '_blank')} 
          />
      </Tooltip>
      
    )
  }
  
  renderDadosGeraisContentGrid () {
    const { classes, tema, temaId } = this.props
    const documentos = getDocumentosBy(tema)
    const links = getObjectByKey(tema, 'links')
    const hasDoc = !_.isEmpty(documentos)
    const hasLink = !_.isEmpty(links)
    return (
      <Grid item>
        <Grid component='div'>
          <Typography variant='body2' display='inline' className={classes.labelTypography}>Título do Tema:</Typography>
          <Typography variant='body2' display='inline' className={classes.valueTypography}>{tema.titulo}</Typography>
        </Grid>
        <Grid component='div'>
          <Typography variant='body2' display='inline' className={classes.labelTypography}>Tipo:</Typography>
          <Typography variant='body2' display='inline' className={classes.valueTypography}>{tema.tipo_tema}</Typography>
        </Grid>
        <Grid component='div'>
          <Typography variant='body2' display='inline' className={classes.labelTypography}>Público interessado:</Typography>
          <Typography variant='body2' display='inline' className={classes.valueTypography}>{_.join(tema.interessados, ', ')}</Typography>
        </Grid>
        <Grid component='div'>
          <Typography variant='body2' display='inline' className={classes.labelTypography}>Descrição do tema:</Typography>
          <Typography variant='body2' display='inline' className={classes.valueTypography}>{tema.descricao}</Typography>
        </Grid>
        <Grid component='div'>
          <Typography variant='body2' display='inline' className={classes.labelTypography}>Classificação:</Typography>
          <Typography variant='body2' display='inline' className={classes.valueTypography}>{_.join(_.map(tema.tags, tag => tag.label), ', ')}</Typography>
        </Grid>
        <Grid component='div'>
          <Typography variant='body2' display='inline' className={classes.labelTypography}>Prioridade:</Typography>
          <Typography variant='body2' display='inline' className={classes.valueTypography}>{tema.prioridade}</Typography>
        </Grid>
        <Grid component='div'>
          <Typography variant='body2' display='inline' className={classes.labelTypography}>Classificação do tema:</Typography>
          <Typography variant='body2' display='inline' className={classes.valueTypography}>{tema.classificacaoTema}</Typography>
        </Grid>
        <Grid component='div'>
          <Typography variant='body2' display='inline' className={classes.labelTypography}>Informações gerais relacionadas ao tema:</Typography>
          <Tooltip title={`${hasDoc?"Arquivos": "Não há arquivos para esse tema"}`}>
            <IconButton color={`${hasDoc? "primary":"default"}`} onClick={()=> hasDoc? this.handleAquivoOpen(temaId): null}>
              <FolderIcon/>
            </IconButton>
          </Tooltip>
          <Tooltip title={`${hasLink?"Links": "Não há links para esse tema"}`}>
          <IconButton color={`${hasLink? "primary":"default"}`} onClick={()=> hasLink? this.handleLinkOpen(temaId):null}>
              <LinkIcon/>
            </IconButton>
            </Tooltip>
        </Grid>
      </Grid>
    )
  }

  renderDadosItensDestaqueContentGrid () {
    const { classes, tema } = this.props
    return _.map(tema['itens_destaque'], (itemDestaque, key) => {
      const itemNumber = key+1
      const documentos = getDocumentosBy(itemDestaque)
      const hasDoc = !_.isEmpty(documentos)
      const links = getObjectByKey(itemDestaque, 'links')
      const hasLink = !_.isEmpty(links)
      return (
        <Grid key={itemDestaque.id} container item spacing={2} style={{marginBottom: 12}}>
          <Grid item container xs={12} md={1} justifyContent='center'>
            <Typography variant='h4' color='primary'>{itemNumber}</Typography>
          </Grid>
          <Grid item xs={12} md={11} container alignItems='center'>
            <Grid item xs={12} component='div'>
              <Typography variant='body2' display='inline' className={classes.labelTypography}>Item {itemNumber}:</Typography>
              <Typography variant='body2' display='inline' className={classes.valueTypography}>{itemDestaque.descricao_item}</Typography>
            </Grid>
            <Grid item xs={12} component='div'>
              <Typography variant='body2' display='inline' className={classes.labelTypography}>Posicionamento item {itemNumber}:</Typography>
              <Typography variant='body2' display='inline' className={classes.valueTypography}>{itemDestaque.posicionamento}</Typography>
            </Grid>
            <Grid item xs={12} component='div'>
              <Typography variant='body2' display='inline' className={classes.labelTypography}>Informações gerais relacionadas ao item {itemNumber}:</Typography>
              <Tooltip title={hasDoc? "Arquivos": "Não há arquivos para esse item"}>
                <IconButton color={hasDoc? 'primary':"default"} onClick={()=> hasDoc? this.handleArquivoItenOpen(itemDestaque.id):null}>
                  <FolderIcon/>
                </IconButton>
              </Tooltip>
              <Tooltip title={hasLink? "Links": "Não há links para esse item"}>
                <IconButton color={hasLink?"primary":"default"} onClick={()=> hasLink? this.handleLinkItenOpen(itemDestaque.id): null}>
                  <LinkIcon/>
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      )
    })
  }

  renderDadosPessoasInstituicoesContentGrid () {
    const { classes, tema } = this.props
    return _.map(tema['pessoas_instituicoes'], (pessoaInstituicao, key) => {
      const itemNumber = key+1
      const contatos = _.filter([pessoaInstituicao['telefone1'], pessoaInstituicao['email1'], pessoaInstituicao['telefone2'], pessoaInstituicao['email2']], field => !_.isNil(field))
      return (
        <Grid key={pessoaInstituicao.id} container item spacing={2} style={{marginBottom: 12}}>
          <Grid item container xs={12} md={1} justifyContent='center'>
            <Typography variant='h4' color='primary'>{itemNumber}</Typography>
          </Grid>
          <Grid item xs={12} md={11} container alignItems='center'>
            <Grid item xs={12} component='div'>
              <Typography variant='body2' display='inline' className={classes.labelTypography}>Pessoa ou instituição:</Typography>
              <Typography variant='body2' display='inline' className={classes.valueTypography}>{pessoaInstituicao.nome}</Typography>
            </Grid>
            <Grid item xs={12} component='div'>
              <Typography variant='body2' display='inline' className={classes.labelTypography}>Motivo pelo qual ela é relevante:</Typography>
              <Typography variant='body2' dsplay='inline' className={classes.valueTypography}>{pessoaInstituicao.motivo_relevancia}</Typography>
            </Grid>
            <Grid item xs={12} component='div'>
              <Typography variant='body2' display='inline' className={classes.labelTypography}>Contatos:</Typography>
              <Typography variant='body2' display='inline' className={classes.valueTypography}>{_.join(contatos, ', ')}</Typography>
            </Grid>
          </Grid>
        </Grid>
      )
    })
  }
  
  renderDadosProcessosContentGrid () {
    const { classes, tema } = this.props
    return _.map(tema['processos'], (processo, key) => {
      const itemNumber = key+1
      return (
        <Grid key={processo.id} container item spacing={2} style={{marginBottom: 12}}>
          <Grid item container xs={12} md={1} justifyContent='center'>
            <Typography variant='h4' color='primary'>{itemNumber}</Typography>
          </Grid>
          <Grid item xs={12} md={11} container alignItems='center'>
            <Grid item xs={12} component='div'>
              <Typography variant='body2' display='inline' className={classes.labelTypography}>Identificação do processo:</Typography>
              <Typography variant='body2' display='inline' className={classes.valueTypography}>
                { processo.processo_sindilegis === 'true' ? 'Processo Sindilegis ' : 'Processo ' }
                { processo.identificacao_processo }
                { processo.tribunalOrgao && ` - ${processo.tribunalOrgao}` }
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )
    })
  }

  renderDadosAcoesSindilegisContentGrid () {
    const { classes, tema } = this.props
    const acoesOrdenadas = _.sortBy(tema['acoes_sindilegis'], 'data_acao').reverse()
    return _.map(acoesOrdenadas, (acao, key) => {
      const itemNumber = key+1
      const documentos = getDocumentosBy(acao)
      const hasDoc = !_.isEmpty(documentos)
      const links = getObjectByKey(acao, 'links')
      const hasLink = !_.isEmpty(links)
      return (
        <Grid key={acao.id} container item spacing={2} style={{marginBottom: 12}}>
          <Grid item container xs={12} md={1} justifyContent='center'>
            <Typography variant='h4' color='primary'>{itemNumber}</Typography>
          </Grid>
          <Grid item xs={12} md={11} container alignItems='center'>
            <Grid item xs={12} component='div'>
              <Typography variant='body2' display='inline' className={classes.labelTypography}>{`${formataData(acao.data_acao)} - ${acao.descricao_acao}`}</Typography>
            </Grid>
            <Grid item xs={12} component='div'>
              <Typography variant='body2' display='inline' className={classes.labelTypography}>{acao.classificacao_acao}</Typography>
            </Grid>
            <Grid item xs={12} component='div'>
              <Tooltip title={hasDoc? "Arquivos":"Não há documentos para essa ação"}>
                <IconButton  color={hasDoc?"primary":"default"} onClick={()=> hasDoc? this.handleArquivoAcaoOpen( acao.id):null}>
                <FolderIcon/>
                </IconButton>
              </Tooltip>
              <Tooltip title={hasLink? "Links":"Não há links para essa ação"}>
                <IconButton  color={hasLink?"primary":"default"} onClick={()=> hasLink? this.handleLinkAcaoOpen(acao.id):null}>
                <LinkIcon/>
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      )
    })
  }

  renderDadosCustosContentGrid () {
    const { classes, tema } = this.props
    return _.map(tema['custos'], (processo, key) => {
      const itemNumber = key+1
      return (
        <Grid key={processo.id} container item spacing={2} style={{marginBottom: 12}}>
          <Grid item container xs={12} md={1} justifyContent='center'>
            <Typography variant='h4' color='primary'>{itemNumber}</Typography>
          </Grid>
          <Grid item xs={12} md={11} container alignItems='center'>
            <Grid item xs={12} component='div'>
              <Typography variant='body2' display='inline' className={classes.labelTypography}>Custos:</Typography>
              <Typography variant='body2' display='inline' className={classes.valueTypography}>
                Custos..
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )
    })
  }

  render() {
    const { classes, loading, tema} = this.props
    const { selectedSection, arquivosOpen, linksOpen, 
      temaId, itemId, arquivosItensOpen, linksItensOpen,
      arquivosAcoesOpen, linksAcoesOpen, acaoId } = this.state
    return (
      <React.Fragment>
        <SindilegisBar />
        <Loading loading={loading} />
        {!loading && (
          <Container maxWidth='xl'>
            {
              Boolean(!_.isNil(tema)) 
              ? (
                  <Paper className={classes.mainPaper}>
                    <Grid container spacing={2}>
                      <Grid container className={classes.buttonGroup} item xs={12} justifyContent='center'>
                        <Grid item>
                          {this.renderStepsButtonGroup()}
                        </Grid>
                      </Grid>
                      <Grid container className={classNames(classes.dadosGeraisContainer, DADOS_GERAIS === selectedSection && classes.currentSection)} item xs={12} justifyContent='center'>
                        <Grid container item md={2} xs={12} className={classes.containerLabel}>
                          {this.renderLabelsSectionGrid(LABELS_TEMA[DADOS_GERAIS])}
                        </Grid>
                        <Grid container className={classes.containerValues} item md={10} xs={12}>
                          {this.renderDadosGeraisContentGrid()}
                        </Grid>
                      </Grid>

                      <Grid container className={classNames(classes.dadosItensDestaqueContainer, ITENS === selectedSection && classes.currentSection)} item xs={12} justifyContent='center'>
                        <Grid container item md={2} xs={12}>
                          {this.renderLabelsSectionGrid(LABELS_TEMA[ITENS])}
                        </Grid>
                        <Grid container className={classes.containerValues} item md={10} xs={12}>
                          {this.renderDadosItensDestaqueContentGrid()}
                        </Grid>
                      </Grid>

                      <Grid container className={classNames(classes.dadosPessoasContainer, PESSOAS_INSTITUICOES === selectedSection && classes.currentSection)} item xs={12} justifyContent='center'>
                        <Grid container item md={2} xs={12}>
                          {this.renderLabelsSectionGrid(LABELS_TEMA[PESSOAS_INSTITUICOES])}
                        </Grid>
                        <Grid container className={classes.containerValues} item md={10} xs={12}>
                          {this.renderDadosPessoasInstituicoesContentGrid()}
                        </Grid>
                      </Grid>

                      <Grid container className={classNames(classes.dadosProcessosContainer, PROCESSOS === selectedSection && classes.currentSection)} item xs={12} justifyContent='center'>
                        <Grid container item md={2} xs={12}>
                          {this.renderLabelsSectionGrid(LABELS_TEMA[PROCESSOS])}
                        </Grid>
                        <Grid container className={classes.containerValues} item md={10} xs={12}>
                          {this.renderDadosProcessosContentGrid()}
                        </Grid>
                      </Grid>

                      <Grid container className={classNames(classes.dadosAcoesSindilegisContainer, ACOES === selectedSection && classes.currentSection)} item xs={12} justifyContent='center'>
                        <Grid container item md={2} xs={12}>
                          {this.renderLabelsSectionGrid(LABELS_TEMA[ACOES])}
                        </Grid>
                        <Grid container className={classes.containerValues} item md={10} xs={12}>
                          {this.renderDadosAcoesSindilegisContentGrid()}
                        </Grid>
                      </Grid>

                      <Grid container className={classNames(classes.dadoCustosContainer, CUSTOS === selectedSection && classes.currentSection)} item xs={12} justifyContent='center'>
                        <Grid container item md={2} xs={12}>
                          {this.renderLabelsSectionGrid(LABELS_TEMA[CUSTOS])}
                        </Grid>
                        <Grid container className={classes.containerValues} item md={10} xs={12}>
                          {this.renderDadosCustosContentGrid()}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                )
              : (
                  <Grid item xs={12} md={12}>
                    Tema não existe... :(
                  </Grid>
                )
            }
          </Container>
        )}
        <Arquivos
        open={arquivosOpen}
        handleClose={this.handleArquivoClose}
        temaId={temaId}/>
        <Links
        open={linksOpen}
        handleClose={this.handleLinkClose}
        temaId={temaId}/>
        <ArquivosItens
        open={arquivosItensOpen}
        handleClose={this.handleArquivoItenClose}
        itemId={itemId}
        />
        <LinksItens
        open={linksItensOpen}
        handleClose={this.handleLinkItenClose}
        itemId={itemId}/>
        <ArquivosAcoes
        open={arquivosAcoesOpen}
        handleClose={this.handdleArquivoAcaoClose}
        acaoId={acaoId}/>
        <LInksAcoes
        open={linksAcoesOpen}
        handleClose={this.handleLinkAcaoClose}
        acaoId={acaoId}/>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ politico_sindical: { tema, temaId, loading } }) => ({
  tema: tema,
  loading: loading,
  temaId: temaId
})

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps, 
    { getTema, descartarTema, startRequest, getTemas }
  )
)(
  (withRouter(PainelDetalharTema))
)
