import React from "react";
import { Field, reduxForm, reset, formValues } from "redux-form";
import { compose } from "recompose";
import { connect } from "react-redux";
import _ from "lodash";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import InputText from "../commons/InputText";
import InputNumber from "../commons/InputNumber";
import Switch from "../commons/Switch";
import Select from "../commons/Select";
import HistoricoDespesa from "./historico_despesa";
import AutoCompleteSelect from "../commons/AutoComplete";
import DatePicker from "../commons/DatePicker";
import DateFnsUtils from "@date-io/date-fns";
import ptLocale from "date-fns/locale/pt-BR";
import { withSnackbar } from "notistack";
import styles from "./styles";
import validate from "./validate";
import { db } from "../../firebase";
import {
  getExpense,
  setExpenseId,
  setExpense,
  atualizarDespesa,
} from "../../actions/financial";
import { getBeneficiarios } from "../../actions/beneficiario";
import { getItensDespesa } from "../../actions/item_despesa";
import { getEventos } from "../../actions/evento";
import { converterParaNumero } from "./util";
import * as config from "./config";
import { addMonths } from "date-fns";
import InputInteger from "../commons/InputInteger";

const dateFns = new DateFnsUtils({ locale: ptLocale });

function isDataIgual(d1, d2) {
  return Math.floor(d1 / 86400000) === Math.floor(d2 / 86400000);
}

class ExpenseForm extends React.Component {
  componentDidMount() {
    this.props.getBeneficiarios();
    this.props.getItensDespesa();
    this.props.getEventos();
  }

  componentWillReceiveProps({ expenseId }) {
    if(expenseId){
    this.props.getExpense(expenseId);
    }
  }

  componentWillUnmount() {
    reset("expense");
    this.props.setExpenseId(null);
    this.props.setExpense(null);
  }

  save(expense) {
    const { enqueueSnackbar, expenseId } = this.props;
    expense.valor = converterParaNumero(expense.valor);

    if (
      this.props.expenses.some(
        (e) =>
          e.id !== expenseId &&
          e.item_despesa === expense.item_despesa &&
          e.id_beneficiario === expense.id_beneficiario &&
          e.tipo_documento === expense.tipo_documento &&
          e.numero_documento === expense.numero_documento &&
          isDataIgual(e.data_vencimento, expense.data_vencimento)
      )
    ) {
      enqueueSnackbar(
        "Despesa com item de despesa, beneficiário, data de vencimento, tipo e número de documento duplicado.",
        {
          variant: "error",
        }
      );
      return;
    }

    let promise;

    if (isNaN(expense.data_vencimento)) {
      expense.data_vencimento = dateFns
        .parse(expense.data_vencimento, "dd/MM/yyyy")
        .getTime();
    }

    if (expenseId) {
      this.props.atualizarDespesa(expenseId, expense, this.successHandler());
    } else {
      expense.parcela = `1/${expense.numero_parcelas}`;
      promise = db.doSaveExpense(expense);
      const { numero_parcelas, data_vencimento } = expense;

      if (numero_parcelas > 1) {
        for (let i = 2; i <= numero_parcelas; i++) {
          const clone = _.cloneDeep(expense);
          clone.parcela = `${i}/${numero_parcelas}`;
          clone.data_vencimento = addMonths(
            new Date(data_vencimento),
            i - 1
          ).getTime();
          db.doSaveExpense(clone);
        }
      }

      promise.then(this.successHandler()).catch(this.errorHandler());
    }
  }

  errorHandler() {
    const { enqueueSnackbar } = this.props;
    return () =>
      enqueueSnackbar("Erro ao salvar despesa.", {
        variant: "error",
      });
  }

  successHandler() {
    const { reset, handleClose, enqueueSnackbar } = this.props;
    return () => {
      enqueueSnackbar("Despesa salva com sucesso!", {
        variant: "success",
      });
      handleClose(true);
      reset("expense");
    };
  }

  handleChangeItemDespesa(item) {
    const item_despesa = this.props.itens_despesa.find((i) => i.nome === item);
    if (item_despesa) {
      const { tipo, centro_custo, descricao, id_beneficiario } = item_despesa;
      tipo && this.props.change("tipo_despesa", tipo);
      centro_custo && this.props.change("centro_custo", centro_custo);
      descricao && this.props.change("descricao", descricao);
      id_beneficiario && this.props.change("id_beneficiario", id_beneficiario);
    }
  }

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      reset,
      beneficiarios,
      itens_despesa,
      eventos,
    } = this.props;
    const { centros_custo, meios_pagamento, tipos_despesa, tipos_documento } =
      config;
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose.bind(this, null)}
        fullWidth
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Despesa</DialogTitle>
        <form onSubmit={handleSubmit((values) => this.save(values))}>
          <DialogContent>
            <Grid container spacing={2} hidden={false}>
              <Grid item sm={6}>
                <Field
                  name="item_despesa"
                  options={itens_despesa
                    .filter((item) => !item.desabilitado)
                    .map((d) => ({ label: d.nome, value: d.nome }))}
                  component={AutoCompleteSelect}
                  label="Item de despesa"
                  onChange={(item) => this.handleChangeItemDespesa(item)}
                />
              </Grid>
              <Grid item sm={3}>
                <Field
                  name="tipo_despesa"
                  component={Select}
                  label="Tipo de despesa"
                >
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  {tipos_despesa.map((m) => (
                    <MenuItem key={m} value={m}>
                      {m}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item sm={3}>
                <Field
                  name="centro_custo"
                  component={Select}
                  label="Centro de custo"
                >
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  {centros_custo.map((c) => (
                    <MenuItem key={c} value={c}>
                      {c}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item sm={3}>
                <Field
                  name="descricao"
                  component={InputText}
                  label="Descrição"
                />
              </Grid>
              <Grid item sm={3}>
                <Field
                  name="id_beneficiario"
                  component={Select}
                  label="Beneficiário"
                >
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  {beneficiarios
                    .filter((b) => !b.desabilitado)
                    .map((b) => (
                      <MenuItem key={b.id} value={b.id}>
                        {b.nome_beneficiario}
                      </MenuItem>
                    ))}
                </Field>
              </Grid>
              <Grid item sm={2}>
                <DadosBeneficiario beneficiarios={beneficiarios} />
              </Grid>
              <Grid item container sm={12} spacing={2}>
                <Grid item sm={4}>
                  <Field
                    name="relacao_evento"
                    component={Switch}
                    label="Despesa relacionada a evento?"
                  />
                </Grid>
                <DadosEvento eventos={eventos} />
              </Grid>
              <Grid item sm={2}>
                <Field
                  name="valor"
                  component={InputNumber}
                  label="Valor da parcela"
                />
              </Grid>
              <Grid item sm={2}>
                <Field
                  name="numero_parcelas"
                  component={InputInteger}
                  label="Nº total de parcelas"
                />
              </Grid>
              {this.props.expense && this.props.expense.parcela && (
                <Grid item sm={2}>
                  <Field name="parcela" component={InputText} label="Parcela" />
                </Grid>
              )}

              <Grid item sm={2}>
                <Field
                  name="data_vencimento"
                  component={DatePicker}
                  label="Data de vencimento"
                />
              </Grid>
              <Grid item sm={2}>
                <Field
                  name="meio_pagamento"
                  component={Select}
                  label="Meio de pagamento"
                >
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  {meios_pagamento.map((m) => (
                    <MenuItem key={m} value={m}>
                      {m}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item sm={2}>
                <Field
                  name="numero_documento"
                  component={InputText}
                  label="Nº documento"
                />
              </Grid>
              <Grid item sm={2}>
                <Field
                  name="tipo_documento"
                  component={Select}
                  label="Tipo do documento"
                >
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  {tipos_documento.map((d) => (
                    <MenuItem key={d} value={d}>
                      {d}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              {this.isDespesaLiquidada() && this.renderFormLiquidacao()}
              <Grid item sm={12}>
                {this.props.expenseId && (
                  <HistoricoDespesa id={this.props.expenseId} />
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => reset("expense") && this.props.handleClose()}
              color="default"
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={pristine || submitting}
              color="primary"
            >
              Salvar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }

  isDespesaLiquidada() {
    return this.props.expense && this.props.expense.data_liquidacao_pagamento;
  }

  renderFormLiquidacao() {
    return (
      <React.Fragment>
        <Grid item sm={2}>
          <Field
            name="data_liquidacao_pagamento"
            component={DatePicker}
            label="Data efetiva"
          />
        </Grid>
        <Grid item sm={1}>
          <Field name="banco_liquidacao" component={InputText} label="Banco" />
        </Grid>
        <Grid item sm={2}>
          <Field
            name="numero_pagamento"
            component={InputText}
            label="Número de pagamento"
          />
        </Grid>
      </React.Fragment>
    );
  }
}

const DadosEvento = formValues("relacao_evento")(
  ({ relacao_evento, eventos }) => {
    if (relacao_evento) {
      return (
        <React.Fragment>
          <Grid item sm={6}>
            <Field name="id_evento" component={Select} label="Nome do evento">
              <MenuItem value="">
                <em>Selecione</em>
              </MenuItem>
              {eventos
                .filter((b) => !b.desabilitado)
                .map((b) => (
                  <MenuItem key={b.id} value={b.id}>
                    {b.nome}
                  </MenuItem>
                ))}
            </Field>
          </Grid>
        </React.Fragment>
      );
    }
    return null;
  }
);

const DadosBeneficiario = formValues("id_beneficiario")(
  ({ id_beneficiario, beneficiarios }) => {
    const cpfCnpj = id_beneficiario
      ? beneficiarios.find((b) => b.id === id_beneficiario)?.cpfCnpj
      : "";
    return (
      <React.Fragment>
        <Typography variant="body2" color="textSecondary">
          CPF/CNPJ
        </Typography>
        <Typography variant="body1">{cpfCnpj}</Typography>
      </React.Fragment>
    );
  }
);

ExpenseForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({
  financial: { expense, expenseId, expenses },
  beneficiario: { beneficiarios },
  item_despesa: { itens_despesa },
  evento: { eventos },
}) => ({
  expense,
  expenses,
  itens_despesa,
  expenseId,
  beneficiarios,
  eventos,
  initialValues: expense,
});

export default compose(
  connect(mapStateToProps, {
    getExpense,
    setExpenseId,
    setExpense,
    atualizarDespesa,
    getBeneficiarios,
    getItensDespesa,
    getEventos,
  }),
  withStyles(styles)
)(
  reduxForm({
    form: "expense",
    validate,
    enableReinitialize: true,
  })(withSnackbar(ExpenseForm))
);
