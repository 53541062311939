export const centros_custo = [
  "Benefício para servidores",
  "Custos operacionais internos",
  "Defesa de interesses e direitos",
  "Gasto com pessoal",
  "Outro"
];

export const tipos_recebedor = [
  "Diretor",
  "Funcionário",
  "Governo Federal, Estadual ou Distrital",
  "Estagiário",
  "Beneficiario",
  "Representante/Coordenador",
  "Associação ou entidade representativa",
  "Outro"
].sort();

export const meios_pagamento = [
  "Digital",
  "Cheque",
  "Dinheiro",
  "Cartão de crédito",
  "Cartão de débito"
].sort();

export const tipos_despesa = ["Produtos / Bens", "Serviços", "Diverso", "Associacoes", "Pessoal", "Aluguel / Imposto", "Investimento"].sort();
export const tipos_receita = ["Ordinária", "Extraordinária"];
export const origens_receita = {
  "Extraordinária": ["Resgates", "Rendimentos", "Reembolso", "Descontos", "Sublocação", "Aluguel"],
  "Ordinária": ["Câmara dos Deputados", "Senado Federal", "TCU"]
};
export const tipos_documento = ["DARF", "DANFE", "GPS", "GRRF", "DAR", "DAS", "RECIBO", "FATURA", "BOLETO", "GRU", "RPA", "OUTRO"].sort();

