import _ from "lodash";
import { ITENS_INTERACAO_SET, ITEM_INTERACAO_SET, ITEM_INTERACAO_ID_SET, ITENS_INTERACAO_REQUEST, ITEM_INTERACAO_UPDATED, DELETE_ITEM_INTERACAO } from './types';
import { db } from "../firebase";

export const saveItemInteracao = (id, data, callback) => {
     return dispatch => {
          db.doSaveItemInteracao(id, data).then(() => {
               dispatch({ type: ITEM_INTERACAO_UPDATED, payload: { id, data } });
               callback && callback();
          });
     };
}

export const criarItemInteracao = (data, callback) => {
     return dispatch => {
          db.doCreateItemInteracao(data).then(() => {
               callback && callback();
          });
     };
}

export const getItensInteracao = () => {
     return dispatch => {
          dispatch({ type: ITENS_INTERACAO_REQUEST });
          db.onceGetItensInteracao().then((snapshot) => {
               let itens_interacao = snapshot.val()
               if (itens_interacao) {
                    Object.keys(itens_interacao).map(id => {
                         itens_interacao[id].id = id
                         return itens_interacao[id]
                    });
                    itens_interacao = _.orderBy(_.values(itens_interacao), [b => b.nome.toLowerCase()], ['asc']);
               } else {
                    itens_interacao = []
               }

               dispatch({ type: ITENS_INTERACAO_SET, itens_interacao });
          })
     }
}

export const getItemInteracao = (id) => {
     return dispatch => {
          db.onceGetItemInteracao(id).then((snapshot) => {
               const item_interacao = snapshot.val()
               dispatch({ type: ITEM_INTERACAO_SET, item_interacao })
          });
     }
}

export const deleteItemInteracao = (id) => {
     return dispatch => {
          db.doDeleteItemInteracao(id).then(() => {
               dispatch({ type: DELETE_ITEM_INTERACAO, payload: { id } });
          })
     }
}

export const setItemInteracao = (item_interacao) => dispatch => dispatch({ type: ITEM_INTERACAO_SET, item_interacao })

export const setItemInteracaoId = (item_interacao_id) => dispatch => dispatch({ type: ITEM_INTERACAO_ID_SET, item_interacao_id })