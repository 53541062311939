import React, { useState } from "react";
import { Grid, TextField, Typography, Paper } from "@material-ui/core";
import { formatarValor } from "./JuriFiliCalculator";
import ButtonComponent from "../../commons/ButtonComponent";
import styles from "./Calculators.module.css";

const AcaoFiliado = () => {
  const [mf, setMf] = useState("");
  const [cx, setCx] = useState("");
  const [ux, setUx] = useState("");
  const [ut, setUt] = useState("");
  const [result, setResult] = useState(0);

  const handleCalcular = () => {
    const mfNumerico = Number(mf.replace(/\D/g, "")) / 100;
    const cxNumerico = Number(cx.replace(/\D/g, "")) / 100;
    const uxNumerico = Number(ux);
    const utNumerico = Number(ut);

    const calculoResult = mfNumerico - (uxNumerico / utNumerico) * cxNumerico;
    setResult(calculoResult);
  };

  const handleLimpar = () => {
    setMf("");
    setCx("");
    setUx("");
    setUt("");
    setResult(0);
  };

  return (
    <Paper elevation={3} className={styles.bodyCalculator}>
      <Typography variant="h5" align="start" gutterBottom>
        Cálculo do Custo-Filiado para a Ação "X" do Clube de Benefícios - R${" "}
        {result.toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Valor da mensalidade paga pelo filiado"
            value={mf}
            onChange={(e) => setMf(formatarValor(e.target.value))}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Custo investido na Ação 'X'"
            value={cx}
            onChange={(e) => setCx(formatarValor(e.target.value))}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Número de vezes que o filiado utiliza o benefício da Ação 'X'"
            value={ux}
            onChange={(e) => setUx(e.target.value)}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Número total de utilizações do benefício por todos os filiados"
            value={ut}
            onChange={(e) => setUt(e.target.value)}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={12} container justifyContent="center">
          <ButtonComponent
            variant="insert"
            onClick={handleCalcular}
            size="large"
          >
            Calcular
          </ButtonComponent>
          <ButtonComponent
            variant="secondary"
            size="large"
            onClick={handleLimpar}
          >
            Limpar
          </ButtonComponent>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AcaoFiliado;
