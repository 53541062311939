import React from "react";
import { Field, reduxForm } from "redux-form";
import { compose } from "recompose";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import InputText from "../commons/InputText";
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioButton from "../commons/RadioButton";
import { withSnackbar } from "notistack";
import styles from "./styles";
import { getBeneficiario, criarBeneficiario, saveBeneficiario } from "../../actions/beneficiario";
import { cpfMask, cnpjMask } from "../commons/masks";

class BeneficiarioForm extends React.Component {

  state = { mask: cnpjMask }

  constructor(props) {
    super(props);
    this.save = this.save.bind(this);
  }

  componentWillReceiveProps({ beneficiarioId }) {
    if (beneficiarioId) {
    this.props.getBeneficiario(beneficiarioId);
  }
}

  save(beneficiario) {
    const { beneficiarioId } = this.props;
    if (beneficiarioId) {
      this.props.saveBeneficiario(beneficiarioId, beneficiario, () => this.successHandler())
    } else {
      this.props.criarBeneficiario(beneficiario, () => this.successHandler(true))
    }
  }

  successHandler(reload) {
    const { reset, handleClose, enqueueSnackbar } = this.props;
    enqueueSnackbar("Beneficiario salva com sucesso!", {
      variant: "success"
    });
    reset('beneficiario')
    handleClose(reload);
  }

  errorHandler() {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar("Erro ao salvar beneficiario.", {
      variant: "error"
    })
  }

  handleChangeTipo(tipo) {
    if (tipo === 'F') {
      this.setState({ mask: cpfMask })
    } else {
      this.setState({ mask: cnpjMask })
    }
  }

  render() {
    const { handleSubmit, pristine, submitting, reset } = this.props;

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose.bind(this, null)}
        fullWidth
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Beneficiário</DialogTitle>
        <form onSubmit={handleSubmit(this.save)}>
          <DialogContent>
            <Grid
              container
              spacing={2}
              hidden={false}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item sm={4}>
                <Field
                  name="tipo"
                  component={RadioButton}
                  label="Tipo"
                  onChange={this.handleChangeTipo.bind(this)}
                >
                  <FormControlLabel value="F" control={<Radio />} label="Pessoa física" />
                  <FormControlLabel value="J" control={<Radio />} label="Pessoa jurídica" />
                </Field>
              </Grid>
              <Grid item sm={3}>
                <Field
                  name="cpfCnpj"
                  component={InputText}
                  label="CPF / CNPJ"
                  {...this.state.mask}
                />
              </Grid>
              <Grid item sm={3}>
                <Field
                  name="nome_beneficiario"
                  component={InputText}
                  label="Nome"
                />
              </Grid>
              <Grid item sm={2}>
                <Field
                  name="sigla"
                  component={InputText}
                  label="Sigla"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => reset() && this.props.handleClose()}
              color="default"
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={pristine || submitting}
              color="primary"
            >
              Salvar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

BeneficiarioForm.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ beneficiario: { beneficiario, beneficiarioId } }) => ({
  beneficiario: beneficiario,
  beneficiarioId: beneficiarioId,
  initialValues: beneficiario
});

const validate = values => {
  const errors = {};
  const requiredFields = [
    "tipo",
    "cpfCnpj",
    "nome_beneficiario",
  ];
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = " Obrigatório";
    }
  });
  return errors;
};

export default compose(
  connect(
    mapStateToProps,
    { getBeneficiario, criarBeneficiario, saveBeneficiario }
  ),
  withStyles(styles)
)(
  reduxForm({
    form: "beneficiario",
    validate,
    enableReinitialize: true,
  })(withSnackbar(BeneficiarioForm))
);
