import React from 'react'
import { auth } from "../../firebase";

const AuthContext = React.createContext()

class AuthProvider extends React.Component {
    state = { authUser: JSON.parse(localStorage.getItem("authUser")) };

    componentDidMount() {
        this.listener = auth.onAuthUserListener(
            authUser => {
                this.setState({ authUser });
                localStorage.setItem("authUser", JSON.stringify(authUser));
            },
            () => {
                localStorage.removeItem("authUser");
                this.setState({ authUser: null });
            }
        );
    }

    componentWillUnmount() {
        this.listener();
    }

    render() {
        return (
            <AuthContext.Provider value={{ authUser: this.state.authUser }}            >
                {this.props.children}
            </AuthContext.Provider>
        )
    }
}

const AuthConsumer = AuthContext.Consumer

export { AuthProvider, AuthConsumer }
