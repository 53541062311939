import React from 'react'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import DeleteIcon from '@material-ui/icons/Delete'
import SaveIcon from '@material-ui/icons/Save'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Add from '@material-ui/icons/Add'
import Alert from '@material-ui/lab/Alert'
import { withStyles } from '@material-ui/core/styles'
import { withSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { Field, FieldArray, formValueSelector, reduxForm } from 'redux-form'

import InputText from '../commons/InputText'
import ConfirmDialog from '../commons/ConfirmDialog'
import { saveTema, getTema, createPessoaInstituicaoTema, savePessoaInstituicaoTema, deletePessoaInstituicaoTema } from '../../actions/politico_sindical'
import { steps, hasCurrentItemChanged, initialValuesTema } from '../../controllers/temaController'
import Switch from '../commons/Switch'
import styles from './styles'
import validate from './validate'

class TemaStepItensDestaqueForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      confirmOpen: false,
      confirmAction: Function,
      confirmMessage: '',
    }
  }
  
  componentDidMount () {
    const { temaId } = this.props
    if (temaId) {
      this.props.getTema(temaId)
    }
  }

  save(values) {
    const { handleSubmit, temaId } = this.props
    handleSubmit && handleSubmit()
    if (temaId) {
      const hasSomeNewItem = _.some(values['pessoas_instituicoes'], ({ id }) => _.isNil(id))
      if (hasSomeNewItem) {
        _.forEach(values['pessoas_instituicoes'], pessoaInstituicao => {
          _.isNil(pessoaInstituicao.id)
            ? this.props.createPessoaInstituicaoTema(temaId, pessoaInstituicao)
            : this.props.savePessoaInstituicaoTema(pessoaInstituicao.id, temaId, pessoaInstituicao)
        })
        this.props.saveTema(temaId, { page: steps.PESSOAS_INSTITUICOES, existe_pessoas_instituicoes: true }, () => this.successHandler(true))
      } else {
        this.props.saveTema(temaId, values, () => this.successHandler(true))
      }
    }
  }

  handleSavePessoaInstituicao (pessoaInstituicao) {
    const { temaId } = this.props
    if (temaId && !_.isEmpty(pessoaInstituicao)) {
      const { id } = pessoaInstituicao
      _.isNil(id)
        ? this.props.createPessoaInstituicaoTema(temaId, pessoaInstituicao)
        : this.props.savePessoaInstituicaoTema(id, temaId, pessoaInstituicao)

      this.props.getTema(temaId)
    }
  }

  handleConfirmClose = confirm => {
    if (confirm) {
      this.state.confirmAction()
      this.setState({ confirmOpen: false })
    } else {
      this.setState({ confirmOpen: false })
    }
  }

  handleConfirmOpen = (confirmAction, confirmMessage) => {
    this.setState({ confirmOpen: true, confirmAction, confirmMessage })
  }

  handleDeletePessoaInstituicao (pessoa, removeFieldCallback) {
    const { temaId } = this.props
    if (!_.isNil(pessoa) && !_.isNil(pessoa.id)) {
      this.handleConfirmOpen(() => {
        this.props.deletePessoaInstituicaoTema(pessoa.id, temaId)
        removeFieldCallback && removeFieldCallback()
      }, `Confirma exclusão da pessoa ou instituição ${pessoa.nome}?`)
    } else {
      removeFieldCallback && removeFieldCallback()
    }
  }

  successHandler(reload) {
    const { reset, enqueueSnackbar } = this.props
    enqueueSnackbar('Pessoas ou instituições relacionadas ao tema com sucesso!', {
      variant: 'success'
    })
    reset('tema')
  }

  renderGridActionsIconButtons (fields, index, hasNoItemId) {
    const { tema } = this.props
    const saveBtnDisabled = _.isEmpty(fields.get(index).nome) || _.isEmpty(fields.get(index).motivo_relevancia)
    const shouldToSaveItem = hasNoItemId || hasCurrentItemChanged(tema, fields.get(index), 'pessoas_instituicoes')
    return (
      <Grid container item xs={2} justifyContent='center'>
        <Grid item>
          <Tooltip title='Salvar pessoa ou instituição' arrow>
            <span>
              <IconButton 
                color={'primary'} 
                aria-label={'Salvar'}
                disabled={saveBtnDisabled}
                onClick={() => this.handleSavePessoaInstituicao(fields.get(index))}
              >
                { shouldToSaveItem 
                    ? (<SaveIcon /> )
                    : (<CheckCircleIcon />)
                }
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title='Deletar pessoa ou instituição' arrow>
            <IconButton 
              color={'secondary'} 
              aria-label={'Remover'}
              onClick={ () => this.handleDeletePessoaInstituicao(fields.get(index), () => fields.remove(index)) }
            >
              <DeleteIcon /> 
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    )
  }

  renderPessoaInstituicao ({ fields, meta: { error, submitFailed }}) {
    const { classes } = this.props
    return (
      <Grid container className={classes.itensDestaqueContainer}>
        <Grid item xs={12}>
          <Button type='button' variant={'outlined'} color={'primary'} endIcon={<Add />} onClick={() => fields.push({})}>
            Nova pessoa
          </Button>
        </Grid>
        <Grid item xs={12}>
          {submitFailed && error && <Alert className={classes.alertMsg} severity='warning'>{error}</Alert>}
        </Grid>
        {fields.map((item, index) => {
          const hasNoItemId = _.isNil(fields.get(index).id)
          return (
          <Grid item container key={index} className={classes.itensDestaque}>
            <Grid container item justifyContent='space-between'>
              <Typography variant={'body2'}>Pessoa #{index + 1}</Typography>
              {this.renderGridActionsIconButtons(fields, index, hasNoItemId)}
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={4}>
                <Field name={`${item}.nome`} component={InputText} label='Nome da pessoa ou instituição' />
              </Grid>
              <Grid item xs={8}>
                <Field name={`${item}.motivo_relevancia`} component={InputText} label='Motivo pelo qual ela é relevante para este tema' />
              </Grid>
              <Grid item xs={3}>
                <Field name={`${item}.telefone1`} component={InputText} label='Telefone 1' />
              </Grid>
              <Grid item xs={3}>
                <Field name={`${item}.telefone2`} component={InputText} label='Telefone 2' />
              </Grid>
              <Grid item xs={3}>
                <Field name={`${item}.email1`} component={InputText} label='Email 1' />
              </Grid>
              <Grid item xs={3}>
                <Field name={`${item}.email2`} component={InputText} label='Email 2' />
              </Grid>
            </Grid>
          </Grid>
        )})}
      </Grid>
    )
  }

  renderDadosPessoaInstituicaoGrid () {
    const { hasPessoaInstituicaoRelevantes } = this.props
    return hasPessoaInstituicaoRelevantes && (
      <React.Fragment>
        <Grid item sm={12}>
          <Typography variant={'body1'}>
            Pessoa ou insticuição
          </Typography>
        </Grid>
        <FieldArray name='pessoas_instituicoes' component={this.renderPessoaInstituicao.bind(this)} />
    </React.Fragment>
    )
  }

  render() {
    const {
      classes,
      handleSubmit,
      reset,
      submitting,
      previousPage,
      canEdit
    } = this.props

    return (
      <form className={classes.container} onSubmit={handleSubmit(this.save.bind(this))}>
        <DialogTitle id='form-dialog-title'>Inclusão de novo tema</DialogTitle>
        <DialogContent style={{ minHeight: '50vh' }}>
          <Grid
            container
            spacing={2}
          >
            <Grid item sm={12}>
              <Field 
                name='existe_pessoas_instituicoes'
                component={Switch}
                label='Existem pessoas ou instituições com posições relevantes em relação ao tema?'/>
            </Grid>
            {this.renderDadosPessoaInstituicaoGrid()}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => reset() && this.props.handleClose(null)}
            color='default'
          >
            Cancelar
          </Button>
          <Button
            onClick={previousPage}
            color='default'
          >
            Voltar
          </Button>
          <Button
            type='submit'
            disabled={!canEdit || submitting}
            color='primary'
          >
            Próximo passo
          </Button>
        </DialogActions>
        <ConfirmDialog
          open={this.state.confirmOpen}
          message={this.state.confirmMessage}
          onClose={this.handleConfirmClose}
        />
      </form>
    )
  }
}

const selector = formValueSelector('tema')

const mapStateToProps = state => {
  const { politico_sindical: { tema, temaId } } = state
  return {
    tema: tema,
    temaId: temaId,
    initialValues: { ...initialValuesTema, ...tema, page: steps.PESSOAS_INSTITUICOES },
    hasPessoaInstituicaoRelevantes: selector(state, 'existe_pessoas_instituicoes') || false
  }
}

TemaStepItensDestaqueForm.propTypes = {
  classes: PropTypes.object.isRequired
}

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    { saveTema, getTema, createPessoaInstituicaoTema, savePessoaInstituicaoTema, deletePessoaInstituicaoTema }
  )
)(
  reduxForm({
    form: 'tema',
    validate,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
  })(withSnackbar(TemaStepItensDestaqueForm))
)
