import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import SindilegisBar from "../commons/SindilegisBar";
import Loading from "../commons/Loading";
import {
  Container,
  Grid,
  makeStyles,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
import SindexGeral from "./Calculators/SindexCalculator";
import JuridicoFiliados from "./Calculators/JuriFiliCalculator";
import BeneficiosFiliado from "./Calculators/BeneFiliCalculator";
import AcaoFiliado from "./Calculators/AcaoFiliCalculator";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  areaOperacoes: {
    marginTop: 16,
    marginBottom: 16,
  },
});

const Sindex = () => {
  const classes = useStyles();
  const loading = useSelector((state) => state.filiado.loading);
  const [selectedCalculo, setSelectedCalculo] = useState("");
  const filiadoJuridico = "Custo Filiado - Jurídico";
  const filiadoBeneficios = "Custo Filiado - Benefícios";
  const filiadoAcao = "Custo Filiado - Ação 'X'";
  const viabilidadeFinanceira = "Viabilidade Financeira";

  const handleSelectChange = (event) => {
    setSelectedCalculo(event.target.value);
  };

  return (
    <div className={classes.root}>
      <SindilegisBar />
      <Loading loading={loading} />
      <Container maxWidth="xl">
        <Grid
          container
          justifyContent="flex-end"
          spacing={2}
          className={classes.areaOperacoes}
        >
          <Grid item xs={12}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Typography variant="h5" align="start" gutterBottom>
                  Sindex
                </Typography>
              </Grid>
              <Grid item sm={2}>
                <Field
                  name="calculo"
                  component={({ input }) => (
                    <Select
                      {...input}
                      onChange={(event) => {
                        input.onChange(event);
                        handleSelectChange(event);
                      }}
                      fullWidth
                      displayEmpty
                    >
                      <MenuItem value="">
                        <em>Selecione um cálculo</em>
                      </MenuItem>
                      <MenuItem value={filiadoJuridico}>
                        Custo Filiado - Jurídico
                      </MenuItem>
                      <MenuItem value={filiadoBeneficios}>
                        Custo Filiado - Benefícios
                      </MenuItem>
                      <MenuItem value={filiadoAcao}>
                        Custo Filiado - Ação 'X'
                      </MenuItem>
                      <MenuItem value={viabilidadeFinanceira}>
                        Viabilidade Financeira
                      </MenuItem>
                    </Select>
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              spacing={2}
              direction="column"
              style={{ paddingTop: 30 }}
            >
              {selectedCalculo === "" && (
                <Grid
                  item
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <Typography variant="h5">
                    Para começar você precisa selecionar qual cálculo deseja
                    realizar.
                  </Typography>
                </Grid>
              )}
              {selectedCalculo === filiadoJuridico && <JuridicoFiliados />}
              {selectedCalculo === filiadoBeneficios && <BeneficiosFiliado />}
              {selectedCalculo === filiadoAcao && <AcaoFiliado />}
              {selectedCalculo === viabilidadeFinanceira && <SindexGeral />}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default reduxForm({ form: "sindexForm" })(Sindex);
