import {
  POLITICO_SET,
  POLITICOS_REQUEST,
  POLITICOS_SET,
  POLITICO_UPDATE,
  DELETE_POLITICO,
  POLITICO_ID_SET,
} from "../actions/types";

const INITIAL_STATE = {
  politicos: [],
  politico: null,
  politicoId: null,
  historico: [],
  loading: false
};

function politicioReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case POLITICOS_SET: {
      return { ...state, politicos: action.politicos || [], loading: false };
    }
    case POLITICOS_REQUEST: {
      return { ...state, loading: true };
    }
    case POLITICO_SET: {
      return { ...state, politico: action.politico, loading: false };
    }
    case POLITICO_ID_SET: {
      return { ...state, politicoId: action.politicoId}
    }
    case POLITICO_UPDATE: {
      const { politicos } = state
      const { id, data } = action.payload
      const index = politicos.findIndex(f => f.id === id)
      const politico = { ...politicos[index], ...data }
      politicos.splice(index, 1, politico)
      return { ...state, politicos: [...politicos] }
    }
    case DELETE_POLITICO: {
      const { id } = action.payload;
      return {
        ...state,
        politicos: state.politicos.filter(politico => politico.id !== id)
      };
    }
    default:
      return state;
  }
}

export default politicioReducer;
