import React from "react";
import { addMonths } from 'date-fns'
import { Field, reduxForm, formValues } from "redux-form";
import { compose } from "recompose";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from "../commons/Switch";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import InputText from "../commons/InputText";
import InputNumber from "../commons/InputNumber";
import DatePicker from "../commons/DatePicker";
import Select from "../commons/Select";
import _ from "lodash";
import { withSnackbar } from "notistack";
import AutoCompleteSelect from "../commons/AutoComplete";
import { getItensReceita } from "../../actions/item_receita";
import { getEventos } from "../../actions/evento";
import styles from "./styles";
import { db } from "../../firebase";
import { getReceita } from "../../actions/financial";
import * as config from "./config";
import { converterParaNumero } from "./util";

class ReceitaForm extends React.Component {

  constructor(props) {
    super(props);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    this.props.getItensReceita();
    this.props.getEventos();
  }

  componentWillReceiveProps({ receitaId }) {
    if(receitaId){
    this.props.getReceita(receitaId);
  }
}

  save(receita) {
    const { reset, handleClose, enqueueSnackbar, receitaId } = this.props;
    let promise;

    receita.valor = converterParaNumero(receita.valor);

    if (receitaId) {
      promise = db.doUpdateReceita(receitaId, receita);
    } else {
      receita.parcela = `1/${receita.numero_parcelas}`
      promise = db.doSaveReceita(receita);
      const { numero_parcelas, data } = receita;

      if (numero_parcelas > 1) {
        for (let i = 2; i <= numero_parcelas; i++) {
          const clone = _.cloneDeep(receita)
          clone.parcela = `${i}/${numero_parcelas}`
          clone.data = addMonths(new Date(data), (i - 1)).getTime()
          db.doSaveReceita(clone);
        }
      }
    }

    promise
      .then(() => {
        enqueueSnackbar("Receita salva com sucesso!", {
          variant: "success"
        });
        handleClose(true);
        reset();
      })
      .catch(() =>
        enqueueSnackbar("Erro ao salvar receita.", {
          variant: "error"
        })
      );
  }

  handleChangeItemReceita(item) {
    const item_receita = this.props.itens_receita.find(i => i.nome === item);
    if (item_receita) {
      const { tipo, descricao } = item_receita;
      tipo && this.props.change('tipo', tipo);
      descricao && this.props.change('descricao', descricao);
    }
  }

  render() {
    const { handleSubmit, pristine, submitting, reset, itens_receita, eventos } = this.props;
    const { tipos_receita } = config;

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose.bind(this, null)}
        fullWidth
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Receita</DialogTitle>
        <form onSubmit={handleSubmit(this.save)}>
          <DialogContent>
            <Grid
              container
              spacing={4}
              hidden={false}
              justifyContent="center"
            >
              <Grid item sm={6}>
                <Field
                  name="item_receita"
                  options={itens_receita.filter(item => !item.desabilitado).map(d => ({ label: d.nome, value: d.nome }))}
                  component={AutoCompleteSelect}
                  label="Item de receita"
                  onChange={(item) => this.handleChangeItemReceita(item)}
                />
              </Grid>
              <Grid item sm={3}>
                <Field name="tipo" component={Select} label="Tipo" onChange={this.handleChange}>
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  {tipos_receita.map(t => <MenuItem key={t} value={t}>{t}</MenuItem>)}
                </Field>
              </Grid>
              <Grid item sm={3}>
                <Field
                  name="numero_parcelas"
                  component={InputText}
                  label="Número de parcelas"
                />
              </Grid>
              <Grid item sm={6}>
                <Field
                  name="descricao"
                  component={InputText}
                  label="Descrição"
                />
              </Grid>
              <Grid item sm={3}>
                <Field
                  name="data"
                  component={DatePicker}
                  label="Data"
                />
              </Grid>
              <Grid item sm={3}>
                <Field name="valor" component={InputNumber} label="Valor" />
              </Grid>
              <Grid item container sm={12} spacing={2}>
                <Grid item sm={4}>
                  <Field name="relacao_evento" component={Switch} label="Receita relacionada a evento?" />
                </Grid>
                <DadosEvento eventos={eventos} />
              </Grid>              
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => reset() && this.props.handleClose()}
              color="default"
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={pristine || submitting}
              color="primary"
              onClick={()=> window.location.reload()}
            >
              Salvar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

const DadosEvento = formValues('relacao_evento')(({ relacao_evento, eventos }) => {
  if (relacao_evento) {
    return (
      <React.Fragment>
        <Grid item sm={8}>
          <Field
            name="id_evento"
            component={Select}
            label="Nome do evento"
          >
            <MenuItem value="">
              <em>Selecione</em>
            </MenuItem>
            {eventos.filter(b => !b.desabilitado).map(b => (
              <MenuItem key={b.id} value={b.id}>
                {b.nome}
              </MenuItem>
            ))}
          </Field>
        </Grid>
      </React.Fragment>
    )
  }
  return null
});

ReceitaForm.propTypes = {
  classes: PropTypes.object.isRequired
};

const validate = values => {
  const errors = {};
  const requiredFields = [
    "tipo",
    "item_receita",
    "data",
    "descricao",
    "valor",
    'numero_parcelas'
  ];
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = " Obrigatório";
    }
  });
  return errors;
};

const mapStateToProps = (
  {
    financial: { receita, receitaId },
    item_receita: { itens_receita },
    evento: { eventos },
  }) => ({
    receita: receita,
    receitaId: receitaId,
    initialValues: receita,
    itens_receita,
    eventos
  });

export default compose(
  connect(
    mapStateToProps,
    { getReceita, getItensReceita, getEventos }
  ),
  withStyles(styles)
)(
  reduxForm({
    form: "receita",
    validate,
    enableReinitialize: true,
  })(withSnackbar(ReceitaForm))
);
