import { ITENS_DESPESA_SET, ITEM_DESPESA_SET, ITEM_DESPESA_ID_SET, ITENS_DESPESA_REQUEST, ITEM_DESPESA_UPDATED, DELETE_ITEM_DESPESA } from "../actions/types";

const INITIAL_STATE = {
  itens_despesa: [],
  item_despesa: null,
  item_despesa_id: null,
  historico: [],
  loading: false
};

function item_despesaReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ITENS_DESPESA_SET: {
      return { ...state, itens_despesa: action.itens_despesa, loading: false }
    }
    case ITENS_DESPESA_REQUEST: {
      return { ...state, loading: true }
    }
    case ITEM_DESPESA_SET: {
      return { ...state, item_despesa: action.item_despesa, loading: false }
    }
    case ITEM_DESPESA_ID_SET: {
      return { ...state, item_despesa_id: action.item_despesa_id }
    }
    case ITEM_DESPESA_UPDATED: {
      const { itens_despesa } = state
      const { id, data } = action.payload
      const index = itens_despesa.findIndex(f => f.id === id)
      const item_despesa = { ...itens_despesa[index], ...data }
      itens_despesa.splice(index, 1, item_despesa)
      return { ...state, itens_despesa: [...itens_despesa] }
    }
    case DELETE_ITEM_DESPESA: {
      const { itens_despesa } = state
      const index = itens_despesa.findIndex(f => f.id === action.payload.id)
      itens_despesa.splice(index, 1)
      return { ...state, itens_despesa: [...itens_despesa] }
    }
    default:
      return state;
  }
}

export default item_despesaReducer;
