import React from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import SindilegisBar from "../commons/SindilegisBar";
import RangeDatePicker from "../commons/RangeDatePicker";
import styles from "./styles";
import Loading from "../commons/Loading";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import {
  getAtendimentos,
  getEscritoriosAdvocacia,
  getProcessos,
  criarRelacionamentoFiliadosProcessos,
  adicionarIDsParaEventos,
  SITUACAO_ATENDIMENTO_CANCELADO,
  SITUACAO_ATENDIMENTO_CONCLUIDO,
  SITUACAO_ATENDIMENTO_PENDENTE_CONCLUSAO,
  SITUACAO_ATENDIMENTO_PENDENTE_REALIZACAO,
  SITUACAO_PROCESSO_AGUARDANDO_MANIFESTACAO_SINDILEGIS,
  SITUACAO_PROCESSO_EM_ANDAMENTO,
  SITUACAO_PROCESSO_ENCERRADO,
} from "../../actions/juridico";
import Button from "@material-ui/core/Button";
import * as routes from "../../constants/routes";
import * as roles from "../../constants/roles";
import _ from "lodash";
import AtendimentoForm from "./Atendimento/atendimento";
import ProcessoAdministrativoForm from "./Processo/processoAdministrativo";
import ProcessoJudicialForm from "./Processo/processoJudicial";
import ProcedimentoForm from "./Processo/procedimento";
import { getFiliados } from "../../actions/filiado";
import { getUser } from "../../actions/user";
import { formataData, isDataAnterior } from "../commons/util";
import {
  getDataFinalJuridico,
  getDataInicialJuridico,
} from "../Financial/util";
import { doCreateFiliadoProcesso } from "../../firebase/db";
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import MenuButton from '../commons/MenuButton'

class HomeJuridico extends React.Component {
  state = {
    sumarioProcessos: [],
    atendimentoOpen: false,
    processoJudicialOpen: false,
    processoAdministrativoOpen: false,
    procedimentoOpen: false,
    atendimentosFromDate: getDataInicialJuridico(),
    atendimentosToDate: getDataFinalJuridico(),
    processosFromDate: getDataInicialJuridico(),
    processosToDate: getDataFinalJuridico(),
    filteredProcessos: [],
    filteredAtendimentos: [],
    relacionamentoFiliadosCriado: false,
  };

  handleAtendimentoClickOpen = () => {
    this.setState({ atendimentoOpen: true });
  };

  handleAtendimentoClose = (reload) => {
    this.setState({ atendimentoOpen: false });
    if (reload) {
      this.props.getAtendimentos();
    }
  };

  componentDidMount() {
    this.props.getAtendimentos();
    this.props.getProcessos();
    this.props.getFiliados();
    this.props.getEscritoriosAdvocacia();
    if (this.props.authUser) {
      this.props.getUser(this.props.authUser.uid);
    }
  }

  async componentDidUpdate(prevProps) {
    const { processos } = this.props;

    if (
      !this.state.relacionamentoFiliadosCriado &&
      (prevProps.processos !== processos ||
        prevProps.atendimentos !== this.props.atendimentos)
    ) {
      this.atualizarDatas();
      this.props.adicionarIDsParaEventos(processos)
      criarRelacionamentoFiliadosProcessos(doCreateFiliadoProcesso);
      this.setState({ relacionamentoFiliadosCriado: true });
    }
  }

  atualizarDatas() {
    const storedAtendimentosFromDate = sessionStorage.getItem(
      "atendimentosFromDate"
    );
    const storedAtendimentosToDate =
      sessionStorage.getItem("atendimentosToDate");
    const storedProcessosFromDate = sessionStorage.getItem("processosFromDate");
    const storedProcessosToDate = sessionStorage.getItem("processosToDate");

    const atendimentosFromDate = storedAtendimentosFromDate
      ? new Date(storedAtendimentosFromDate)
      : getDataInicialJuridico();
    const atendimentosToDate = storedAtendimentosToDate
      ? new Date(storedAtendimentosToDate)
      : getDataFinalJuridico();
    const processosFromDate = storedProcessosFromDate
      ? new Date(storedProcessosFromDate)
      : getDataInicialJuridico();
    const processosToDate = storedProcessosToDate
      ? new Date(storedProcessosToDate)
      : getDataFinalJuridico();

    this.setState({
      atendimentosFromDate,
      atendimentosToDate,
      processosFromDate,
      processosToDate,
    });

    this.filterAtendimentos(
      atendimentosFromDate.getTime(),
      atendimentosToDate.getTime()
    );
    this.filterProcessos(
      processosFromDate.getTime(),
      processosToDate.getTime()
    );
  }

  handleDateChangeAtendimentos(date) {
    if (date.fromDate > date.toDate) {
      this.props.enqueueSnackbar(
        "A data final não pode ser anterior à data inicial!",
        { variant: "error" }
      );
      return;
    }
    this.setState(
      {
        atendimentosFromDate: date.fromDate,
        atendimentosToDate: date.toDate,
      },
      () => {
        sessionStorage.setItem(
          "atendimentosFromDate",
          date.fromDate.toISOString()
        );
        sessionStorage.setItem("atendimentosToDate", date.toDate.toISOString());
      }
    );
    this.filterAtendimentos(date.fromDate, date.toDate);
  }

  handleDateChangeProcessos(date) {
    if (date.fromDate > date.toDate) {
      this.props.enqueueSnackbar(
        "A data final não pode ser anterior à data inicial!",
        { variant: "error" }
      );
      return;
    }
    this.setState(
      {
        processosFromDate: date.fromDate,
        processosToDate: date.toDate,
      },
      () => {
        sessionStorage.setItem(
          "processosFromDate",
          date.fromDate.toISOString()
        );
        sessionStorage.setItem("processosToDate", date.toDate.toISOString());
      }
    );
    this.filterProcessos(date.fromDate.getTime(), date.toDate.getTime());
  }

  filterAtendimentos(atendimentosFromDate, atendimentosToDate) {
    if (atendimentosFromDate && atendimentosToDate) {
      let filteredAtendimentos = [];

      Object.keys(this.props.atendimentos).forEach((escritorio) => {
        filteredAtendimentos.push(
          ...Object.values(this.props.atendimentos[escritorio]).filter((a) => {
            const dataAtendimento = new Date(a.data);
            return (
              dataAtendimento >= atendimentosFromDate &&
              dataAtendimento <= atendimentosToDate
            );
          })
        );
      });
      this.setState({ filteredAtendimentos });
    } else {
      return null;
    }
  }

  filterProcessos(processosFromDate, processosToDate) {
    if (processosFromDate && processosToDate) {
      const filteredProcessos = Object.values(this.props.processos).filter(
        (p) =>
          processosFromDate <= p.dataProtocolo &&
          processosToDate >= p.dataProtocolo
      );
      this.setState({ filteredProcessos });
    } else {
      return null;
    }
  }

  prepareAtendimentosPieData() {
    const { authUser } = this.props;
    const todosProcessos =
      _.includes(authUser.roles, roles.JURIDICO) ||
      _.includes(authUser.roles, roles.VISUALIZADOR_JURIDICO);
    const sumario = this.calcularSumarioAtendimentos(todosProcessos);
    return sumario.map(item => ({
      name: item.descricao,
      value: item.quantidade,
    }));
  }

  prepareProcessosPieData() {
    const { authUser } = this.props;
    const todosProcessos =
      _.includes(authUser.roles, roles.JURIDICO) ||
      _.includes(authUser.roles, roles.VISUALIZADOR_JURIDICO);
    const sumario = this.calcularSumarioProcessos(todosProcessos);
    return sumario.map(item => ({
      name: item.descricao,
      value: item.quantidade,
    }));
  }

  render() {
    const { classes, loading, authUser, location } = this.props;
    const isEscritorioJuridico = _.includes(
      authUser.roles,
      roles.ESCRITORIO_JURIDICO
    );
    const isAuth = Boolean(authUser)

    const BUTTON_ROUTES = [
      { label: 'Gerenciar objetos de processo', route: routes.OBJETO_PROCESSO, condition: isEscritorioJuridico },
      { label: 'Gerenciar processos', route: routes.JURIDICO_PROCESSOS },
      { label: 'Relatório de processos', route: routes.JURIDICO_PROCESSOS_RELATORIO },
    ].filter(button => button.condition !== true)

    const CONDITIONAL_BUTTON = [
      { label: 'Escritórios', route: routes.ESCRITORIOS, condition: isAuth },
    ]

    const todosProcessos =
      _.includes(authUser.roles, roles.JURIDICO) ||
      _.includes(authUser.roles, roles.VISUALIZADOR_JURIDICO);
    const isVisualizador = _.includes(
      authUser.roles,
      roles.VISUALIZADOR_JURIDICO
    );
    const isJuridico = _.includes(authUser.roles, roles.JURIDICO);


    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF6384', '#36A2EB'];
    const atendimentosPieData = this.prepareAtendimentosPieData();
    const filteredAtendimentosPieData = atendimentosPieData.filter(item => item.value > 0)
    const processosPieData = this.prepareProcessosPieData();
    const filteredProcessosPieData = processosPieData.filter(item => item.value > 0)

    return (
      <div className={classes.root}>
        <SindilegisBar />
        <Loading loading={loading} />
        {
          loading ? null :
            <Container maxWidth="xl">
              {!isVisualizador && (
                <MenuButton
                  buttonsRoutes={BUTTON_ROUTES}
                  location={location}
                  additionalButtons={CONDITIONAL_BUTTON}
                />
              )}
              <Card
                className={classes.paper}
                elevation={1}
                hidden={loading}
                style={{ paddingBottom: 20 }}
              >
                <CardHeader
                  title={
                    this.props.isEscritorio ? "Escritório Jurídico" : "Jurídico"
                  }
                />
                <Grid container>
                  <Grid item md={6}>
                    <Card className={classes.paper} variant="outlined">
                      <CardHeader title="Atendimentos"></CardHeader>
                      <CardContent>
                        <Box
                          width="100%"
                          display="flex"
                          justifyContent="flex-end"
                          mb="8px"
                        >
                          {!isVisualizador && (
                            <Button
                              variant="outlined"
                              size="small"
                              color="primary"
                              disabled={!isJuridico && isEscritorioJuridico}
                              onClick={() => this.handleAtendimentoClickOpen()}
                            >
                              Agendar atendimento
                            </Button>
                          )}
                        </Box>
                        <Grid
                          container
                          item
                          md={9}
                          spacing={9}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <RangeDatePicker
                            fromDate={this.state.atendimentosFromDate}
                            toDate={this.state.atendimentosToDate}
                            onChange={(date) =>
                              this.handleDateChangeAtendimentos(date)
                            }
                          ></RangeDatePicker>
                        </Grid>
                        <Grid container direction="column" alignItems="center">
                          {this.calcularSumarioAtendimentos(todosProcessos).map(
                            (item) => this.renderSumario(item)
                          )}
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item md={6}>
                    <Card className={classes.paper} variant="outlined" hidden={loading}>
                      <CardHeader title="Gráfico de Atendimentos" style={{ textAlign: "flex-start" }} />
                      {filteredAtendimentosPieData.length > 0 ?
                        <CardContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '400px' }}>
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            <PieChart width={800} height={520}>
                              <Pie
                                data={filteredAtendimentosPieData}
                                cx={400}
                                cy={200}
                                labelLine={false}
                                label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                                outerRadius={80}
                                fill="#8884d8"
                                dataKey="value"
                              >
                                {filteredAtendimentosPieData.map((entry, index) => (
                                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                              </Pie>
                              <Tooltip />
                              <Legend />
                            </PieChart>
                          </div>
                        </CardContent> :
                        <CardContent style={{ display: 'flex', justifyContent: "center", alignItems: "center", fontSize: 20 }}>
                          Não há dados para serem exibidos.
                        </CardContent>
                      }
                    </Card>
                  </Grid>
                  <Grid item md={6}>
                    <Card
                      className={classes.paper}
                      variant="outlined"
                      hidden={loading}
                    >
                      <CardHeader
                        title="Processos/Procedimentos"
                        style={{ textAlign: "flex-start" }}
                      />
                      <CardContent>
                        {isVisualizador ? null : (
                          <Box
                            width="100%"
                            display="flex"
                            justifyContent="flex-end"
                            mb="8px"
                          >
                            <Button
                              variant="outlined"
                              size="small"
                              color="primary"
                              onClick={() =>
                                this.setState({ processoJudicialOpen: true })
                              }
                            >
                              Novo processo judicial
                            </Button>
                            <Button
                              variant="outlined"
                              size="small"
                              style={{ margin: "0 4px" }}
                              color="primary"
                              onClick={() =>
                                this.setState({ processoAdministrativoOpen: true })
                              }
                            >
                              Novo processo administrativo
                            </Button>
                            <Button
                              variant="outlined"
                              size="small"
                              color="primary"
                              onClick={() =>
                                this.setState({ procedimentoOpen: true })
                              }
                            >
                              Novo procedimento
                            </Button>
                          </Box>
                        )}
                        <Grid
                          container
                          item
                          md={9}
                          spacing={9}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <RangeDatePicker
                            fromDate={this.state.processosFromDate}
                            toDate={this.state.processosToDate}
                            onChange={(date) =>
                              this.handleDateChangeProcessos(date)
                            }
                          ></RangeDatePicker>
                        </Grid>
                        <Grid container direction="column" alignItems="center">
                          {this.calcularSumarioProcessos(todosProcessos).map(
                            (item) => this.renderSumario(item)
                          )}
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item md={6}>
                    <Card className={classes.paper} variant="outlined" hidden={loading}>
                      <CardHeader title="Gráfico de Processos" style={{ textAlign: "flex-start" }} />
                      {filteredProcessosPieData.length > 0 ?
                        <CardContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '400px' }}>
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            <PieChart width={800} height={400}>
                              <Pie
                                data={filteredProcessosPieData}
                                cx={400}
                                cy={200}
                                labelLine={false}
                                label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                                outerRadius={80}
                                fill="#8884d8"
                                dataKey="value"
                              >
                                {processosPieData.map((entry, index) => (
                                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                              </Pie>
                              <Tooltip />
                              <Legend />
                            </PieChart>
                          </div>
                        </CardContent> :
                        <CardContent style={{ display: 'flex', justifyContent: "center", alignItems: "center", fontSize: 20 }}>
                          Não há dados para serem exibidos.
                        </CardContent>
                      }
                    </Card>
                  </Grid>
                </Grid>
              </Card>
            </Container>
        }
        <AtendimentoForm
          open={this.state.atendimentoOpen}
          handleClose={this.handleAtendimentoClose}
          acao="Agendar"
          authUser={authUser}
        />
        <ProcessoAdministrativoForm
          open={this.state.processoAdministrativoOpen}
          handleClose={this.handleProcessoAdministrativoClose}
          authUser={authUser}
        />
        <ProcessoJudicialForm
          open={this.state.processoJudicialOpen}
          handleClose={this.handleProcessoJudicialClose}
          authUser={authUser}
        />
        <ProcedimentoForm
          open={this.state.procedimentoOpen}
          handleClose={this.handleProcedimentoClose}
          authUser={authUser}
        />
      </div>
    );
  }

  handleProcessoAdministrativoClose = (reload) => {
    this.setState({ processoAdministrativoOpen: false });
    if (reload) {
      this.props.getProcessos();
    }
  };

  handleProcessoJudicialClose = (reload) => {
    this.setState({ processoJudicialOpen: false });
    if (reload) {
      this.props.getProcessos();
    }
  };

  handleProcedimentoClose = (reload) => {
    this.setState({ procedimentoOpen: false });
    if (reload) {
      this.props.getProcessos();
    }
  };

  renderSumario(item) {
    const { classes } = this.props;

    return (
      <Grid
        key={item.descricao}
        container
        alignItems="center"
        className={classes.botoesAtendimentos}
        onClick={() => this.props.history.push(item.rota)}
      >
        <Grid item xs={8} md={8} className={classes.ajustePadding}>
          <Typography variant="body1">{item.descricao}</Typography>
        </Grid>
        <Grid item xs={4} md={4} className={classes.ajustePadding}>
          <Typography variant="h5" align="right" color="primary" justfy="right">
            {item.quantidade}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  calcularSumarioAtendimentos(todosAtendimentos) {
    const { user } = this.props;
    const { filteredAtendimentos } = this.state;

    let agendadosParaHoje = 0;
    let todosAgendados = 0;
    let dataVencida = 0;
    let pendentes = 0;
    let cancelados = 0;
    let concluidos = 0;
    const today = new Date();
    const dataInicial = formataData(this.state.atendimentosFromDate);
    const dataFinal = formataData(this.state.atendimentosToDate);
    const compareDates = dataInicial === dataFinal;

    filteredAtendimentos
      .filter((key) => todosAtendimentos || user?.escritorios?.includes(key))
      .forEach((a) => {
        if (a.situacao === SITUACAO_ATENDIMENTO_PENDENTE_REALIZACAO) {
          todosAgendados++;

          if (compareDates) {
            dataFinal === formataData(a.data) && agendadosParaHoje++;
          }
          isDataAnterior(new Date(a.data), today) && dataVencida++;
        }

        a.situacao === SITUACAO_ATENDIMENTO_PENDENTE_CONCLUSAO && pendentes++;
        a.situacao === SITUACAO_ATENDIMENTO_CANCELADO && cancelados++;
        a.situacao === SITUACAO_ATENDIMENTO_CONCLUIDO && concluidos++;
      });

    return [
      Sumario(
        "Agendados para hoje",
        agendadosParaHoje,
        compareDates
          ? this.getRotaAtendimentos(
            SITUACAO_ATENDIMENTO_PENDENTE_REALIZACAO,
            this.state.atendimentosFromDate.getTime(),
            this.state.atendimentosToDate.getTime()
          ) + `&hoje=true`
          : console.error("A data final e inicial não são iguais.")
      ),
      Sumario(
        "Todos os agendados",
        todosAgendados,
        this.getRotaAtendimentos(
          SITUACAO_ATENDIMENTO_PENDENTE_REALIZACAO,
          this.state.atendimentosFromDate.getTime(),
          this.state.atendimentosToDate.getTime()
        )
      ),
      Sumario(
        "Data de realização vencida",
        dataVencida,
        this.getRotaAtendimentos(
          SITUACAO_ATENDIMENTO_PENDENTE_REALIZACAO,
          this.state.atendimentosFromDate.getTime(),
          this.state.atendimentosToDate.getTime()
        ) + "&vencidos=true"
      ),
      Sumario(
        "Pendentes de conclusão",
        pendentes,
        this.getRotaAtendimentos(
          SITUACAO_ATENDIMENTO_PENDENTE_CONCLUSAO,
          this.state.atendimentosFromDate.getTime(),
          this.state.atendimentosToDate.getTime()
        )
      ),
      Sumario(
        "Cancelados",
        cancelados,
        this.getRotaAtendimentos(
          SITUACAO_ATENDIMENTO_CANCELADO,
          this.state.atendimentosFromDate.getTime(),
          this.state.atendimentosToDate.getTime()
        )
      ),
      Sumario(
        "Concluídos",
        concluidos,
        this.getRotaAtendimentos(
          SITUACAO_ATENDIMENTO_CONCLUIDO,
          this.state.atendimentosFromDate.getTime(),
          this.state.atendimentosToDate.getTime()
        )
      ),
    ];
  }

  getRotaAtendimentos(
    situacao,
    atendimentosFromDate,
    atendimentosToDate,
    vencidos = false,
    hoje = false
  ) {
    let url = `juridico/atendimentos?situacao=${situacao}&fromDate=${atendimentosFromDate}&toDate=${atendimentosToDate}`;
    if (vencidos) {
      url += "&vencidos=true";
    }
    if (hoje) {
      url += `&hoje=true`;
    }
    return url;
  }

  calcularSumarioProcessos(todosProcessos) {
    const { user, processos } = this.props;
    const sumarioProcessos = [];

    if (sumarioProcessos.length === 0 && Object.values(processos).length > 0) {
      sumarioProcessos.push({
        descricao: "Em andamento",
        quantidade: this.state.filteredProcessos
          .filter(
            (p) => todosProcessos || user.escritorios?.includes(p.escritorio.id)
          )
          .filter((p) => p.situacao === SITUACAO_PROCESSO_EM_ANDAMENTO).length,
        rota: this.getRotaProcessos(
          SITUACAO_PROCESSO_EM_ANDAMENTO,
          this.state.processosFromDate.getTime(),
          this.state.processosToDate.getTime()
        ),
      });
      sumarioProcessos.push({
        descricao: "Aguardando manifestação do Sindilegis",
        quantidade: this.state.filteredProcessos
          .filter(
            (p) => todosProcessos || user.escritorios?.includes(p.escritorio.id)
          )
          .filter(
            (p) =>
              p.situacao ===
              SITUACAO_PROCESSO_AGUARDANDO_MANIFESTACAO_SINDILEGIS
          ).length,
        rota: this.getRotaProcessos(
          SITUACAO_PROCESSO_AGUARDANDO_MANIFESTACAO_SINDILEGIS,
          this.state.processosFromDate.getTime(),
          this.state.processosToDate.getTime()
        ),
      });
      sumarioProcessos.push({
        descricao: "Encerrados",
        quantidade: this.state.filteredProcessos
          .filter(
            (p) => todosProcessos || user.escritorios?.includes(p.escritorio.id)
          )
          .filter((p) => p.situacao === SITUACAO_PROCESSO_ENCERRADO).length,
        rota: this.getRotaProcessos(
          SITUACAO_PROCESSO_ENCERRADO,
          this.state.processosFromDate.getTime(),
          this.state.processosToDate.getTime()
        ),
      });
    }
    return sumarioProcessos;
  }

  getRotaProcessos(situacao, processosFromDate, processosToDate) {
    return `juridico/processos?situacao=${situacao}&fromDate=${processosFromDate}&toDate=${processosToDate}`;
  }
}

function Sumario(descricao, quantidade, rota) {
  return { descricao, quantidade, rota };
}

const mapStateToProps = ({
  juridico: { atendimentos, processos, loading, escritoriosAdvocacia },
  userState: { user },
}) => ({
  atendimentos,
  loading,
  processos,
  escritoriosAdvocacia,
  user,
});

HomeJuridico.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getAtendimentos,
    getEscritoriosAdvocacia,
    getFiliados,
    getProcessos,
    getUser,
    criarRelacionamentoFiliadosProcessos,
    adicionarIDsParaEventos
  })
)(withRouter(withSnackbar(HomeJuridico)));
