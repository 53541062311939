export default theme => ({
  button: {
    marginTop: 10,
    marginBottom: 10
  },
  media: {
    height: 140
  },
  title: {
    marginTop: 40
  },
  cardTitle: {
    fontSize: 14,
  },
  areaOperacoes: {
    marginTop: 16,
    marginBottom: 16
  },
  naturezaDespesa: {
    position: 'absolute',
    top: 0,
    left: 24,
    backgroundColor: "#0000ff"
  },
  cardExpanse: {
    minHeight: 72,
  },
  botaoEditar: {
    marginLeft: 8
  },
  cardLancamentos: {
    cursor: "pointer"
  },
  areaGrafico: {
  },
  soma: {
    position: 'fixed',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    zIndex: 1
  },
  despesa: {
    padding: theme.spacing(6),
    backgroundColor: 'rgba( 0, 0, 0, .05)'
  },
  containerBottomPadding: {
    paddingBottom: 80
  }
});
