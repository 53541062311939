import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { creditarReceita } from "../../actions/financial";
import { connect } from "react-redux";
import { formataData } from "./util";

class CreditoReceita extends Component {

  state = { data: null, liquidado: false }

  constructor(props) {
    super(props);
    this.handleChangeData = this.handleChangeData.bind(this);
    this.handleCreditarReceitaClick = this.handleCreditarReceitaClick.bind(this)
  }

  handleChangeData(data) {
    this.setState({ data });
  }

  handleCreditarReceitaClick() {
    const { data } = this.state;

    const credito = {
      data_credito: data.getTime()
    }

    this.props.creditarReceita(this.props.receita.id, credito)
    this.setState({ liquidado: true })
  }

  render() {

    const { data_credito } = this.props.receita;

    if (data_credito || this.state.liquidado) {
      return (
        <Grid item>
          <Typography variant="body1" align="right" color="textSecondary">
            Receita creditada em {formataData(data_credito || this.state.data)}
          </Typography>
        </Grid>
      )
    } else {
      return (
        <React.Fragment>
          <Grid item>
            <KeyboardDatePicker autoOk clearable label="Data do crédito" format="dd/MM/yyyy" value={this.state.data} onChange={this.handleChangeData} />
          </Grid>
          <Grid item>
            <Button variant="outlined" size="small" style={{ marginTop: 18 }} disabled={this.state.data === null} onClick={this.handleCreditarReceitaClick} color="primary">Creditar</Button>
          </Grid>
        </React.Fragment>
      );
    }
  }
}


export default connect(null, { creditarReceita })(CreditoReceita)
