import _ from "lodash";
import {
  FUNCIONARIOS_SET,
  FUNCIONARIO_SET,
  FUNCIONARIO_ID_SET,
  FUNCIONARIOS_REQUEST,
  FUNCIONARIO_UPDATED,
  DELETE_FUNCIONARIO,
} from "./types";
import { db } from "../firebase";
import { aplicarMascaraCPF } from "../components/commons/masks";

export const saveFuncionario = (id, data, callback) => {
  return (dispatch) => {
    db.doSaveFuncionario(id, data).then(() => {
      dispatch({ type: FUNCIONARIO_UPDATED, payload: { id, data } });
      callback && callback();
    });
  };
};

export const criarFuncionario = (data, callback) => {
  return (dispatch) => {
    db.doCreateFuncionario(data).then(() => {
      callback && callback();
    });
  };
};

export const getFuncionarios = () => {
  return (dispatch) => {
    dispatch({ type: FUNCIONARIOS_REQUEST });
    db.onceGetFuncionarios().then((snapshot) => {
      let funcionarios = snapshot.val();
      if (funcionarios) {
        Object.keys(funcionarios).map((id) => {
          funcionarios[id].id = aplicarMascaraCPF(id);
          return funcionarios[id];
        });
        funcionarios = _.values(funcionarios);
      } else {
        funcionarios = [];
      }

      dispatch({ type: FUNCIONARIOS_SET, funcionarios });
    });
  };
};

export const getFuncionario = (id) => {
  return (dispatch) => {
    db.onceGetFuncionario(id).then((snapshot) => {
      const funcionario = snapshot.val();
      funcionario.id = aplicarMascaraCPF(id);
      dispatch({ type: FUNCIONARIO_SET, funcionario });
    });
  };
};

export const deleteFuncionario = (id) => {
  return (dispatch) => {
    if (id) {
      db.doDeleteFuncionario(id).then(() => {
        dispatch({ type: DELETE_FUNCIONARIO, payload: { id } });
      });
    } else {
      throw new Error("Empty Id");
    }
  };
};

export const setFuncionario = (funcionario) => (dispatch) =>
  dispatch({ type: FUNCIONARIO_SET, funcionario });

export const setFuncionarioId = (funcionarioId) => (dispatch) =>
  dispatch({ type: FUNCIONARIO_ID_SET, funcionarioId });
