import {
    JURIDICO_ATENDIMENTO_ID_SET,
    JURIDICO_ATENDIMENTO_SET,
    JURIDICO_ATENDIMENTO_UPDATED,
    JURIDICO_ATENDIMENTOS_REQUEST,
    JURIDICO_ATENDIMENTOS_SET,
    JURIDICO_ESCRITORIO_ADVOCACIA_SET,
    JURIDICO_ESCRITORIOS_ADVOCACIA_REQUEST,
    JURIDICO_ESCRITORIOS_ADVOCACIA_SET,
    JURIDICO_ESCRITORIO_ADVOCACIA_ID_SET,
    JURIDICO_ESCRITORIO_ADVOCACIA_UPDATED,
    DELETE_JURIDICO_ESCRITORIO_ADVOCACIA,
    JURIDICO_PROCESSO_SET,
    JURIDICO_PROCESSO_UPDATED,
    JURIDICO_PROCESSOS_REQUEST,
    JURIDICO_PROCESSOS_SET,
    JURIDICO_PROCESSO_REQUEST,
    JURIDICO_EVENTO_PROCESSO_ID_SET
} from "../actions/types";

const INITIAL_STATE = {
    atendimentos: {},
    atendimento: null,
    atendimentoId: null,
    escritoriosAdvocacia: [],
    escritorioAdvocacia: null,
    historico: [],
    loading: false,
    processos: [],
    processo: null,
    eventoId: null
};

function juridicoReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case JURIDICO_ATENDIMENTOS_SET: {
      return { ...state, atendimentos: action.atendimentos, loading: false }
    }
    case JURIDICO_ATENDIMENTOS_REQUEST: {
      return {  ...state, loading: true }
    }
    case JURIDICO_ATENDIMENTO_UPDATED: {
        const { atendimentos } = state
        const { escritorioAdvocaciaId, id, atendimento } = action.payload
        atendimentos[escritorioAdvocaciaId][id] = atendimento
        return { ...state, atendimentos: { ...atendimentos } }
    }
    case JURIDICO_ATENDIMENTO_SET: {
        return { ...state, atendimento: action.atendimento, loading: false }
    }
    case JURIDICO_ATENDIMENTO_ID_SET: {
        return { ...state, atendimentoId: action.atendimentoId }
    }
    case JURIDICO_ESCRITORIOS_ADVOCACIA_SET: {
        return { ...state, escritoriosAdvocacia: action.escritoriosAdvocacia }
    }
    case JURIDICO_ESCRITORIOS_ADVOCACIA_REQUEST: {
        return { ...state }
    }
    case JURIDICO_ESCRITORIO_ADVOCACIA_SET: {
        return { ...state, escritorioAdvocacia: action.escritorioAdvocacia }
    }
    case JURIDICO_ESCRITORIO_ADVOCACIA_ID_SET: {
      return{...state, escritorioAdvocaciaId: action.escritorioAdvocaciaId}
    }
    case JURIDICO_EVENTO_PROCESSO_ID_SET:{
      return{...state, eventoId: action.eventoId}
    }
    case JURIDICO_ESCRITORIO_ADVOCACIA_UPDATED:{
      const {escritoriosAdvocacia} = state
      const {id, data} = action.payload
      const index = escritoriosAdvocacia.findIndex(f=> f.id ===id)
      const escritorioAdvocacia = {...escritoriosAdvocacia[index], ...data}
      escritoriosAdvocacia.splice(index, 1, escritorioAdvocacia)
      return{...state, escritoriosAdvocacia: [...escritoriosAdvocacia]}
    }
    case DELETE_JURIDICO_ESCRITORIO_ADVOCACIA: {
      const {escritoriosAdvocacia} = state
      const index = escritoriosAdvocacia.findIndex(e => e.id === action.payload.id)
      escritoriosAdvocacia.splice(index, 1)
      return {...state, escritoriosAdvocacia: [...escritoriosAdvocacia]}
    }
    case JURIDICO_PROCESSOS_REQUEST: {
        return { ...state, loading: true }
    }
    case JURIDICO_PROCESSOS_SET: {
        return { ...state, processos: action.processos, loading: false }
    }
    case JURIDICO_PROCESSO_UPDATED: {
      const { processos } = state
      const { processo } = action.payload
      const index = processos.map(p => p.id).indexOf(processo.id)
      processos[index] = processo;
      return { ...state, processos: [...processos]  }
    }
    case JURIDICO_PROCESSO_REQUEST: {
      return{...state, loading: true}
    }
    case JURIDICO_PROCESSO_SET: {
      return { ...state, processo: action.processo, loading: false }
    }
    default:
      return state;
  }
}

export default juridicoReducer;
