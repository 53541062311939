import {
    POLITICOS_SET,
    POLITICO_SET,
    POLITICOS_REQUEST,
    POLITICO_UPDATE,
    DELETE_POLITICO,
    POLITICO_ID_SET,
} from "./types"
import { db } from "../firebase"

export const saveAutoridadePolitica = (id, data, callback) => {
    return (dispatch) => {
        db.doSaveAutoridadePolitica(id, data).then(() => {
            dispatch({ type: POLITICO_UPDATE, payload: { id, data } });
            callback && callback()
        })
    }
}

export const criarAutoridadePolitica = (data, callback) => {
    return (dispatch) => {
        db.doCreateAutoridadePolitica(data).then((id) => {
            if (callback) {
                callback(id);
            }
        });
    }
}

export const incluirPhotoAutoridade = (id, path) => {
    return (dispatch) => {
        return db.doIncluirPhotoAutoridade(id, path)
            .then(() => {
                console.info("Foto incluida com sucesso!")
            })
            .catch((error) => {
                console.error("Erro ao incluir foto:", error);
            });
    };
};


export const incluirAnexosAutoridade = (id, path) => {
    return dispatch => db.doIncluirAnexosAutoridade(id, path)
}

export const removerAnexoAutoridade = (anexoId, politicoId) => {
    return dispatch => db.doRemoverAnexoAutoridade(anexoId, politicoId)
}

export const getAutoridadesPoliticas = () => {
    return (dispatch) => {
        dispatch({ type: POLITICOS_REQUEST });
        db.onceGetAutoridadesPoliticas().then((snapshot) => {
            const politicosObject = snapshot.val();
            const politicosArray = politicosObject
                ? Object.keys(politicosObject).map(key => ({
                    ...politicosObject[key],
                    id: key
                }))
                : [];
            dispatch({ type: POLITICOS_SET, politicos: politicosArray });
        });
    }
}

export const getAutoridadePolitica = (id) => {
    return (dispatch) => {
        return db.onceGetAutoridadePolitica(id)
            .then((snapshot) => {
                const politico = snapshot.val();
                dispatch({ type: POLITICO_SET, politico });
                return politico;
            });
    };
};


export const deleteAutoridadePolitica = (id) => {
    return (dispatch) => {
        if (id) {
            db.doDeleteAutoridadePolitica(id).then(() => {
                dispatch({ type: DELETE_POLITICO, payload: { id } })
            })
        } else {
            throw new Error("Empty Id ")
        }
    }
}

export const setAutoridadePolitica = (politico) => {
    return (dispatch) => {
        dispatch({ type: POLITICO_SET, politico });
    };
};


export const setAutoridadePoliticaId = (politicoId) => {
    return (dispatch) => {
        dispatch({ type: POLITICO_ID_SET, politicoId })

    }
}