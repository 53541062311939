import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../firebase';
import * as routes from '../constants/routes';
import { Button, FormControl, InputLabel, Input, Paper, CssBaseline, Typography, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

const theme = {
    main: {
        width: "auto",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

    },
    paper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 16,
        maxWidth: '400px',
        borderRadius: '16px',

    },
    form: {
        width: "100%",
        marginTop: 16,
    },
    submit: {
        marginTop: 24,
        marginBottom: 20,
        paddinBottom: 40
    },
    backGorundForget: {
        zIndex: -1,
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: ' url(./img/logo-ilustrativo-sindilegis.svg), linear-gradient(128deg, rgba(120,205,209,1) 0%, rgba(43,167,206,1) 100%)',
        backgroundSize: "80px auto, auto"
    },
    linkForgotPassword: {
        fontWeigh: "700",
        color: "#F48976",
        textDecoration: "none"
    }
};

function PasswordForgetPage(props) {
    const { history } = props;
    return (
        <main style={theme.main}>
            <CssBaseline />
            <Paper style={theme.paper}>
                <img src="/img/sindilegis-logo.svg" style={{ width: "80%", height: "auto", marginBottom: 8, paddingBottom: 40 }} alt="Sindilegis" />
                <Typography variant='body2' style={{ width: '100%' }}>Insira o e-mail que você usa para fazer login e enviaremos um link para recuperação da sua senha.</Typography>
                <PasswordForgetForm history={history} />
            </Paper>
            <div style={theme.backGorundForget} />
        </main>
    );
}

const INITIAL_STATE = {
    email: '',
    error: null,
};

class PasswordForgetForm extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE, openSnackbar: false };
    }

    onSubmit = (event) => {
        const { email } = this.state;
        auth.doPasswordReset(email)
            .then(() => {
                this.setState({ ...INITIAL_STATE });
            })
            .catch(error => {
                this.setState({ error, openSnackbar: true });
            });

        event.preventDefault();
    }

    handleCloseSnackbar = () => {
        this.setState({ openSnackbar: false });
    }

    render() {
        const { email, error, openSnackbar } = this.state;
        const isInvalid = email === '';

        return (
            <form onSubmit={this.onSubmit} style={theme.form}>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="email">Email</InputLabel>
                    <Input
                        id="email"
                        value={email}
                        onChange={event => this.setState({ email: event.target.value })}
                        type='text'
                        error={!!error}
                    />
                </FormControl>
                <Button
                    type="submit"
                    fullWidth
                    variant='contained'
                    color='primary'
                    disabled={isInvalid}
                    style={theme.submit}
                >
                    <Typography variant="body2" style={{ color: 'white' }}> <strong>Criar nova senha</strong></Typography>
                </Button>

                {error && <Typography color="error">{error.message}</Typography>}

                <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={this.handleCloseSnackbar}>
                    <MuiAlert onClose={this.handleCloseSnackbar} severity="error">
                        {error && error.message}
                    </MuiAlert>
                </Snackbar>
            </form>
        );
    }
}

const PasswordForgetLink = () => (
    <p>
        <Link to={routes.PASSWORD_FORGET}>
            <Button color='primary'><Typography variant='body2'>
                <strong>Esqueci a senha</strong>
            </Typography></Button>
        </Link>
    </p>
);

export default PasswordForgetPage;
export { PasswordForgetForm, PasswordForgetLink };
