import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firebase-functions";
import "firebase/storage";

let config;

if (process.env.NODE_ENV === "development" || window.location.host.indexOf("sindilegis-dese") > - 1) {
  config = {
    apiKey: "AIzaSyAFeGhgysR2XI2lOwkTjdlzC5A-3fxYhMk",
    authDomain: "sindilegis-dese.firebaseapp.com",
    databaseURL: "https://sindilegis-dese.firebaseio.com",
    projectId: "sindilegis-dese",
    storageBucket: "sindilegis-dese.appspot.com",
    messagingSenderId: "825479957103"
  };
} else {
  config = {
    apiKey: "AIzaSyD8AQ4UpWr_gHlrt3SvLRgM6Oyh_Bimi8w",
    authDomain: "sindilegis-330e0.firebaseapp.com",
    databaseURL: "https://sindilegis-330e0.firebaseio.com",
    projectId: "sindilegis-330e0",
    storageBucket: "sindilegis-330e0.appspot.com",
    messagingSenderId: "5581855115"
  };
}

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const db = firebase.database();
const auth = firebase.auth();
const functions = firebase.functions();
const storage = firebase.storage();

export { db, auth, functions, storage };
