import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Grid,
  Container,
  Button,
  Snackbar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import SindilegisBar from "../commons/SindilegisBar";
import Loading from "../commons/Loading";
import CardArchive from "./CardArchive/CardArchive";
import {
  verificaConsignacao,
  getConsignacoes,
  createConsignacao,
  verificarStatusConsignacao,
  callInativarFiliados,
} from "../../actions/consignacao";
import MuiAlert from "@material-ui/lab/Alert";
import { storage } from "../../firebase";
import { bindActionCreators } from "redux";
import Link from "@material-ui/core/Link";
import MaterialTable from "material-table";
import styles from "./index.module.css";
import { getFiliado } from "../../actions/filiado";
import _ from "lodash";
import tableI18n from "../TableLocalization";
import ButtonComponent from "../commons/ButtonComponent";
import { downloadXlsx } from "../commons/util";

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

const Consignacao = ({ authUser }) => {
  const [loading, setLoading] = useState(false);
  const [hasFiles, setHasFiles] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [selectedYear, setSelectedYear] = useState();
  const [selectedMonth, setSelectedMonth] = useState();
  const [error, setError] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const [loadingConsig, setLoadingConsig] = useState(false);
  const [filiadosNaoConsignados, setFiliadosNaoConsignados] = useState([]);
  const [selectedFiliados, setSelectedFiliados] = useState([]);
  const [fetchingFiliados, setFetchingFiliados] = useState(false);
  const [loadingInativacao, setLoadingInativacao] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const dispatch = useDispatch();
  const consignacoes = useSelector((state) => state.consignacao.consignacoes);
  const isVizualizador = authUser?.roles?.includes("visualizador_consignacao");

  const consignacaoData = consignacoes?.[selectedYear]?.[selectedMonth];
  const consigs = consignacaoData
    ? Object.values(consignacaoData).filter((item) => item.tableData?.id !== 3)
    : [];
  const filiadosAAtualizar = consignacaoData?.filiados_a_atualizar
    ? Object.values(consignacaoData.filiados_a_atualizar)
    : [];

  const isButtonDisabled = !hasFiles || isVerifying;
  const years = Array.from({ length: 7 }, (_, i) => 2024 + i);
  const months = Array.from({ length: 12 }, (_, i) => i + 1);
  const hasData = filiadosAAtualizar.length > 0;
  const filiadosInativos =
    filiadosNaoConsignados.filter(
      (filiado) => filiado.situacao_filiado === "INATIVO"
    ) || [];
  const hasFiliadoInativo = filiadosInativos.length > 0;

  const localization = tableI18n;
  localization.toolbar.nRowsSelected = `{0} Filiados(s) selecionado(s)`;

  const fetchFiliados = async () => {
    setFetchingFiliados(true);
    if (selectedYear && selectedMonth && hasData) {
      const ids = filiadosAAtualizar.map((filiado) => filiado.id);
      const fetchedFiliados = [];

      for (const id of ids) {
        const filiadoData = await dispatch(getFiliado(id));
        if (
          filiadoData &&
          typeof filiadoData === "object" &&
          "nome_completo" in filiadoData &&
          "cpf" in filiadoData
        ) {
          fetchedFiliados.push({ id, ...filiadoData });
        } else {
          console.warn(`Filiado não encontrado para o id: ${id}`);
        }
      }
      setFiliadosNaoConsignados(fetchedFiliados);
    }
    setFetchingFiliados(false);
  };

  useEffect(() => {
    setFiliadosNaoConsignados([]);
  }, [selectedMonth]);

  useEffect(() => {
    if (selectedYear && selectedMonth) {
      dispatch(getConsignacoes(selectedYear, selectedMonth));
    }
  }, [selectedYear, selectedMonth, dispatch]);

  useEffect(() => {
    fetchFiliados();
  }, [selectedYear, selectedMonth, dispatch, filiadosAAtualizar.length]);

  const handleFileUpload = () => setHasFiles(true);
  const clearFiles = () => setHasFiles(false);

  const handleVerificarConsignacao = async () => {
    if (!selectedYear || !selectedMonth) {
      setError("O mês e o ano são obrigatórios.");
      return;
    }

    setLoadingConsig(true);
    const dataHoraFormatada = new Date().toLocaleString("pt-BR", {
      day: "2-digit",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });

    try {
      const idConsignacao = await dispatch(
        createConsignacao({
          startAt: `Início em ${dataHoraFormatada}`,
          status: "Em andamento",
        })
      );

      if (idConsignacao) {
        await dispatch(
          verificaConsignacao(selectedYear, selectedMonth, idConsignacao)
        );
        clearFiles();
        setIsVerifying(true);
        checkStatusConsignacao(idConsignacao);
      }
    } catch (error) {
      setSnackbarMessage(`Erro ao verificar a consignação: ${error.message}`);
      setSnackbarOpen(true);
      setLoadingConsig(false);
    }
  };

  const checkStatusConsignacao = (idConsignacao) => {
    let intervalId = setInterval(async () => {
      const status = await dispatch(verificarStatusConsignacao(idConsignacao));
      if (status === `Finalizado`) {
        clearInterval(intervalId);
        setSnackbarMessage("Verificação finalizada com sucesso");
        setSnackbarOpen(true);
        setIsVerifying(false);
        setLoadingConsig(false);
        await dispatch(getConsignacoes(selectedYear, selectedMonth));
      }
    }, 30000);
  };

  const handleOpenConfirmation = () => setOpenConfirmation(true);
  const handleCloseConfirmation = () => setOpenConfirmation(false);

  const handleConfirmInativarFiliados = async () => {
    handleCloseConfirmation();
    try {
      setLoadingInativacao(true);
      const message = await dispatch(callInativarFiliados(selectedFiliados));
      setSnackbarMessage(message);
      setSnackbarOpen(true);
      setSelectedFiliados([]);
      await fetchFiliados();
    } catch (error) {
      setSnackbarMessage("Erro ao inativar filiados: " + error.message);
      setSnackbarOpen(true);
    } finally {
      setLoadingInativacao(false);
    }
  };

  const getSelectedFiliados = () => {
    if (filiadosNaoConsignados.length > 0)
      return (
        filiadosNaoConsignados.filter((filiado) =>
          selectedFiliados.includes(filiado.id)
        ) || []
      );
  };

  const renderTable = (data, title) => (
    <Grid className={styles.table}>
      <MaterialTable
        columns={[
          {
            title: "Arquivo",
            field: "filename",
            render: (row) => (
              <Link
                component="button"
                variant="body2"
                onClick={() => storage.downloadAnexo(row.url)}
              >
                {row.filename}
              </Link>
            ),
          },
        ]}
        data={data}
        title={title}
        options={{
          actionsColumnIndex: -1,
          search: false,
          paging: false,
        }}
        localization={{
          body: {
            emptyDataSourceMessage: "Nenhum dado disponível",
          },
        }}
      />
    </Grid>
  );

  const renderTableNaoConsignados = (data, title) => {
    return (
      <Grid className={styles.table}>
        <MaterialTable
          columns={[
            {
              title: "Nome",
              field: "nome_completo",
              render: (row) => row?.nome_completo?.toUpperCase(),
            },
            {
              title: "CPF",
              field: "cpf",
              render: (row) => row.cpf,
            },
            { title: "Casa", field: "empresa" },
            { title: "Situação", field: "situacao_filiado" },
            {
              title: "Forma de Pagamento",
              field: "forma_pagamento",
              render: (row) =>
                row.forma_pagamento
                  ? row.forma_pagamento.toUpperCase()
                  : "NÃO INFORMADA",
            },
          ]}
          data={data.sort((a, b) => {
            const statusA = a.situacao_filiado === "ATIVO" ? 0 : 1;
            const statusB = b.situacao_filiado === "ATIVO" ? 0 : 1;
            return statusA - statusB;
          })}
          title={title}
          options={{
            exportAllData: true,
            exportButton: hasFiliadoInativo,
            actionsColumnIndex: -1,
            search: true,
            paging: true,
            selection: !isVizualizador,
            rowStyle: (rowData) => ({
              pointerEvents:
                rowData.situacao_filiado === "INATIVO" ? "none" : "auto",
              color:
                rowData.situacao_filiado === "INATIVO" ? "#ccc" : "inherit",
            }),
            exportCsv: (columns) => {
              const filiadosInativos = filiadosNaoConsignados.filter(
                (filiado) => filiado.situacao_filiado === "INATIVO"
              );
              downloadXlsx(columns, filiadosInativos, `filiados_inativos.xlsx`);
            },
          }}
          onSelectionChange={(rows) => {
            const selectedIds = rows
              .filter((row) => row.situacao_filiado !== "INATIVO")
              .map((row) => row.id);
            setSelectedFiliados(selectedIds);
          }}
          localization={{
            ...localization,
            body: {
              ...localization.body,
              emptyDataSourceMessage:
                selectedMonth && selectedYear && fetchingFiliados
                  ? "Carregando..."
                  : "Nenhum dado disponível",
            },
          }}
        />
        <Grid style={{ marginTop: 8 }}>
          <ButtonComponent
            variant="primary"
            onClick={handleOpenConfirmation}
            disabled={selectedFiliados.length === 0}
          >
            {loadingInativacao
              ? "Inativando filiados..."
              : "Inativar Filiados Selecionados"}
          </ButtonComponent>
        </Grid>
      </Grid>
    );
  };

  return (
    <div>
      <SindilegisBar />
      <Loading loading={loading} />
      {loadingConsig ? (
        <Grid container className={styles.loading}>
          <Grid item>
            <div className={styles.divLoading}>
              <CircularProgress />
              <Typography variant="body1" className={styles.body1Loading}>
                Por favor, aguarde. Estamos verificando as consignações.
              </Typography>
              <Typography variant="body2" className={styles.body2Loading}>
                Esse processo pode demorar alguns minutos.
              </Typography>
            </div>
          </Grid>
        </Grid>
      ) : (
        <Container maxWidth="xl" className={styles.generalContainer}>
          <Grid container spacing={2} className={styles.gridContainer}>
            <Grid item sm={4}>
              <Typography
                variant="h5"
                align="start"
                gutterBottom
                className={styles.title}
              >
                CONSIGNAÇÃO
              </Typography>
            </Grid>
            <Grid item xs={12} className={styles.selectArea}>
              <Typography className={styles.titleSelectArea}>
                * Selecione o mês e o ano dos arquivos.
              </Typography>
              <Grid className={styles.gridFormControl}>
                <FormControl
                  variant="outlined"
                  style={{ minWidth: 120, marginRight: 20 }}
                >
                  <InputLabel>Mês</InputLabel>
                  <Select
                    value={selectedMonth}
                    onChange={(e) => setSelectedMonth(e.target.value)}
                    label="Mês"
                    disabled={isVerifying}
                  >
                    {months.map((month) => (
                      <MenuItem key={month} value={month}>
                        {month}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl variant="outlined" style={{ minWidth: 120 }}>
                  <InputLabel>Ano</InputLabel>
                  <Select
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(e.target.value)}
                    label="Ano"
                    disabled={isVerifying}
                  >
                    {years.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {!isVizualizador ? (
              <>
                <Grid item xs={12} className={styles.cardArea}>
                  {["Câmara", "Senado", "TCU"].map((title) => {
                    const baseFileNameMap = {
                      Câmara: "camara",
                      Senado: "senado",
                      TCU: "tcu",
                    };
                    return (
                      <CardArchive
                        key={title}
                        title={title}
                        baseFileName={baseFileNameMap[title]}
                        onFileUpload={handleFileUpload}
                        selectedYear={selectedYear}
                        selectedMonth={selectedMonth}
                        onClearFiles={clearFiles}
                        disabled={isVerifying}
                      />
                    );
                  })}
                </Grid>
                <Grid className={styles.errorArea}>
                  {error && (
                    <Typography color="error" variant="body2">
                      {error}
                    </Typography>
                  )}
                  <Button
                    color="primary"
                    onClick={handleVerificarConsignacao}
                    disabled={isButtonDisabled}
                  >
                    <CheckCircleOutline /> Verificar consignação
                  </Button>
                </Grid>
                {renderTable(
                  consigs,
                  selectedMonth && selectedYear
                    ? `Consignação em ${selectedMonth}/${selectedYear}`
                    : "Consignação"
                )}
                {renderTableNaoConsignados(
                  filiadosNaoConsignados,
                  selectedMonth && selectedYear
                    ? `Filiados não consignados em ${selectedMonth}/${selectedYear}`
                    : "Filiados não consignados"
                )}
              </>
            ) : (
              <>
                {renderTableNaoConsignados(
                  filiadosNaoConsignados,
                  selectedMonth && selectedYear
                    ? `Filiados não consignados em ${selectedMonth}/${selectedYear}`
                    : "Filiados não consignados"
                )}
              </>
            )}
          </Grid>
        </Container>
      )}
      <Dialog open={openConfirmation} onClose={handleCloseConfirmation}>
        <DialogContent>
          <Typography>
            Ao clicar em confirmar você alterará a situação dos seguintes
            filiados para <strong>INATIVO</strong>:
          </Typography>
          <List>
            {Array.isArray(getSelectedFiliados()) &&
              getSelectedFiliados().map((filiado) => (
                <ListItem key={filiado.id}>
                  <ListItemText
                    primary={`${filiado.nome_completo.toUpperCase()} - ${
                      filiado.empresa
                    }`}
                  />
                </ListItem>
              ))}
          </List>
          <Typography>Confirma a operação?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmation} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirmInativarFiliados} color="secondary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)}>{snackbarMessage}</Alert>
      </Snackbar>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { getConsignacoes, getFiliado, callInativarFiliados },
    dispatch
  );

export default Consignacao;
