const Parentescos = [
    'Bisavô(ó)',
    'Avô(ó)',
    'Mãe',
    'Pai',
    'Cônjuge',
    'Filho(a)',
    'Neto(a)',
    'Bisneto(a)',
    'Irmão(ã)',
    'Primo(a)',
    'Tio(a)'
];

export default Parentescos