export default theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    margin: 20,
    marginTop: 80
  },
  demo: {
    backgroundColor: theme.palette.background.paper
  },
  grow: {
    flexGrow: 1
  }
});
