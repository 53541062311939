import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import styles from "./styles";
import MaterialTable from "material-table";
import { withRouter } from "react-router-dom";
import tableI18n from "../../TableLocalization";
import { getTemas } from "../../../actions/politico_sindical";
import { Grid, Link } from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";

class LinksItensTemaSindical extends React.Component {
  render() {
    const { handleClose, itemId, classes, temas } = this.props;
    const tema = temas?.find(
      (tema) => tema.id === this.props.match.params.temaId
    );
    const itemDestaque = tema?.itens_destaque;
    const itemArray = itemDestaque ? Object.values(itemDestaque) : [];
    const item = itemArray.find((item) => item.id === itemId) || [];
    const titulo = item?.descricao_item;

    let links = [];
    if (item && item.links) {
      links = Object.values(item.links);
    }
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle>{titulo}</DialogTitle>
        <DialogContent>
          <div className={classes.demo}>
            <MaterialTable
              columns={[
                {
                  title: "Link",
                  field: "nome",
                  render: (rowData) => (
                    <Link
                      component="button"
                      variant="body2"
                      onClick={() => window.open(rowData.url, "_blank")}
                    >
                      <Grid container alignItems="center" spacing={1}>
                        <LinkIcon />
                        {rowData.nome}
                      </Grid>
                    </Link>
                  ),
                },
              ]}
              data={links}
              title={"Links"}
              options={{
                actionsColumnIndex: -1,
              }}
              localization={tableI18n}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(itemId)} color="default">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ politico_sindical: { temas } }) => ({
  temas: temas,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getTemas })
)(withRouter(LinksItensTemaSindical));
