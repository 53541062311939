import * as routes from "../../constants/routes";

export default {
  admin_financeiro: {
    route: routes.FINANCIAL,
    name: "Financeiro",
    vector: "./img/icons/financeiro.svg",
    altText: "Financeiro",
    modules: 1
  },
  visualizador_financeiro: {
    route: routes.FINANCIAL,
    name: "Financeiro",
    vector: "./img/icons/financeiro.svg",
    altText: "Financeiro",
    modules: 1

  },
  gestor_usuario: {
    route: routes.FILIADOS,
    name: "Filiados",
    vector: "./img/icons/filiados.svg",
    altText: "Filiados",
    modules: 2
  },
  visualizador_usuario: {
    route: routes.FILIADOS,
    name: "Filiados",
    vector: "./img/icons/filiados.svg",
    altText: "Filiados",
    modules: 2
  },
  editor_filiado: {
    route: routes.FILIADOS,
    name: "Filiados",
    vector: "./img/icons/filiados.svg",
    altText: "Filiados",
    modules: 2
  },
  gestor_sistema: {
    route: routes.ROLES,
    name: "Perfis",
    vector: "./img/icons/perfis.svg",
    altText: "Perfis",
    modules: 2
  },
  aprovador: {
    route: routes.APROVADOR,
    name: "Aprovador",
    vector: "./img/icons/aprovador.svg",
    altText: "Aprovador",
    modules: 1
  },
  gestor_evento: {
    route: routes.EVENTOS,
    name: "Eventos",
    vector: "./img/icons/eventos.svg",
    altText: "Eventos",
    modules: 1
  },
  visualizador_evento: {
    route: routes.EVENTOS,
    name: "Eventos",
    vector: "./img/icons/eventos.svg",
    altText: "Eventos",
    modules: 1
  },
  gestor_interacao: {
    route: routes.INTERACOES,
    name: "Interações",
    vector: "./img/icons/interacoes.svg",
    altText: "Interações",
    modules: 2
  },
  gestor_funcionarios: {
    route: routes.FUNCIONARIOS,
    name: "Funcionários",
    vector: "./img/icons/funcionarios.svg",
    altText: "Funcionários",
    modules: 1
  },
  juridico: {
    route: routes.JURIDICO,
    name: "Jurídico",
    vector: "./img/icons/juridico.svg",
    altText: "Jurídico",
    modules: 3
  },
  escritorio_juridico: {
    route: routes.ESCRITORIO_JURIDICO,
    name: "Processos",
    vector: "./img/icons/escritorio-juridico.svg",
    altText: "Processos",
    modules: 3
  },
  visualizador_juridico: {
    route: routes.JURIDICO,
    name: "Jurídico",
    vector: "./img/icons/juridico.svg",
    altText: "Jurídico",
    modules: 3
  },
  comunicacao: {
    route: routes.COMUNICACAO,
    name: "Comunicação",
    vector: "./img/icons/comunicacao.svg",
    altText: "Comunicação",
    modules: 2
  },
  filiado: {
    route: routes.FILIADO,
    name: "Meu perfil",
    vector: "./img/icons/simbolo-default-verde.svg",
    altText: "Meu perfil",
    modules: 2
  },
  politico_sindical: {
    route: routes.POLITICO_SINDICAL,
    name: "Sindical",
    vector: "./img/icons/politico-sindical.svg",
    altText: "Político-Sindical",
    modules: 4
  },
  visualizador_contratos: {
    route: routes.CONTRATOS,
    name: "Contratos",
    vector: "./img/icons/contrato.svg",
    altText: "Contratos",
    modules: 1
  },
  pesquisa_satisfacao: {
    route: routes.PESQUISA_DE_SATISFACAO,
    name: "Satisfação",
    vector: "./img/icons/satisfacao.svg",
    altText: "Satisfação",
    modules: 2
  },
  autoridades: {
    route: routes.AUTORIDADES,
    name: "Autoridades",
    vector: "./img/icons/autoridade.svg",
    altText: "Autoridades",
    modules: 4
  },
  consignacao: {
    route: routes.CONSIGNACAO,
    name: 'Consignação',
    vector: './img/icons/simbolo-default-verde.svg',
    altText: 'Consignação',
    modules: 2
  },
  visualizador_consignacao: {
    route: routes.CONSIGNACAO,
    name: 'Consignação',
    vector: './img/icons/simbolo-default-verde.svg',
    altText: 'Consignação',
    modules: 2
  },
  sindex: {
    route: routes.SINDEX,
    name: "Sindex",
    vector: './img/icons/simbolo-default-azul.svg',
    altText: "Sindex",
    modules: 1
  },
  verificador_filiado: {
    route: routes.BRB,
    name: 'BRB',
    vector: './img/icons/simbolo-default-verde.svg',
    altText: 'BRB',
    modules: 2
  }
};