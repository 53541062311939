export default theme => ({
  root: {
    flexGrow: 1
  },
  demo: {
    backgroundColor: theme.palette.background.paper
  },
  grow: {
    flexGrow: 1
  },
  areaOperacoes: {
    marginTop: 16,
    marginBottom: 16
  },
  containerBottomPadding: {
    paddingBottom: 80
  }  
});
