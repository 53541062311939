import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AddAPhoto from '@material-ui/icons/AddAPhoto';
import LinearProgress from "@material-ui/core/LinearProgress";
import { uploadPhotoAutoridadePolitica, removerAnexo, downloadAnexo } from "../../firebase/storage";
import { incluirPhotoAutoridade } from "../../actions/autoridadePolitica";

const styles = (theme) => ({
    input: {
        display: "none"
    },
    progress: {
        width: '100%',
        marginTop: theme.spacing(2),
    }
});

class PhotoAutoridade extends Component {
    constructor(props) {
        super(props);
        this.state = {
            photo: props.photo || null,
            uploadProgress: 0,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.photo !== this.props.photo) {
            this.setState({ photo: this.props.photo });
        }
    }

    handleCapture = (event) => {
        const file = event.target.files[0];
        const { autoridadeId } = this.props;

        if (!file) return;

        const uploadTask = uploadPhotoAutoridadePolitica(`${autoridadeId}/${file.name}`, file);

        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                this.setState({ uploadProgress: progress });
            },
            (error) => {
                console.error("Erro ao fazer upload da foto:", error);
            },
            () => {
                uploadTask.snapshot.ref.getMetadata().then(({ fullPath }) => {
                    const photo = { nome: file.name, url: fullPath };
                    this.setState({ photo, uploadProgress: 0 });
                    this.props.onChange(photo);
                });
            }
        );
    };

    handleDelete = (photo) => {
        removerAnexo(photo.url)
            .then(() => {
                this.setState({ photo: null });
                this.props.onChange(null);
            })
            .catch((e) => console.error("Erro ao remover a foto:", e));
    };

    renderAnexo(photo) {
        return (
            <Chip
                key={photo.url}
                onDelete={() => this.handleDelete(photo)}
                onClick={() => downloadAnexo(photo.url)}
                label='Foto'
                clickable
                variant="outlined"
            />
        );
    }

    render() {
        const { classes, somenteVisualizacao } = this.props;
        const { photo, uploadProgress } = this.state;
        return (
            <Fragment>
                {uploadProgress > 0 && (
                    <LinearProgress
                        variant="determinate"
                        value={uploadProgress}
                        className={classes.progress}
                    />
                )}
                <Grid item container sm={12}>
                    {photo && this.renderAnexo(photo)}
                </Grid>
                {!somenteVisualizacao && !photo && (
                    <Grid item container sm={12} spacing={2}>
                        <input
                            accept="image/*"
                            className={classes.input}
                            id="upload-foto"
                            onChange={this.handleCapture}
                            type="file"
                        />
                        <label htmlFor="upload-foto">
                            <Button
                                color="primary"
                                component="span"
                                size="small"
                                style={{ marginRight: 8, marginTop: 10, marginBottom: 5 }}
                            >
                                <AddAPhoto />
                            </Button>
                        </label>
                    </Grid>
                )}
            </Fragment>
        );
    }
}

export default connect(null, { incluirPhotoAutoridade })(
    withStyles(styles, { withTheme: true })(PhotoAutoridade)
);
