import React, { useState } from "react";
import { Grid, TextField, Typography, Paper } from "@material-ui/core";
import { formatarValor } from "./JuriFiliCalculator";
import ButtonComponent from "../../commons/ButtonComponent";
import styles from "./Calculators.module.css";

const SindexCalculator = () => {
  const [utilizacaoReal, setUtilizacaoReal] = useState("");
  const [utilizacaoEsperada, setUtilizacaoEsperada] = useState("");
  const [receitaMensal, setReceitaMensal] = useState("");
  const [custoMensal, setCustoMensal] = useState("");
  const [pontuacaoSERVQUAL, setPontuacaoSERVQUAL] = useState("");
  const [result, setResult] = useState(0);

  const parseNumber = (value) => {
    if (!value) return 0;

    let cleanedValue = value.replace(",", ".");

    cleanedValue = cleanedValue.replace(/[^0-9.]/g, "");

    const number = parseFloat(cleanedValue);
    return isNaN(number) ? 0 : number;
  };

  const handleCalcular = () => {
    const realNumerico = parseNumber(utilizacaoReal);
    const esperadaNumerico = parseNumber(utilizacaoEsperada);
    const servqualNumerico = parseNumber(pontuacaoSERVQUAL);
    const receitaNumerico = parseNumber(receitaMensal) / 100;
    const custoNumerico = parseNumber(custoMensal) / 100;
    const PESO_1 = 0.4;
    const PESO_2 = 0.4;
    const PESO_3 = 0.2;

    const step1 = (realNumerico / esperadaNumerico) * PESO_1;
    const step2 = (receitaNumerico / custoNumerico) * PESO_2;
    const step3 = (servqualNumerico / 5) * PESO_3;

    const sindex = (step1 + step2 + step3) * 5;
    setResult(sindex);
  };

  const handleLimpar = () => {
    setUtilizacaoReal("");
    setUtilizacaoEsperada("");
    setReceitaMensal("");
    setCustoMensal("");
    setPontuacaoSERVQUAL("");
    setResult(0);
  };

  return (
    <Paper elevation={3} className={styles.bodyCalculator}>
      <Typography variant="h5" align="start" gutterBottom>
        Cálculo do Índice de Viabilidade Financeira (Sindex) -{" "}
        {result.toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Utilização Real"
            value={utilizacaoReal}
            onChange={(e) => setUtilizacaoReal(e.target.value)}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Utilização Esperada"
            value={utilizacaoEsperada}
            onChange={(e) => setUtilizacaoEsperada(e.target.value)}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Receita Mensal"
            value={receitaMensal}
            onChange={(e) => setReceitaMensal(formatarValor(e.target.value))}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Custo Mensal"
            value={custoMensal}
            onChange={(e) => setCustoMensal(formatarValor(e.target.value))}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Pontuação SERVQUAL"
            value={pontuacaoSERVQUAL}
            onChange={(e) => setPontuacaoSERVQUAL(e.target.value)}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6} container justifyContent="center">
          <ButtonComponent
            variant="insert"
            size="large"
            onClick={handleCalcular}
          >
            Calcular
          </ButtonComponent>
          <ButtonComponent
            variant="secondary"
            size="large"
            onClick={handleLimpar}
          >
            Limpar
          </ButtonComponent>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SindexCalculator;
