import React from "react";
import TextField from "@material-ui/core/TextField";

const InputWithPrefix = ({ input, label, meta }) => {
  const handleChange = (event) => {
    const value = event.target.value;

    if (value.startsWith("P - ")) {
      input.onChange(value);
    } else {
      input.onChange(`P - ${value}`);
    }
  };

  return (
    <div>
      <TextField
        {...input}
        label={label}
        InputLabelProps={{ shrink: true }}
        value={input.value || ""}
        onChange={handleChange}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
        fullWidth
        margin="dense"
      />
    </div>
  );
};

export default InputWithPrefix;
