export default theme => ({
  button: {
    marginTop: 10,
    marginBottom: 10
  },
  media: {
    height: 140
  },
  title: {
    marginTop: 40
  },
  cardTitle: {
    fontSize: 14,
  },
  areaOperacoes: {
    marginTop: 16,
    marginBottom: 16
  },
  containerBottomPadding: {
    paddingBottom: 80
  }
});
