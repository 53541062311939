import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { compose } from "recompose";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Container from '@material-ui/core/Container';
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import * as roles from "../../constants/roles";
import * as routes from "../../constants/routes";
import { getProcessos } from "../../actions/juridico";
import tableI18n from "../TableLocalization";
import SindilegisBar from "../commons/SindilegisBar";
import styles from "./styles";
import Loading from "../commons/Loading";
import { downloadXlsx } from "../commons/util";
import { formataData, formatarValor } from "../Financial/util";
import CustomDialog from "../commons/CustomDialog";
import TooltipText from "../commons/TooltipText";
import MenuButton from "../commons/MenuButton";

function converterParaNumber(valor) {
    return valor ? Number(valor.replaceAll(".", "").replaceAll(",", ".")) : 0;
}

class RelatorioProcessos extends React.Component {
    state = {
        dialog: {
            open: false,
            tipo: '',
            conteudo: ''
        }
    }


    componentDidMount() {
        this.props.getProcessos()
    }

    handleOpenDialog = (tipo, conteudo) => {
        this.setState({
            dialog: {
                open: true,
                tipo,
                conteudo
            }
        });
    }

    handleCloseDialog = () => {
        this.setState({
            dialog: {
                open: false,
                tipo: '',
                conteudo: ''
            }
        });
    }

    handleOpenComentarios = (selectedComentarios) => {
        const comentariosArray = selectedComentarios.includes("; ")
            ? selectedComentarios.split("; ")
            : [selectedComentarios];

        this.handleOpenDialog('Comentários', comentariosArray);
    };

    handleOpenEscritorio = (selectedEscritorio) => {
        this.handleOpenDialog('Escritório', selectedEscritorio);
    }

    handleOpenFiliados = (selectedFiliados) => {
        const filiadosArray = selectedFiliados.includes(', ') ? selectedFiliados.split(', ') : [selectedFiliados]
        this.handleOpenDialog('Filiados', filiadosArray);
    }

    handleOpenTribunal = (selectedTribunal) => {
        this.handleOpenDialog('Tribunal/Órgão', selectedTribunal);
    }

    render() {
        const { classes, processos, authUser, loading, location } = this.props;
        const isAuth = Boolean(authUser)
        const canExport = _.includes(authUser.roles, roles.GESTOR_USUARIO);
        const { dialog } = this.state;
        const isEscritorioJuridico = _.includes(
            authUser.roles,
            roles.ESCRITORIO_JURIDICO
        );

        processos.forEach(p => {
            p.valor_causa = formatarValor(converterParaNumber(p.valorCausa))
            p.ano = p.dataProtocolo ? new Date(p.dataProtocolo).getFullYear() : ''
            p.filiadosNomes = _.size(p.filiados) > 0 ? p.filiados.map((f) => f.nome_completo).join(", ") : ''
            p.todosComentarios = _.size(p.comentarios) > 0 ? p.comentarios.map(c => c.comentario).join("; ") : '';
            p.portal = p.publicarPortalSindilegis === undefined ? "" : p.publicarPortalSindilegis ? "S" : "N"
            p.empresas = p.filiados ?
                _.uniq(p.filiados.filter((f) => f.empresa != null && f.empresa.length > 0).map((f) => f.empresa)).join(", ")
                : ""
            p.nomeEscritorio = p.escritorio ? p.escritorio.nome : ""
        })

        const BUTTON_ROUTES = [
            { label: 'Jurídico', route: routes.JURIDICO },
            { label: 'Gerenciar objetos de processo', route: routes.OBJETO_PROCESSO, condition: isEscritorioJuridico },
            { label: 'Gerenciar processos', route: routes.JURIDICO_PROCESSOS },
        ].filter(button => button.condition !== true)
        const CONDITIONAL_BUTTON = [
            { label: 'Escritórios', route: routes.ESCRITORIOS, condition: isAuth },
        ]

        return (
            <React.Fragment>
                <SindilegisBar />
                <Loading loading={loading} />
                {!loading && <Container maxWidth="xl">
                    <Grid container spacing={2} justifyContent="center" alignItems="center" className={classes.areaOperacoes}>
                        <MenuButton buttonsRoutes={BUTTON_ROUTES} location={location} additionalButtons={CONDITIONAL_BUTTON} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <div className={classes.demo}>
                            <MaterialTable
                                columns={[
                                    {
                                        title: "Tipo processo", field: "tipo",

                                    },
                                    { title: "Número processo", field: "numeroProcesso" },
                                    { title: "Ano", field: "ano" },
                                    {
                                        title: "Comentários", field: "todosComentarios",
                                        render: rowData => (
                                            <TooltipText
                                                text={rowData.todosComentarios ? 'Clique para ver os comentários' : 'Não há comentários'}
                                                onClick={() => rowData.todosComentarios ? this.handleOpenComentarios(rowData.todosComentarios) : null}
                                            />

                                        )
                                    },
                                    {
                                        title: "Escritório", field: "nomeEscritorio",
                                        render: rowData => (
                                            <TooltipText
                                                text={rowData.nomeEscritorio}
                                                onClick={() => this.handleOpenEscritorio(rowData.nomeEscritorio)}
                                            />
                                        )
                                    },
                                    {
                                        title: "Filiados", field: "filiadosNomes",
                                        render: rowData => (
                                            <TooltipText
                                                text={rowData.filiadosNomes}
                                                onClick={() => this.handleOpenFiliados(rowData.filiadosNomes)}
                                            />
                                        )
                                    },
                                    { title: "Casa(s)", field: "empresas" },
                                    {
                                        title: "Tribunal/Orgão", field: "tribunalOrgao",
                                        render: rowData => (
                                            <TooltipText
                                                text={rowData.tribunalOrgao}
                                                onClick={() => this.handleOpenTribunal(rowData.tribunalOrgao)}
                                            />
                                        )
                                    },
                                    { title: "Situação", field: "situacao" },
                                    { title: "Valor", field: "valor_causa" },
                                    { title: "Portal", field: "portal" },
                                    {
                                        title: "Data", field: "dataProtocolo",
                                        render: (row) => (
                                            <span>{formataData(row.dataProtocolo)}</span>
                                        ),
                                        hidden: true,
                                    },
                                    {
                                        title: "CPF Filiados",
                                        field: "filiados",
                                        render: (row) => {
                                            return row.filiados
                                                ? row.filiados.map((filiado) => filiado.cpf).join(", ") : false
                                        },
                                        hidden: true,
                                    }


                                ]}
                                data={processos}
                                title="Relatório de processos"
                                localization={tableI18n}
                                options={{
                                    filtering: true,
                                    exportButton: canExport,
                                    exportAllData: true,
                                    exportDelimiter: ';',
                                    exportFileName: 'relatorio de processos',
                                    exportCsv: (columns, data) => {
                                        const dadosFormatados = data.map(row => ({
                                            ...row,
                                            dataProtocolo: formataData(row.dataProtocolo),
                                            filiados: row.filiados ? row.filiados.map(filiado => filiado.cpf.toString()).join(", ") : ''
                                        }))
                                        downloadXlsx(columns, dadosFormatados, 'processos.xlsx');
                                    },
                                    padding: 'dense'
                                }}
                                icons={{ Filter: () => (<span></span>) }}
                            />
                        </div>
                    </Grid>
                </Container>}
                <CustomDialog
                    open={dialog.open}
                    onClose={this.handleCloseDialog}
                    title={dialog.tipo}
                    content={Array.isArray(dialog.conteudo) ? dialog.conteudo : dialog.conteudo}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({
    juridico: { processos, loading },
}) => ({
    processos,
    loading,
});

RelatorioProcessos.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withStyles(styles),
    connect(
        mapStateToProps,
        { getProcessos }
    ),
)(RelatorioProcessos);
