export const AUTH_USER = 'auth_user';
export const AUTH_ERROR = 'auth_error';
export const LIQUIDAR_PAGAMENTO = 'LIQUIDAR_PAGAMENTO'
export const EXPENSES_SET = 'EXPENSES_SET'
export const EXPENSE_SET = 'EXPENSE_SET'
export const EXPENSE_ID_SET = 'EXPENSE_ID_SET'
export const EXPENSE_UPDATED = 'EXPENSE_UPDATED'
export const DELETE_EXPENSE = 'DELETE_EXPENSE'
export const HISTORICO_DESPESA_SET = 'HISTORICO_DESPESA_SET'
export const RECEITAS_E_DEPESAS_SET = 'RECEITAS_E_DEPESAS_SET'

export const RECEITAS_SET = 'RECEITAS_SET'
export const RECEITA_SET = 'RECEITA_SET'
export const RECEITA_ID_SET = 'RECEITA_ID_SET'
export const DELETE_RECEITA = 'DELETE_RECEITA'
export const RECEITA_DESPESA_REQUEST = 'RECEITA_DESPESA_REQUEST'
export const RECEITA_UPDATED = 'RECEITA_UPDATED'

export const ITENS_DESPESA_SET = 'ITENS_DESPESA_SET'
export const ITEM_DESPESA_SET = 'ITEM_DESPESA_SET'
export const ITEM_DESPESA_ID_SET = 'ITEM_DESPESA_ID_SET'
export const ITENS_DESPESA_REQUEST = 'ITENS_DESPESA_REQUEST'
export const ITEM_DESPESA_UPDATED = 'ITEM_DESPESA_UPDATED'
export const DELETE_ITEM_DESPESA = 'DELETE_ITEM_DESPESA'

export const FILIADO_SET = 'FILIADO_SET'
export const FILIADOS_SET = 'FILIADOS_SET'
export const FILIADOS_REQUEST = 'FILIADOS_REQUEST'
export const FILIADO_ID_SET = 'FILIADO_ID_SET'
export const FILIADO_UPDATED = 'FILIADO_UPDATED'
export const DELETE_FILIADO = 'DELETE_FILIADO'
export const HISTORICO_SET = 'HISTORICO_SET'

export const DEPENDENTS_SET = 'DEPENDENTS_SET'
export const DEPENDENT_UPDATED = 'DEPENDENT_UPDATED'
export const DEPENDENT_SET = 'DEPENDENT_SET'
export const REMOVE_DEPENDENT = 'REMOVE_DEPENDENT'
export const DEPENDENTES_COM_TITULAR_SET = 'DEPENDENTES_COM_TITULAR_SET'

export const ROLES_SET = 'ROLES_SET'
export const REMOVE_ROLE = 'REMOVE_ROLE'

export const USERS_SET = 'USERS_SET'
export const USERS_REQUEST = 'USERS_REQUEST'
export const USER_SET = 'USER_SET'
export const USER_ID_SET = 'USER_ID_SET'


export const BENEFICIARIOS_SET = 'BENEFICIARIOS_SET'
export const BENEFICIARIO_SET = 'BENEFICIARIO_SET'
export const BENEFICIARIO_ID_SET = 'BENEFICIARIO_ID_SET'
export const BENEFICIARIOS_REQUEST = 'BENEFICIARIOS_REQUEST'
export const BENEFICIARIO_UPDATED = 'BENEFICIARIO_UPDATED'
export const DELETE_BENEFICIARIO = 'DELETE_BENEFICIARIO'

export const DOCUMENTOS_SET = 'DOCUMENTOS_SET'
export const DOCUMENTO_SET = 'DOCUMENTO_SET'
export const DOCUMENTO_ID_SET = 'DOCUMENTO_ID_SET'
export const DOCUMENTOS_REQUEST = 'DOCUMENTOS_REQUEST'
export const DOCUMENTO_UPDATED = 'DOCUMENTO_UPDATED'
export const DELETE_DOCUMENTO = 'DELETE_DOCUMENTO'

export const DOCUMENTOS_TRANSPARENCIA_SET = 'DOCUMENTOS_TRANSPARENCIA_SET'
export const DOCUMENTO_TRANSPARENCIA_SET = 'DOCUMENTO_TRANSPARENCIA_SET'
export const DOCUMENTO_TRANSPARENCIA_ID_SET = 'DOCUMENTO_TRANSPARENCIA_ID_SET'
export const DOCUMENTOS_TRANSPARENCIA_REQUEST = 'DOCUMENTOS_TRANSPARENCIA_REQUEST'
export const DOCUMENTO_TRANSPARENCIA_UPDATED = 'DOCUMENTO_TRANSPARENCIA_UPDATED'
export const DELETE_DOCUMENTO_TRANSPARENCIA = 'DELETE_DOCUMENTO_TRANSPARENCIA'

export const CONSIGNACOES_SET = 'CONSIGNACOES_SET'
export const CONSIGNACOES_REQUEST = 'CONSIGNACOES_REQUEST'

export const EVENTOS_SET = 'EVENTOS_SET'
export const EVENTO_SET = 'EVENTO_SET'
export const EVENTO_ID_SET = 'EVENTO_ID_SET'
export const EVENTOS_REQUEST = 'EVENTOS_REQUEST'
export const EVENTO_UPDATED = 'EVENTO_UPDATED'
export const DELETE_EVENTO = 'DELETE_EVENTO'

export const FUNCIONARIOS_SET = 'FUNCIONARIOS_SET'
export const FUNCIONARIO_SET = 'FUNCIONARIO_SET'
export const FUNCIONARIO_ID_SET = 'FUNCIONARIO_ID_SET'
export const FUNCIONARIOS_REQUEST = 'FUNCIONARIOS_REQUEST'
export const FUNCIONARIO_UPDATED = 'FUNCIONARIO_UPDATED'
export const DELETE_FUNCIONARIO = 'DELETE_FUNCIONARIO'

export const POLITICOS_SET = 'POLITICOS_SET'
export const POLITICO_SET = 'POLITICO_SET'
export const POLITICO_ID_SET = 'POLITICO_ID_SET'
export const POLITICOS_REQUEST = 'POLITICOS_REQUEST'
export const POLITICO_UPDATE = 'POLITICO_UPDATE'
export const DELETE_POLITICO = 'DELETE_POLITICO'

export const OBJETOS_PROCESSO_SET = 'OBJETOS_PROCESSO_SET'
export const OBJETO_PROCESSO_SET = 'OBJETO_PROCESSO_SET'
export const OBJETO_PROCESSO_ID_SET = 'OBJETO_PROCESSO_ID_SET'
export const OBJETOS_PROCESSO_REQUEST = 'OBJETOS_PROCESSO_REQUEST'
export const OBJETO_PROCESSO_UPDATED = 'OBJETO_PROCESSO_UPDATED'
export const DELETE_OBJETO_PROCESSO = 'DELETE_OBJETO_PROCESSO'

export const FILIADOS_PROCESSO_SET = 'FILIADOS_PROCESSO_SET'
export const FILIADO_PROCESSO_SET = 'FILIADO_PROCESSO_SET'
export const FILIADO_PROCESSO_ID_SET = 'FILIADO_PROCESSO_ID_SET'
export const FILIADOS_PROCESSO_REQUEST = 'FILIADOS_PROCESSO_REQUES'
export const FILIADO_PROCESSO_UPDATED = 'FILIADO_PROCESSO_UPDATED'
export const DELETE_FILIADO_PROCESSO = 'DELETE_FILIADO_PROCESSO'

export const INTERACOES_SET = 'INTERACOES_SET'
export const INTERACAO_SET = 'INTERACAO_SET'
export const INTERACAO_ID_SET = 'INTERACAO_ID_SET'
export const INTERACOES_REQUEST = 'INTERACOES_REQUEST'
export const INTERACAO_UPDATED = 'INTERACAO_UPDATED'
export const DELETE_INTERACAO = 'DELETE_INTERACAO'

export const SUPRIMENTOS_SET = 'SUPRIMENTOS_SET'
export const SUPRIMENTO_SET = 'SUPRIMENTO_SET'
export const SUPRIMENTO_ID_SET = 'SUPRIMENTO_ID_SET'
export const DELETE_SUPRIMENTO = 'DELETE_SUPRIMENTO'
export const SUPRIMENTO_DESPESA_REQUEST = 'SUPRIMENTO_DESPESA_REQUEST'
export const SUPRIMENTO_UPDATED = 'SUPRIMENTO_UPDATED'

export const ITENS_RECEITA_SET = 'ITENS_RECEITA_SET'
export const ITEM_RECEITA_SET = 'ITEM_RECEITA_SET'
export const ITEM_RECEITA_ID_SET = 'ITEM_RECEITA_ID_SET'
export const ITENS_RECEITA_REQUEST = 'ITENS_RECEITA_REQUEST'
export const ITEM_RECEITA_UPDATED = 'ITEM_RECEITA_UPDATED'
export const DELETE_ITEM_RECEITA = 'DELETE_ITEM_RECEITA'

export const JURIDICO_ATENDIMENTOS_SET = 'JURIDICO_ATENDIMENTOS_SET'
export const JURIDICO_ATENDIMENTO_ID_SET = 'JURIDICO_ATENDIMENTO_ID_SET'
export const JURIDICO_ATENDIMENTO_SET = 'JURIDICO_ATENDIMENTO_SET'
export const JURIDICO_ATENDIMENTOS_REQUEST = 'JURIDICO_ATENDIMENTOS_REQUEST'
export const JURIDICO_ATENDIMENTO_UPDATED = 'JURIDICO_ATENDIMENTO_UPDATED'
export const JURIDICO_DELETE_ATENDIMENTO = 'JURIDICO_DELETE_ATENDIMENTO'


export const JURIDICO_ESCRITORIO_ADVOCACIA_ID_SET = 'JURIDICO_ESCRITORIO_ADVOCACIA_ID_SET'
export const JURIDICO_ESCRITORIO_ADVOCACIA_SET = 'JURIDICO_ESCRITORIO_ADVOCACIA_SET'
export const JURIDICO_ESCRITORIO_ADVOCACIA_UPDATED = 'JURIDICO_ESCRITORIO_UPDATED'
export const JURIDICO_ESCRITORIOS_ADVOCACIA_SET = 'JURIDICO_ESCRITORIOS_ADVOCACIA_SET'
export const JURIDICO_ESCRITORIOS_ADVOCACIA_REQUEST = 'JURIDICO_ESCRITORIOS_ADVOCACIA_REQUEST'
export const DELETE_JURIDICO_ESCRITORIO_ADVOCACIA = 'DELETE_JURIDICO_ESCRITORIO_ADVOCACIA'
export const JURIDICO_PROCESSOS_REQUEST = 'JURIDICO_PROCESSOS_REQUEST'
export const JURIDICO_PROCESSOS_SET = 'JURIDICO_PROCESSOS_SET'
export const JURIDICO_PROCESSO_UPDATED = 'JURIDICO_PROCESSO_UPDATED'
export const JURIDICO_PROCESSO_SET = 'JURIDICO_PROCESSO_SET'
export const JURIDICO_PROCESSO_REQUEST = 'JURIDICO_PROCESSO_REQUEST'
export const JURIDICO_EVENTO_PROCESSO_ID_SET = 'JURIDICO_EVENTO_PROCESSO_ID_SET'

export const ITENS_INTERACAO_SET = 'ITENS_INTERACAO_SET'
export const ITEM_INTERACAO_SET = 'ITEM_INTERACAO_SET'
export const ITEM_INTERACAO_ID_SET = 'ITEM_INTERACAO_ID_SET'
export const ITENS_INTERACAO_REQUEST = 'ITENS_INTERACAO_REQUEST'
export const ITEM_INTERACAO_UPDATED = 'ITEM_INTERACAO_UPDATED'
export const DELETE_ITEM_INTERACAO = 'DELETE_ITEM_INTERACAO'

export const COMUNICACAO_FILIADOS_SET = 'COMUNICACAO_FILIADOS_SET'

export const POLITICO_SINDICAL_TEMA_SET = 'POLITICO_SINDICAL_TEMA_SET'
export const POLITICO_SINDICAL_TEMAS_SET = 'POLITICO_SINDICAL_TEMAS_SET'
export const POLITICO_SINDICAL_TEMAS_REQUEST = 'POLITICO_SINDICAL_TEMAS_REQUEST'
export const POLITICO_SINDICAL_TEMA_ID_SET = 'POLITICO_SINDICAL_TEMA_ID_SET'
export const POLITICO_SINDICAL_TEMA_UPDATED = 'POLITICO_SINDICAL_TEMA_UPDATED'
export const POLITICO_SINDICAL_DELETE_TEMA = 'POLITICO_SINDICAL_DELETE_TEMA'
export const POLITICO_SINDICAL_TEMA_STEP_SET = 'POLITICO_SINDICAL_TEMA_STEP_SET'
export const POLITICO_SINDICAL_TEMA_DESCARTADO = 'POLITICO_SINDICAL_TEMA_DESCARTADO'
export const POLITICO_SINDICAL_TEMAS_DESCARTADOS = 'POLITICO_SINDICAL_TEMAS_DESCARTADOS'
export const POLITICO_SINDICAL_TOTAL_TEMAS_SITUACAO_SET = 'POLITICO_SINDICAL_TOTAL_TEMAS_SITUACAO_SET'
export const POLITICO_SINDICAL_ITEM_DESTAQUE_TEMA_ID_SET = 'POLITICO_SINDICAL_ITEM_DESTAQUE_TEMA_ID_SET'
export const POLITICO_SINDICAL_TOTAL_TEMAS_SITUACAO_DESCARTADO = 'POLITICO_SINDICAL_TOTAL_TEMAS_SITUACAO_DESCARTADO'
export const POLITICO_SINDICAL_DADOS_IDENTIFICACAO_PROCESSO_SET = 'POLITICO_SINDICAL_DADOS_IDENTIFICACAO_PROCESSO_SET'
export const POLITICO_SINDICAL_DADOS_IDENTIFICACAO_PROCESSO_DESCARTADO = 'POLITICO_SINDICAL_DADOS_IDENTIFICACAO_PROCESSO_DESCARTADO'