import React, { Component, Fragment } from 'react';
import { connect } from "react-redux"
import { withStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import AttachFile from '@material-ui/icons/AttachFile';
import LinearProgress from '@material-ui/core/LinearProgress';
import { uploadAnexoAutoridadePolitica } from '../../../firebase/storage';
import { incluirAnexosAutoridade } from '../../../actions/autoridadePolitica';

const styles = (theme) => ({
    input: {
        display: 'none'
    },
    progress: {
        width: '100%',
        marginTop: theme.spacing(2)
    }
});

class AnexoAutoridade extends Component {
    state = { anexo: {}, uploadProgress: 0 };

    componentDidMount() {
        if (this.props.anexo) {
            this.setState({ anexo: this.props.anexo })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.anexos !== this.props.anexos) {
            this.setState({ anexos: this.props.anexos });

        }
    }

    handleCapture = (event) => {
        const file = event.target.files[0];
        const { id } = this.props;

        const uploadTask = uploadAnexoAutoridadePolitica(`${id}/${file.name}`, file)

        uploadTask.on('state_changed',

            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                this.setState({ uploadProgress: progress });
            },
            (error) => {
                console.error("Erro ao fazer upload do anexo:", error);

            }, () => {
                uploadTask.snapshot.ref.getMetadata().then(({ fullPath }) => {
                    const anexo = { nome: file.name, url: fullPath, id: fullPath };
                    const anexos = Object.values(this.state.anexo);
                    const updatedAnexos = [...anexos, anexo];
                    this.setState({ anexo: updatedAnexos, uploadProgress: 0 })
                    this.props.onChange(updatedAnexos)
                })
            }
        )
    };

    render() {
        const { classes } = this.props;
        const { uploadProgress } = this.state;

        return (
            <Fragment>
                {uploadProgress > 0 && (
                    <LinearProgress
                        variant="determinate"
                        value={uploadProgress}
                        className={classes.progress}
                    />
                )}
                <Grid item container sm={12} spacing={2}>
                    <input
                        className={classes.input}
                        id="upload-anexo"
                        onChange={this.handleCapture}
                        type="file"
                    />
                    <label htmlFor="upload-anexo">
                        <Button
                            color="primary"
                            component="span"
                            size="small"
                            style={{ marginRight: 8, marginTop: 10, marginBottom: 5 }}
                        >
                            <AttachFile />
                            Anexar Documentos
                        </Button>
                    </label>
                </Grid>
            </Fragment>
        );
    }
}

export default connect(null, { incluirAnexosAutoridade })(withStyles(styles, { withTheme: true })(AnexoAutoridade))