import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Container, Button, Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'
  },
  message: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    textTransform: 'none',
  },
}));

const UnauthorizedPage = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleRedirect = () => {
    history.push('/');
  };

  return (
    <Container className={classes.root}>
      <Box>
        <Typography variant="h4" component="h1" className={classes.message} gutterBottom>
          Acesso não autorizado
        </Typography>
        <Typography variant="body1" className={classes.message}>
          Desculpe, você não tem permissão para acessar o sistema.
        </Typography>
        <Box display="flex" justifyContent="flex-end">
            <Button
                color="primary"
                className={classes.button}
                onClick={handleRedirect}>
             Voltar
            </Button>
        </Box>
       
      </Box>
    </Container>
  );
};

export default UnauthorizedPage;
