import { INTERACOES_SET, INTERACAO_SET, INTERACAO_ID_SET, INTERACOES_REQUEST, INTERACAO_UPDATED, DELETE_INTERACAO } from './types';
import { db } from "../firebase";

export const saveInteracao = (id, data, callback) => {
     return dispatch => {
          db.doSaveInteracao(id, data).then(() => {
               dispatch({ type: INTERACAO_UPDATED, payload: { id, data } });
               callback && callback();
          });
     };
}

export const criarInteracao = (filiadoId, data, callback) => {
     return dispatch => {
          db.doCreateInteracao(filiadoId, data).then(() => {
               callback && callback();
          });
     };
}

export const getInteracoes = () => {
     return dispatch => {
          dispatch({ type: INTERACOES_REQUEST });
          db.onceGetInteracoes().then((snapshot) => {
               let interacoes = snapshot.val() || {};
               dispatch({ type: INTERACOES_SET, interacoes });
          })
     }
}

export const getInteracao = (id) => {
     return dispatch => {
          db.onceGetInteracao(id).then((snapshot) => {
               const interacao = snapshot.val()
               dispatch({ type: INTERACAO_SET, interacao })
          });
     }
}

export const deleteInteracao = (id) => {
     return dispatch => {
          db.doDeleteInteracao(id).then(() => {
               dispatch({ type: DELETE_INTERACAO, payload: { id } });
          })
     }
}

export const setInteracao = (interacao) => dispatch => dispatch({ type: INTERACAO_SET, interacao })

export const setInteracaoId = (interacaoId) => dispatch => dispatch({ type: INTERACAO_ID_SET, interacaoId })