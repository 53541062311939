import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { compose } from "recompose";
import PropTypes from "prop-types";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from "@material-ui/core/Grid";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { getHistoricoDespesa, setHistoricoDespesa } from "../../actions/financial";
import { formataData } from "./util";

class HistoricoDespesa extends Component {

  componentDidMount() {
    const { getHistoricoDespesa, id } = this.props;
    if (id) {
      getHistoricoDespesa(id)
    }
  }

  componentWillUnmount() {
    const { setHistoricoDespesa } = this.props;
    setHistoricoDespesa(null)
  }

  render() {
    const { classes, historico } = this.props;

    if (_.isEmpty(historico)) {
      return null;
    }

    return (
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>Histórico</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {this.renderItem(historico)}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }

  renderItem(historico) {
    return (
      <Grid container item spacing={2} sm={12}>
        <Toolbar>
          <Typography variant="h6" id="tableTitle">Histórico de alterações</Typography>
        </Toolbar>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Data</TableCell>
              <TableCell>Alteração</TableCell>
              <TableCell>Responsável</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {historico.map(row => (
              <TableRow key={row.data}>
                <TableCell>{formataData(row.data)}</TableCell>
                {row.diff ? (<TableCell>{Object.keys(row.diff).map(key => this.renderCell(key, row))}</TableCell>) : <TableCell></TableCell>}
                <TableCell>{row.nome_responsavel}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    );
  }

  renderCell(key, row) {
    let value = row.diff[key];

    if (key === 'anexos') {
      value = _.values(value).map(v => v.nome).join(', ');
    }

    return (<Typography variant='body2' key={key}>{`${key}: ${value}`}</Typography>);
  }
}

const mapStateToProps = ({ financial }) => ({
  historico: financial.historico,
});

HistoricoDespesa.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    { getHistoricoDespesa, setHistoricoDespesa }
  )
)(HistoricoDespesa);
