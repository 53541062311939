import _ from 'lodash'
import { 
     POLITICO_SINDICAL_TEMAS_SET, 
     POLITICO_SINDICAL_TEMA_SET, 
     POLITICO_SINDICAL_TEMA_ID_SET, 
     POLITICO_SINDICAL_TEMA_STEP_SET,
     POLITICO_SINDICAL_TEMAS_REQUEST, 
     POLITICO_SINDICAL_TEMA_UPDATED, 
     POLITICO_SINDICAL_DELETE_TEMA,
     POLITICO_SINDICAL_TEMA_DESCARTADO,
     POLITICO_SINDICAL_TEMAS_DESCARTADOS,
     POLITICO_SINDICAL_TOTAL_TEMAS_SITUACAO_SET,
     POLITICO_SINDICAL_ITEM_DESTAQUE_TEMA_ID_SET,
     POLITICO_SINDICAL_DADOS_IDENTIFICACAO_PROCESSO_SET,
     POLITICO_SINDICAL_DADOS_IDENTIFICACAO_PROCESSO_DESCARTADO,
     POLITICO_SINDICAL_TOTAL_TEMAS_SITUACAO_DESCARTADO
} from './types'
import { db } from '../firebase'
import { getItensAsArray, isSituacaoTemaEncerrado, LABELS_SITUACAO_TEMA, sanearFieldsArrayTema, SITUACAO_TEMA_EM_ACOMPANHAMENTO, SITUACAO_TEMA_ENCERRADO } from '../controllers/temaController'

export const startRequest = () => dispatch => dispatch({ type: POLITICO_SINDICAL_TEMAS_REQUEST })

export const saveTema = (id, tema, callback) => {
     return dispatch => {
          const temaSaneado = sanearFieldsArrayTema(tema)
          temaSaneado.lastUpdated = new Date().getTime();
          db.doSaveTema(id, temaSaneado).then(() => {
               dispatch({ type: POLITICO_SINDICAL_TEMA_UPDATED, payload: { id, data: temaSaneado } })
               callback && callback()
          })
     }
}

export const createTema = (data, callback) => {
     return dispatch => {
          const now = new Date().getTime()
          const temaToCreate = {...data, createdAt: now, lastUpdated: now }
          db.doCreateTema(temaToCreate).then(() => {
               db.onceGetLastFiveTemas().then(snapshot => {
                    const lastFiveTemas = snapshot.val()
                    const createdNowKey = _.findKey(lastFiveTemas, ({ createdAt, titulo }) => createdAt === now && titulo === temaToCreate.titulo)
                    dispatch({ type: POLITICO_SINDICAL_TEMA_ID_SET, temaId: createdNowKey })
                    callback && callback()
               })
               
          })
     }
}

export const incluirDocumentoTema = (temaId, path) => {
     return dispatch => {
          db.doIncluirDocumentoTema(temaId, path)
     }
}

export const incluirLinkTema = (temaId, link) => {
     return dispatch => {
          db.doIncluirLinkTema(temaId, link)
     }
}

export const incluirDocumentoItemDestaqueTema = (temaId, itemDestaqueId, path) => {
     return dispatch => {
          db.doIncluirDocumentoItemDestaqueTema(temaId, itemDestaqueId, path)
     }
}

export const incluirLinkItemDestaqueTema = (temaId, itemDestaqueId, link) => {
     return dispatch => {
          db.doIncluirLinkItemDestaqueTema(temaId, itemDestaqueId, link)
     }
}

export const createItemDestaqueTema = (temaId, itemDestaque) => {
     return dispatch => {
          db.doCreateItemDestaqueTema(temaId, itemDestaque).then(() => {
               db.onceGetItensDestaqueTema(temaId).then((snapshot) => {
                    const itens = snapshot.val()
                    const itemDestaqueId = _.findLastKey(itens, item => item.descricao_item === itemDestaque.descricao_item)
                    dispatch({ type: POLITICO_SINDICAL_ITEM_DESTAQUE_TEMA_ID_SET, itemDestaqueId })
               })
          })
     }
}

export const toggleSituacaoTema = (uidTema, situacao) => {
     const toggledSituacaoTema = isSituacaoTemaEncerrado(situacao) 
          ? SITUACAO_TEMA_EM_ACOMPANHAMENTO 
          : SITUACAO_TEMA_ENCERRADO
     return dispatch => {
          db.doToggleSituacaoTema(uidTema, toggledSituacaoTema)
     }
}

export const saveItemDestaqueTema = (id, temaId, itemDestaque) => {
     return dispatch => {
          db.doSaveItemDestaqueTema(id, temaId, itemDestaque)
     }
}

export const deleteItemDestaqueTema = (id, temaId) => {
     return dispatch => {
          db.doDeleteItemDestaqueTema(id, temaId)
     }
}

export const createPessoaInstituicaoTema = (temaId, pessoaInstituicao) => {
     return dispatch => {
          db.doCreatePessoaInstituicaoTema(temaId, pessoaInstituicao)
     }
}

export const savePessoaInstituicaoTema = (id, temaId, pessoaInstituicao) => {
     return dispatch => {
          db.doSavePessoaInstituicaoTema(id, temaId, pessoaInstituicao)
     }
}

export const deletePessoaInstituicaoTema = (id, temaId) => {
     return dispatch => {
          db.doDeletePessoaInstituicaoTema(id, temaId)
     }
}

export const createProcessoTema = (temaId, processo) => {
     return dispatch => {
          db.doCreateProcessoTema(temaId, processo)
     }
}

export const saveProcessoTema = (id, temaId, processo) => {
     return dispatch => {
          db.doSaveProcessoTema(id, temaId, processo)
     }
}

export const deleteProcessoTema = (id, temaId) => {
     return dispatch => {
          db.doDeleteProcessoTema(id, temaId)
     }
}

export const createAcaoSindilegisTema = (temaId, acao) => {
     return dispatch => {
          db.doCreateAcaoSindilegisTema(temaId, acao)
     }
}

export const saveAcaoSindilegisTema = (id, temaId, acao) => {
     return dispatch => {
          db.doSaveAcaoSindilegisTema(id, temaId, acao)
     }
}

export const deleteAcaoSindilegisTema = (id, temaId) => {
     return dispatch => {
          db.doDeleteAcaoSindilegisTema(id, temaId)
     }
}

export const incluirDocumentoAcaoSindilegisTema = (temaId, acaoId, path) => {
     return dispatch => {
          db.doIncluirDocumentoAcaoSindilegisTema(temaId, acaoId, path)
     }
}

export const incluirLinkAcaoSindilegisTema = (temaId, acaoId, link) => {
     return dispatch => {
          db.doIncluirLinkAcaoSindilegisTema(temaId, acaoId, link)
     }
}

export const getTemasPorSituacao = (situacao) => {
     return dispatch => {
          dispatch({ type: POLITICO_SINDICAL_TEMAS_REQUEST })
          db.onceGetTemasPorSituacao(situacao).then((snapshot) => {
               let temas = snapshot.val()
               if (temas) {
                    Object.keys(temas).map(id => {
                         temas[id].id = id
                         return temas[id]
                    })
                    temas = _.values(temas)
               } else {
                    temas = []
               }
               dispatch({ type: POLITICO_SINDICAL_TEMAS_SET, temas })
          })
     }
}

export const getTemas = (situacao) => {
     return dispatch => {
          dispatch({ type: POLITICO_SINDICAL_TEMAS_REQUEST })
          db.onceGetTemas().then((snapshot) => {
               let temas = snapshot.val()
               if (temas) {
                    Object.keys(temas).map(id => {
                         temas[id].id = id
                         return temas[id]
                    })
                    temas = _.values(temas)
               } else {
                    temas = []
               }
               dispatch({ type: POLITICO_SINDICAL_TEMAS_SET, temas })
          })
     }
}


export const getTotalTemasSituacao = () => {
     return dispatch => {
          dispatch({ type: POLITICO_SINDICAL_TEMAS_REQUEST })
          db.onceGetTemas().then((snapshot) => {
               const temas = snapshot.val()
               const situacoesKeys = _.keys(LABELS_SITUACAO_TEMA)
               const situacoes = _.zipObject(_.keys(LABELS_SITUACAO_TEMA), situacoesKeys.map(() => 0))
               if (temas) {
                    _.each(situacoesKeys, key => {
                         situacoes[key] = _.size(_.filter(temas, ({ situacao }) => situacao === key ))
                    })
               } 
               dispatch({ type: POLITICO_SINDICAL_TOTAL_TEMAS_SITUACAO_SET, situacoes })
          })
     }
}

export const getTema = (id) => {
     return dispatch => {
          db.onceGetTema(id).then((snapshot) => {
               const tema = snapshot.val()
               if (!_.isNil(tema)) {
                    tema['itens_destaque'] = getItensAsArray(tema.itens_destaque)
                    tema['pessoas_instituicoes'] = getItensAsArray(tema.pessoas_instituicoes)
                    tema['processos'] = getItensAsArray(tema.processos)
                    tema['acoes_sindilegis'] = getItensAsArray(tema.acoes_sindilegis)
               }
               dispatch({ type: POLITICO_SINDICAL_TEMA_SET, tema })
          })
     }
}

export const getDadosIdentificacaoProcessos = () => {
     return dispatch => {
          db.onceGetProcessos().then((snapshot) => {
               const processos = getItensAsArray(snapshot.val())
               dispatch({ type: POLITICO_SINDICAL_DADOS_IDENTIFICACAO_PROCESSO_SET, processos })
          })
     }
}

export const deleteTema = (id) => {
     return dispatch => {
          db.doDeleteTema(id).then(() => {
               dispatch({ type: POLITICO_SINDICAL_DELETE_TEMA, payload: { id } })
          })
     }
}

export const setTema = tema => dispatch => dispatch({ type: POLITICO_SINDICAL_TEMA_SET, tema })

export const setTemaId = temaId => dispatch => dispatch({ type: POLITICO_SINDICAL_TEMA_ID_SET, temaId })

export const setTemaStep = pageId => dispatch => dispatch({ type: POLITICO_SINDICAL_TEMA_STEP_SET, pageId })

export const descartarTema = () => dispatch => dispatch({ type: POLITICO_SINDICAL_TEMA_DESCARTADO })

export const descartarTemas = () => dispatch => dispatch({ type: POLITICO_SINDICAL_TEMAS_DESCARTADOS })

export const descartarTotalTemasSituacao = () => dispatch => dispatch({ type: POLITICO_SINDICAL_TOTAL_TEMAS_SITUACAO_DESCARTADO })

export const descartarDadosIdentificacaoProcessos = () => dispatch => dispatch({ type: POLITICO_SINDICAL_DADOS_IDENTIFICACAO_PROCESSO_DESCARTADO })