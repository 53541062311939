export default theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    margin: 20,
    marginTop: 40
  },
  detail: {
    padding: theme.spacing(1),
  },
  button: {
    marginBottom: 10
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
      margin: 0
    }
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  grow: {
    flexGrow: 1
  },
  input: {
    display: 'none'
  },
  botoesAtendimentos: {
    backgroundColor: 'rgba( 0, 0, 0, 0.09 )',
    borderRadius: 4,
    cursor: 'pointer',
    marginTop: 4,
    marginBottom: 4,
    transition: 'all 0.3s',
    '&:hover': {
      backgroundColor: 'rgba( 0, 0, 0, 0.16 )'
    }
  },
  ajustePadding: {
    padding: 16
  }
});
