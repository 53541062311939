export default theme => ({
  root: {
    flexGrow: 1
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 1000,
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  grow: {
    flexGrow: 1
  },
  button: {
    marginTop: 10,
    marginBottom: 10
  },
  media: {
    height: 140
  },
  title: {
    marginTop: 40
  },
  cardTitle: {
    fontSize: 14,
  },
  areaOperacoes: {
    marginTop: 16,
    marginBottom: 16
  },
  containerBottomPadding: {
    paddingBottom: 80
  }
});
