import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { compose } from "recompose";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import InputText from "../commons/InputText";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Select from "../commons/Select";
import { withSnackbar } from "notistack";
import styles from "./styles";
import { getItemDespesa, criarItemDespesa, saveItemDespesa } from "../../actions/item_despesa";
import { getBeneficiarios } from "../../actions/beneficiario";
import * as config from "../Financial/config";

class ItemDespesaForm extends Component {


  constructor(props) {
    super(props);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    this.props.getBeneficiarios();
  }

  componentWillReceiveProps({ item_despesa_id }) {
    if(item_despesa_id){
    this.props.getItemDespesa(item_despesa_id);
  }
}

  save(item_despesa) {
    const { item_despesa_id } = this.props;
    if (item_despesa_id) {
      this.props.saveItemDespesa(item_despesa_id, item_despesa, () => this.successHandler())
    } else {
      this.props.criarItemDespesa(item_despesa, () => this.successHandler(true))
    }
  }

  successHandler(reload) {
    const { reset, handleClose, enqueueSnackbar } = this.props;
    enqueueSnackbar("ItemDespesa salva com sucesso!", {
      variant: "success"
    });
    reset('item_despesa')
    handleClose(reload);
  }

  errorHandler() {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar("Erro ao salvar item_despesa.", {
      variant: "error"
    })
  }

  render() {
    const { handleSubmit, pristine, submitting, reset, beneficiarios } = this.props;
    const {
      centros_custo,
      tipos_despesa,
    } = config;

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose.bind(this, null)}
        fullWidth
        maxWidth="md"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Item de despesa</DialogTitle>
        <form onSubmit={handleSubmit(this.save)}>
          <DialogContent>
            <Grid
              container
              spacing={2}
              hidden={false}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item sm={12}>
                <Field
                  name="nome"
                  component={InputText}
                  label="Nome"
                />
              </Grid>
              <Grid item sm={12}>
                <Typography>Configurações</Typography>
              </Grid>
              <Grid item sm={3}>
                <Field
                  name="tipo"
                  component={Select}
                  label="Tipo"
                >
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  {tipos_despesa.map(m => (
                    <MenuItem key={m} value={m}>
                      {m}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item sm={3}>
                <Field
                  name="centro_custo"
                  component={Select}
                  label="Centro de custo"
                >
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  {centros_custo.map(c => (
                    <MenuItem key={c} value={c}>
                      {c}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item sm={3}>
                <Field
                  name="descricao"
                  component={InputText}
                  label="Descrição"
                />
              </Grid>
              <Grid item sm={3}>
                <Field
                  name="id_beneficiario"
                  component={Select}
                  label="Beneficiário"
                >
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  {beneficiarios.filter(b => !b.desabilitado).map(b => (
                    <MenuItem key={b.id} value={b.id}>
                      {b.nome_beneficiario}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => reset() && this.props.handleClose()}
              color="default"
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={pristine || submitting}
              color="primary"
            >
              Salvar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

ItemDespesaForm.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ item_despesa: { item_despesa, item_despesa_id }, beneficiario: { beneficiarios } }) => ({
  item_despesa: item_despesa,
  item_despesa_id: item_despesa_id,
  beneficiarios,
  initialValues: item_despesa
});

const validate = values => {
  const errors = {};
  const requiredFields = [
    "tipo",
    "nome"
  ];
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = " Obrigatório";
    }
  });
  return errors;
};

export default compose(
  connect(
    mapStateToProps,
    { getItemDespesa, criarItemDespesa, saveItemDespesa, getBeneficiarios }
  ),
  withStyles(styles)
)(
  reduxForm({
    form: "item_despesa",
    validate,
    enableReinitialize: true,
  })(withSnackbar(ItemDespesaForm))
);
