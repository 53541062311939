import { ITENS_RECEITA_SET, ITEM_RECEITA_SET, ITEM_RECEITA_ID_SET, ITENS_RECEITA_REQUEST, ITEM_RECEITA_UPDATED, DELETE_ITEM_RECEITA } from "../actions/types";

const INITIAL_STATE = {
  itens_receita: [],
  item_receita: null,
  item_receita_id: null,
  historico: [],
  loading: false
};

function item_receitaReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ITENS_RECEITA_SET: {
      return { ...state, itens_receita: action.itens_receita, loading: false }
    }
    case ITENS_RECEITA_REQUEST: {
      return { ...state, loading: true }
    }
    case ITEM_RECEITA_SET: {
      return { ...state, item_receita: action.item_receita, loading: false }
    }
    case ITEM_RECEITA_ID_SET: {
      return { ...state, item_receita_id: action.item_receita_id }
    }
    case ITEM_RECEITA_UPDATED: {
      const { itens_receita } = state
      const { id, data } = action.payload
      const index = itens_receita.findIndex(f => f.id === id)
      const item_receita = { ...itens_receita[index], ...data }
      itens_receita.splice(index, 1, item_receita)
      return { ...state, itens_receita: [...itens_receita] }
    }
    case DELETE_ITEM_RECEITA: {
      const { itens_receita } = state
      const index = itens_receita.findIndex(f => f.id === action.payload.id)
      itens_receita.splice(index, 1)
      return { ...state, itens_receita: [...itens_receita] }
    }
    default:
      return state;
  }
}

export default item_receitaReducer;
