import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import AddIcon from "@material-ui/icons/Add";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ReplayIcon from "@material-ui/icons/Replay";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import Container from "@material-ui/core/Container";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import {
  setTema,
  saveTema,
  setTemaId,
  deleteTema,
  setTemaStep,
  descartarTemas,
  toggleSituacaoTema,
  descartarDadosIdentificacaoProcessos,
  getTemas,
} from "../../actions/politico_sindical";
import * as roles from "../../constants/roles";
import tableI18n from "../TableLocalization";
import TemaWizardFormDialog from "./TemaWizardFormDialog";
import ConfirmDialog from "../commons/ConfirmDialog";
import SindilegisBar from "../commons/SindilegisBar";
import styles from "./styles";
import Loading from "../commons/Loading";
import {
  steps,
  LAST_STEP,
  getDocumentosBy,
  isSituacaoTemaEncerrado,
  deleteDocumentosTemaByNestedField,
} from "../../controllers/temaController";
import { storage } from "../../firebase";
import * as routes from '../../constants/routes'
import MenuButton from '../commons/MenuButton'
import { getRoutes } from '../commons/util'

class TemaList extends React.Component {
  state = {
    open: false,
    confirmOpen: false,
    confirmAction: Function,
    confirmMessage: "",
  };

  componentDidMount() {
    this.props.getTemas();
  }

  componentWillUnmount() {
    this.props.descartarTemas();
    this.props.descartarDadosIdentificacaoProcessos();
  }

  handleTemaClickOpen = () => {
    this.setState({ open: true });
  };

  handleTemaClose = (reload) => {
    this.setState({ open: false });
    this.props.setTema(null);
    this.props.setTemaId(null);
    if (reload) {
      this.reloadTemas();
    }
  };

  handleReload = () => this.reloadTemas();

  handleConfirmOpen = (confirmAction, confirmMessage) => {
    this.setState({ confirmOpen: true, confirmAction, confirmMessage });
  };

  handleConfirmClose = (confirm) => {
    if (confirm) {
      this.state.confirmAction();
      this.setState({ confirmOpen: false });
    } else {
      this.setState({ confirmOpen: false });
    }
  };

  handleDeleteTema(row) {
    this.props.deleteTema(row.id);
    const temaDocsUrl = _.map(_.values(getDocumentosBy(row)), ({ url }) => url);
    _.forEach(temaDocsUrl, (url) => storage.removerAnexo(url));
    deleteDocumentosTemaByNestedField(row, "itens_destaque");
    deleteDocumentosTemaByNestedField(row, "acoes_sindilegis");
  }

  handleToggleSituacaoTema(uid, situacao) {
    this.props.toggleSituacaoTema(uid, situacao);
    this.props.getTemas()
  }

  reloadTemas() {
    this.props.getTemas()
  }


  useQuery() {
    const { location } = this.props;
    return new URLSearchParams(location.search);
  }

  render() {
    const { classes, temas, authUser, loading, location } = this.props;
    const canEdit = _.includes(authUser.roles, roles.POLITICO_SINDICAL);
    const canDelete = _.includes(authUser.roles, roles.POLITICO_SINDICAL);
    const currentPath = location.pathname
    const buttonRoutes = getRoutes(routes, currentPath)

    const query = this.useQuery();
    let situacao = query.get("situacao");
    const tipoTema = query.get("tipo_tema");

    let temasFiltrados = temas.filter((tema) =>
      situacao == null ||
      this.filterByQueryParam(tema, situacao, tipoTema))

    const titulo = situacao === 'EM_ACOMPANHAMENTO' ? "Em acompanhamento" : "Encerrado"

    return (
      <React.Fragment>
        <SindilegisBar />
        <Loading loading={loading} />
        {!loading && (
          <Container maxWidth="xl">
            <Grid item xs={12} md={12}>
              <Grid item style={{ paddingBottom: 10 }}>
                <MenuButton buttonsRoutes={buttonRoutes} location={location} />

              </Grid>
              <div className={classes.demo}>
                <MaterialTable
                  columns={[
                    {
                      title: "Título do tema",
                      field: "titulo",
                      render: (row) => (
                        <Link to={`/sindical/tema/${row.id}`}>
                          {row.titulo}
                        </Link>
                      ),
                    },
                    { title: "Tipo do tema", field: "tipo_tema" },
                    {
                      title: "Servidores interessados",
                      field: "interessados",
                      render: (row) => _.join(row.interessados, ", "),
                    },
                    { title: "Prioridade", field: "prioridade" },
                    {
                      title: "Itens",
                      field: "itens_destaque",
                      render: (row) => (
                        <Link to={`/sindical/tema/${row.id}/1`}>
                          {_.size(row.itens_destaque)}{" "}
                        </Link>
                      ),
                    },
                    {
                      title: "Envolvidos",
                      field: "pessoas_instituicoes",
                      render: (row) => (
                        <Link to={`/sindical/tema/${row.id}/2`}>
                          {_.size(row.pessoas_instituicoes)}{" "}
                        </Link>
                      ),
                    },
                    {
                      title: "Processos",
                      field: "processos",
                      render: (row) => (
                        <Link to={`/sindical/tema/${row.id}/3`}>
                          {_.size(row.processos)}{" "}
                        </Link>
                      ),
                    },
                    {
                      title: "Ações Sindilegis",
                      field: "acoes_sindilegis",
                      render: (row) => (
                        <Link to={`/sindical/tema/${row.id}/4`}>
                          {_.size(row.acoes_sindilegis)}{" "}
                        </Link>
                      ),
                    },
                    { title: "Custos", field: "custos", render: (row) => `--` },
                  ]}
                  data={temasFiltrados.slice().sort((a, b) => {
                    const dateA = a.lastUpdated ? new Date(a.lastUpdated).getTime() : 0;
                    const dateB = b.lastUpdated ? new Date(b.lastUpdated).getTime() : 0;
                    return dateB - dateA;
                  })}
                  title={`Temas da pauta político-sindical do Sindilegis na situaçao: ${titulo}`}
                  actions={[
                    (rowData) => ({
                      icon: () =>
                        isSituacaoTemaEncerrado(rowData.situacao) ? (
                          <ReplayIcon />
                        ) : (
                          <HighlightOffIcon />
                        ),
                      tooltip: isSituacaoTemaEncerrado(rowData.situacao)
                        ? "Cancelar encerramento"
                        : "Encerrar acompanhamento",
                      onClick: (event, row) =>
                        this.handleConfirmOpen(
                          () =>
                            this.handleToggleSituacaoTema(row.id, row.situacao),
                          "Confirma a execução desta operação?"
                        ),
                    }),
                    {
                      icon: canEdit ? "edit" : "info",
                      tooltip: canEdit ? "Editar" : "Detalhe",
                      onClick: (event, row) =>
                        this.props.setTemaId(row.id) &&
                        this.props.setTemaStep(
                          row.page < LAST_STEP ? row.page + 1 : row.page
                        ) &&
                        this.handleTemaClickOpen(),
                    },
                    {
                      icon: () => <AssignmentTurnedInIcon />,
                      tooltip: canEdit ? "Nova ação" : "Detalhar ação",
                      onClick: (event, row) =>
                        this.props.setTemaId(row.id) &&
                        this.props.setTemaStep(steps.ACOES) &&
                        this.handleTemaClickOpen(),
                    },
                    {
                      icon: "delete",
                      tooltip: "Excluir",
                      onClick: (event, row) =>
                        this.handleConfirmOpen(
                          () => this.handleDeleteTema(row),
                          "Confirma a exclusão permanente do tema?"
                        ),
                    },
                  ]}
                  options={{
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50, 100],
                  }}
                  localization={tableI18n}
                />
                <Fab
                  color="primary"
                  aria-label="Cadastrar"
                  className={classes.fab}
                  onClick={() =>
                    this.props.setTemaId(null) &&
                    this.props.setTemaStep(steps.TEMA) &&
                    this.props.setTema(null) &&
                    this.handleTemaClickOpen()
                  }
                >
                  <AddIcon />
                </Fab>
              </div>
            </Grid>
            <TemaWizardFormDialog
              open={this.state.open}
              handleClose={this.handleTemaClose}
              handleReload={this.handleReload}
              canEdit={canEdit}
              canDelete={canDelete}
            />
            <ConfirmDialog
              open={this.state.confirmOpen}
              message={this.state.confirmMessage}
              onClose={this.handleConfirmClose}
            />
          </Container>
        )}
      </React.Fragment>
    );
  }

  filterByQueryParam(tema, situacao, tipoTema) {
    const filterApplied = situacao || tipoTema;

    if (!filterApplied) {
      return true;
    }

    const situacaoMatch = situacao ? tema.situacao === situacao : true;
    const tipoTemaMatch = tipoTema ? tema.tipo_tema === tipoTema : true;

    if ((situacao === "EM_ACOMPANHAMENTO" || situacao === "ENCERRADO") && tipoTema === "Administrativo") {
      return situacaoMatch && tipoTemaMatch;
    } else {
      return situacaoMatch && tipoTemaMatch && tema.tipo_tema !== "Administrativo";
    }
  }




}

const mapStateToProps = ({ politico_sindical: { temas, loading } }) => ({
  temas,
  loading,
});

TemaList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    setTema,
    saveTema,
    setTemaId,
    deleteTema,
    setTemaStep,
    descartarTemas,
    toggleSituacaoTema,
    descartarDadosIdentificacaoProcessos,
    getTemas,
  })
)(withRouter(TemaList));
