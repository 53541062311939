import { storage } from './firebase';

export const uploadAnexoDespesa = (idDespesa, file) => storage.ref(`despesas/${idDespesa}`).put(file)
export const uploadAnexoReceita = (idReceita, file) => storage.ref(`receitas/${idReceita}`).put(file)
export const uploadAnexoDependente = (idDependente, file) => storage.ref(`dependentes/${idDependente}`).put(file)
export const uploadAnexoDocumento = (idDocumento, file) => storage.ref(`documentos/${idDocumento}`).put(file)
export const uploadAnexoDocumentoTransparencia = (idDocumetoTransparencia, file) => storage.ref(`documentos_transparencia/${idDocumetoTransparencia}`).put(file)
export const uploadAnexoAtendimento = (idAtendimento, file) => storage.ref(`atendimentos/${idAtendimento}`).put(file)
export const uploadAnexoProcessos = (idProcesso, file) => storage.ref(`processos/${idProcesso}`).put(file)
export const uploadDocumentoTema = (temaId, file) => storage.ref(`temas/documentos/${temaId}`).put(file)
export const uploadDocumentoItemDestaqueTema = (temaId, itemDestaqueId, file) => storage.ref(`temas/documentos/${temaId}/itensDestaque/${itemDestaqueId}`).put(file)
export const uploadDocumentoAcaoSindilegisTema = (temaId, acaoId, file) => storage.ref(`temas/documentos/${temaId}/acoes_sindilegis/${acaoId}`).put(file)
export const uploadAnexoEvento = (idEvento, file) => storage.ref(`juridico/processos/eventos/${idEvento}`).put(file)
export const uploadPhotoAutoridadePolitica = (idPolitico, photo) => storage.ref(`autoridades_politicas/${idPolitico}`).put(photo);
export const uploadAnexoAutoridadePolitica = (idPolitico, file) => storage.ref(`autoridades_politicas/${idPolitico}`).put(file)
export const uploadAnexoFiliado = (idFiliado, file) => storage.ref(`filiados/${idFiliado}/${file.name}`).put(file)
export const uploadAnexoConsignacao = (file, selectedYear, selectedMonth, baseFileName) => {
    return storage.ref(`consignacao/${selectedYear}/${selectedMonth}/${baseFileName}`).put(file);
};

export const removerAnexo = (ref) => storage.ref(ref).delete()

export const downloadAnexo = (path) => {
    storage.ref(path).getDownloadURL().then(function (url) {
        var link = document.createElement("a");
        if (link.download !== undefined) {
            link.setAttribute("href", url);
            link.setAttribute("target", "_blank");
            link.setAttribute("download", true);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    })
}

export const fetchImageUrlStorage = (photoPath) => {
    return storage.ref(photoPath).getDownloadURL();
}