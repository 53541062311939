export default theme => ({
  appBar: {
    flexGrow: 1,

  },
  conteudo: {
    minHeight: "70vh",
    marginTop: "3em",
  },
  profilesVectors: {
    height: "auto",
    width: "100%"
  },
  homeButtonGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gap: "16px",
  },
  homeButton: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    border: '1px solid #ddd',
    borderRadius: '4px',
    backgroundColor: '#fff',
    padding: "8px",
    cursor: 'pointer',
    transitions: "transform .15s ease-in-out",
    '& > img': {
      height: "64px",
      width: "64px",
    },
    '&:hover': {
      transition: "transform .15s ease-in-out",
      transform: "translate(0, -4px)"
    }
  },
});
