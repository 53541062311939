import React from "react";
import TextField from "@material-ui/core/TextField";

export default props => {
  const {
    input,
    label,
    meta: { touched, error },
    ...custom
  } = props;
  return (
    <TextField
      label={label}
      InputLabelProps={{ shrink: true }}
      error={touched && !!error}
      {...input}
      {...custom}
      margin="dense"
      helperText={error}
      fullWidth
    />
  );
};
