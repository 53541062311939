import { BENEFICIARIOS_SET, BENEFICIARIO_SET, BENEFICIARIO_ID_SET, BENEFICIARIOS_REQUEST, BENEFICIARIO_UPDATED, DELETE_BENEFICIARIO } from "../actions/types";

const INITIAL_STATE = {
  beneficiarios: [],
  beneficiario: null,
  beneficiarioId: null,
  historico: [],
  loading: false
};

function beneficiarioReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case BENEFICIARIOS_SET: {
      return { ...state, beneficiarios: action.beneficiarios, loading: false }
    }
    case BENEFICIARIOS_REQUEST: {
      return { ...state, loading: true }
    }
    case BENEFICIARIO_SET: {
      return { ...state, beneficiario: action.beneficiario, loading: false }
    }
    case BENEFICIARIO_ID_SET: {
      return { ...state, beneficiarioId: action.beneficiarioId }
    }
    case BENEFICIARIO_UPDATED: {
      const { beneficiarios } = state
      const { id, data } = action.payload
      const index = beneficiarios.findIndex(f => f.id === id)
      const beneficiario = { ...beneficiarios[index], ...data }
      beneficiarios.splice(index, 1, beneficiario)
      return { ...state, beneficiarios: [...beneficiarios] }
    }
    case DELETE_BENEFICIARIO: {
      const { beneficiarios } = state
      const index = beneficiarios.findIndex(f => f.id === action.payload.id)
      beneficiarios.splice(index, 1)
      return { ...state, beneficiarios: [...beneficiarios] }
    }
    default:
      return state;
  }
}

export default beneficiarioReducer;
