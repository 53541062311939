import _ from "lodash";
import { ITENS_DESPESA_SET, ITEM_DESPESA_SET, ITEM_DESPESA_ID_SET, ITENS_DESPESA_REQUEST, ITEM_DESPESA_UPDATED, DELETE_ITEM_DESPESA } from './types';
import { db } from "../firebase";

export const saveItemDespesa = (id, data, callback) => {
     return dispatch => {
          db.doSaveItemDespesa(id, data).then(() => {
               dispatch({ type: ITEM_DESPESA_UPDATED, payload: { id, data } });
               callback && callback();
          });
     };
}

export const criarItemDespesa = (data, callback) => {
     return dispatch => {
          db.doCreateItemDespesa(data).then(() => {
               callback && callback();
          });
     };
}

export const getItensDespesa = () => {
     return dispatch => {
          dispatch({ type: ITENS_DESPESA_REQUEST });
          db.onceGetItensDespesa().then((snapshot) => {
               let itens_despesa = snapshot.val()
               if (itens_despesa) {
                    Object.keys(itens_despesa).map(id => {
                         itens_despesa[id].id = id
                         return itens_despesa[id]
                    });
                    itens_despesa = _.orderBy(_.values(itens_despesa), [b => b.nome.toLowerCase()], ['asc']);
               } else {
                    itens_despesa = []
               }

               dispatch({ type: ITENS_DESPESA_SET, itens_despesa });
          })
     }
}

export const getItemDespesa = (id) => {
     return dispatch => {
          db.onceGetItemDespesa(id).then((snapshot) => {
               const item_despesa = snapshot.val()
               dispatch({ type: ITEM_DESPESA_SET, item_despesa })
          });
     }
}

export const deleteItemDespesa = (id) => {
     return dispatch => {
          db.doDeleteItemDespesa(id).then(() => {
               dispatch({ type: DELETE_ITEM_DESPESA, payload: { id } });
          })
     }
}

export const setItemDespesa = (item_despesa) => dispatch => dispatch({ type: ITEM_DESPESA_SET, item_despesa })

export const setItemDespesaId = (item_despesa_id) => dispatch => dispatch({ type: ITEM_DESPESA_ID_SET, item_despesa_id })