import Dialog from '@material-ui/core/Dialog'
import { withStyles } from '@material-ui/core/styles'
import { withSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import React from 'react'
import _ from 'lodash'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { createTema, getTema, saveTema, setTema } from '../../actions/politico_sindical'
import { tiposLink, isValidHttpUrl } from '../../controllers/temaController'
import styles from './styles'
import InputText from '../commons/InputText'
import Select from '../commons/Select'

const urlSample = `https://domain.com`

const validate = values => {
  const errors = {}
  const requiredFields = ['nome','tipo','url']
  requiredFields.forEach(field => {
    if (_.isNil(values[field]) || _.isEmpty(values[field])) {
        errors[field] = ' Obrigatório'
    }
  })
  if (values.url) {
    if (!isValidHttpUrl(values.url)) {
      errors.url = `URL inválida. Ex: ${urlSample}`
    }
  }
  return errors
}

class TemaLinkFormDialog extends React.Component {
  render () {
    const { open, currentLink, rootItem, invalid, pristine, submitting, handleSave, handleClose, reset} = this.props
    return (
      <form>
        <Dialog
        open={open}
        onClose={handleClose.bind(this, null)}
        fullWidth
        maxWidth='sm'
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>Inclusão de novo link</DialogTitle>
          <DialogContent style={{ minHeight: '15vh' }}>
            <Grid
              container
              spacing={2}
            >
              <Grid item sm={7}>
                <Field name='nome' component={InputText} label='Nome do Link' />
              </Grid>
              <Grid item sm={5}>
                <Field name='tipo' component={Select} label='Tipo de Link'>
                  <MenuItem value=''>
                    <em>Selecione</em>
                  </MenuItem>
                  { _.map(_.keys(tiposLink), key => (
                      <MenuItem key={key} value={tiposLink[key]}>{tiposLink[key]}</MenuItem>
                    ))
                  }
                </Field>
              </Grid>
              <Grid item sm={12}>
                <Field name='url' component={InputText} label='URL' placeholder={urlSample} />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleClose(null)}
              color='default'
            >
              Cancelar
            </Button>
            <Button
              type='button'
              color='primary'
              disabled={pristine || invalid || submitting}
              onClick={() => _.isNil(rootItem) 
                ? handleSave(currentLink, () => reset('link')) 
                : handleSave(currentLink, rootItem, () => reset('link')) 
              }
            >
              Incluir
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    )
  }
  
}

const selector = formValueSelector('link')

const mapStateToProps = state => {
  const { politico_sindical: { tema } } = state
  return {
    tema: tema,
    initialValues: { url: '', nome: '', tipo: '' },
    currentLink: { url: selector(state, 'url'), nome: selector(state, 'nome'), tipo: selector(state, 'tipo') }
  }
}

TemaLinkFormDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    { getTema, createTema, saveTema, setTema }
  )
)(
  reduxForm({
    form: 'link',
    validate,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
  })(withSnackbar(TemaLinkFormDialog))
)
