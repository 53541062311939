import React from "react";
import {compose} from "recompose";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import {withStyles} from "@material-ui/core/styles";
import {withSnackbar} from "notistack";
import styles from "./styles";
import { comunicarPorEmail, comunicarPorWhatsapp, setFiliadosAComunicar} from "../../actions/comunicacao";
import 'draft-js/dist/Draft.css';
import {Editor} from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Typography from "@material-ui/core/Typography";
import {convertToRaw, EditorState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import {Field, reduxForm} from "redux-form";
import InputText from "../commons/InputText";
import Select from "../commons/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {remetentes, whatsapp_templates} from "./config";

class ComunicacaoDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mensagem: EditorState.createEmpty(),
            enviando: false
        }
    }


    onChange = (mensagem) => {
        this.setState({mensagem});
    };

    enviar(values) {
        this.setState({...this.state, enviando: true})

        const {titulo, remetente, template, tipoComunicacao} = values;
        const {comunicarPorEmail, comunicarPorWhatsapp, filiadosAComunicar} = this.props;
        if(tipoComunicacao === "email"){
            const mensagemHtml = draftToHtml(convertToRaw(this.state.mensagem.getCurrentContent()));
            comunicarPorEmail(remetente, filiadosAComunicar, titulo, mensagemHtml, this.successHandler.bind(this), this.failureHandler.bind(this));
        }else if(tipoComunicacao === "whatsapp") {
            comunicarPorWhatsapp(filiadosAComunicar, template, this.successHandler.bind(this), this.failureHandler.bind(this))
        }

      }

    successHandler() {
        const {handleClose, enqueueSnackbar} = this.props;
        enqueueSnackbar("Comunicação enviada com sucesso!", {
            variant: "success"
        });
        this.props.reset();
        this.setState({...this.state, mensagem: EditorState.createEmpty(), enviando: false});
        handleClose(false);
    }

    failureHandler() {
        const {enqueueSnackbar} = this.props;
        this.setState({...this.state, enviando: false});

        enqueueSnackbar("Falha ao enviar comunicação, tente novamente", {
            variant: "error"
        });
    }

    cancelar() {
        this.props.setFiliadosAComunicar([]);
        this.props.reset();
        this.setState({mensagem: EditorState.createEmpty()})
        this.props.handleClose();
    }


    errorHandler() {
        const {enqueueSnackbar} = this.props;
        enqueueSnackbar("Erro ao salvar interação.", {
            variant: "error"
        })
    }

    render() {
        const { handleSubmit, pristine, invalid, submitting, filiadosAComunicar} = this.props;

        const mensagemHtml = draftToHtml(convertToRaw(this.state.mensagem.getCurrentContent()));

        const tipoComunicacao = this.props.comunicacao && this.props.comunicacao.values && this.props.comunicacao.values.tipoComunicacao;

        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose.bind(this, null)}
                fullWidth
                maxWidth="md"
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Comunicar</DialogTitle>
                <form onSubmit={handleSubmit(this.enviar.bind(this))}>
                    <DialogContent>
                        <Grid container
                              spacing={2}
                              hidden={false}
                              alignItems="center"
                              justifyContent="center">
                            <Grid item sm={12}>
                                <Typography> Filiado(s): {filiadosAComunicar.map(f => f.nome_completo).join(', ')}</Typography>
                                <hr/>
                            </Grid>
                            <Grid item sm={12}>
                                <Field name="tipoComunicacao" component={Select} label="Tipo de comunicação">
                                    <MenuItem value="email">
                                        <em>E-mail</em>
                                    </MenuItem>
                                    {/*<MenuItem value="whatsapp">
                                        <em>Whatsapp</em>
                                    </MenuItem>*/}
                                </Field>
                            </Grid>
                            {tipoComunicacao === 'email' && this.camposEmail()}
                            {tipoComunicacao === 'whatsapp' && this.camposWhatsapp()}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.cancelar.bind(this)}
                            color="default"
                        >
                            Cancelar
                        </Button>
                        <Button
                            variant="contained"
                            disabled={(tipoComunicacao === 'email' && mensagemHtml.trim() === '<p></p>') || pristine || submitting || invalid || this.state.enviando}
                            color="primary"
                            type="submit">
                            Enviar
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }

    camposWhatsapp() {
        return <React.Fragment>
            <Grid item sm={12}>
                <Field name="template" component={Select} label="Template da mensagem">
                    {whatsapp_templates.map(template => {
                       return <MenuItem value={template}>
                            <em>{template.nome}</em>
                        </MenuItem>
                    })}
                </Field>
            </Grid>
            </React.Fragment>;
    }

    camposEmail() {
        return <React.Fragment>
            <Grid item sm={12}>
                <Field name="remetente" component={Select} label="Remetente">
                    {remetentes.map(remetente => {
                        return <MenuItem value={remetente}>
                            <em>{remetente}</em>
                        </MenuItem>
                    })}
                </Field>
            </Grid>
            <Grid item sm={12}>
                <Field
                    name="titulo"
                    component={InputText}
                    multiline={false}
                    label="Título"
                />
            </Grid>
            <Grid item sm={12}>
                <div className='editor'>
                    <Editor editorState={this.state.mensagem}
                            wrapperClassName="rich-editor demo-wrapper custom-rich-text"
                            editorClassName="demo-editor"
                            onEditorStateChange={this.onChange} toolbar={{
                        options: ['image', 'inline', 'fontSize', 'list', 'textAlign', 'history'],
                    }}/>
                </div>
            </Grid></React.Fragment>;
    }
}

const validate = values => {
    const errors = {};
    const requiredFields = [
        "titulo",
        "remetente",
        "template"
    ];
    requiredFields.forEach(field => {
        if (!values[field] || (Array.isArray(values[field]) && values[field].length === 0)) {
            errors[field] = " Obrigatório";
        }
    });

    return errors;
};

ComunicacaoDialog.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = ({   form: {comunicacao},
                             comunicacao: {filiadosAComunicar}
                         }) => ({
    filiadosAComunicar,
    comunicacao
});

export default compose(
    connect(
        mapStateToProps,
        {comunicarPorEmail, comunicarPorWhatsapp, setFiliadosAComunicar}
    ),
    withStyles(styles)
)(
    reduxForm({
        form: "comunicacao",
        validate,
        enableReinitialize: true
    })(withSnackbar(ComunicacaoDialog)));