import React from "react";
import { Field, reduxForm } from "redux-form";
import { compose } from "recompose";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import InputText from "../../commons/InputText";
import { withSnackbar } from "notistack";
import { storage } from "../../../firebase";
import styles from "./styles";
import AnexoDocumentoTransparencia from "./anexo_documento_transparencia";
import { getDocumentoTransparencia, criarDocumentoTransparencia, saveDocumentoTransparencia, setDocumentoTransparenciaId } from "../../../actions/documento_transparencia";

class DocumentoTransparenciaForm extends React.Component {
  constructor(props) {
    super(props);
    this.save = this.save.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.documentoTransparenciaId !== this.props.documentoTransparenciaId) {
      const { documentoTransparenciaId } = this.props;
      if (documentoTransparenciaId) {
        this.props.getDocumentoTransparencia(documentoTransparenciaId)
      }
    }
  }

  save(documentoTransparencia) {
    const { documentoTransparenciaId } = this.props;
    if (documentoTransparenciaId) {
      this.props.saveDocumentoTransparencia(documentoTransparenciaId, documentoTransparencia, () => this.successHandler())
    } else {
      this.props.criarDocumentoTransparencia(documentoTransparencia, (key) => {
        this.props.setDocumentoTransparenciaId(key)
        this.props.getDocumentoTransparencia(key);
        this.successHandler();
      })
    }
  }

  deleteAnexo(anexo) {
    const { documentoTransparenciaId } = this.props;
    this.props.saveDocumentoTransparencia(documentoTransparenciaId, { anexo: null })
    storage.removerAnexo(anexo.url)
    this.successDeleteAnexo()
  }

  successHandler() {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar("Documento salvo com sucesso!", {
      variant: "success"
    });
  }

  successDeleteAnexo() {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar("Anexo excluído com sucesso!", {
      variant: "success"
    });
  }

  errorHandler() {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar("Erro ao salvar documento.", {
      variant: "error"
    })
  }

  render() {
    const { handleSubmit, pristine, submitting, reset, documentoTransparenciaId, documentoTransparencia, email } = this.props;

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose.bind(this, null)}
        fullWidth
        maxWidth="sm"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{documentoTransparenciaId ? "Atualizar Documento" : " Inserir Documento"}</DialogTitle>
        <form onSubmit={handleSubmit(this.save)}>
          <DialogContent>
            <Grid
              container
              spacing={2}
              hidden={false}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item sm={12}>
                <Field
                  name="nome"
                  component={InputText}
                  label="Nome"
                />
              </Grid>
              {documentoTransparencia &&
                <Grid item sm={12}>
                  <AnexoDocumentoTransparencia email={email} id={documentoTransparenciaId} anexo={documentoTransparencia.anexo} handleDelete={(anexo) => this.deleteAnexo(anexo)} />
                  <Field name="anexoFlag" component="input" type="hidden" />
                </Grid>
              }
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                reset() && this.props.handleClose(true)

              }
              color="default"
            >
              Fechar
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={pristine || submitting}
              color="primary"
            >
              Salvar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

DocumentoTransparenciaForm.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ documento_transparencia: { documentoTransparencia, documentoTransparenciaId } }) => ({
  documentoTransparencia: documentoTransparencia,
  documentoTransparenciaId: documentoTransparenciaId,
  initialValues: documentoTransparencia
});

const validate = values => {
  const errors = {};
  const requiredFields = ["nome", "url"];
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = " Obrigatório";
    }
  });
  return errors;
};

export default compose(
  connect(
    mapStateToProps,
    { getDocumentoTransparencia, criarDocumentoTransparencia, saveDocumentoTransparencia, setDocumentoTransparenciaId }
  ),
  withStyles(styles)
)(
  reduxForm({
    form: "documento",
    validate,
    enableReinitialize: true,
  })(withSnackbar(DocumentoTransparenciaForm))
);
