export default (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    margin: 20,
    marginTop: 80,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  grow: {
    flexGrow: 1,
  },
  areaOperacoes: {
    marginTop: 16,
    marginBottom: 16,
  },
  containerBottomPadding: {
    paddingBottom: 80,
  },
  addPerfil: {
    marginTop: 22,
  },
});
