import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Radio from '@material-ui/core/Radio'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import { withSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { Field, reduxForm } from 'redux-form'
import { createTema, getTema, saveTema, setTema, setTemaId } from '../../actions/politico_sindical'
import { initialValuesTema, interessados, prioridades, classificacaoTema, steps, tags, tipos } from '../../controllers/temaController'
import AsyncAutoCompleteSelect from '../commons/AsyncAutoComplete'
import CheckboxGroup from '../commons/CheckboxGroup'
import InputText from '../commons/InputText'
import RadioButton from '../commons/RadioButton'
import Select from '../commons/Select'
import styles from './styles'
import validate from './validate'

class TemaStepForm extends React.Component {

  componentDidMount () {
    const { temaId } = this.props
    if (temaId) {
      this.props.getTema(temaId)
    }
  }

  save(values) {
    const { temaId, handleSubmit, pristine } = this.props
    handleSubmit && handleSubmit()
    if (!pristine) {
      if (temaId) {
        this.props.saveTema(temaId, values, () => this.successHandler(true))
      } else {
        this.props.createTema(values, () => this.successHandler(true))
      }
    }
  }

  successHandler(reload) {
    const { reset, handleReload, enqueueSnackbar } = this.props
    enqueueSnackbar('Tema salvo com sucesso!', {
      variant: 'success'
    })
    reset('tema')
    handleReload()
  }

  render() {
    const {
      classes,
      handleSubmit,
      reset,
      submitting,
      canEdit
    } = this.props

    return (
      <form className={classes.container} onSubmit={handleSubmit(this.save.bind(this))}>
        <DialogTitle id='form-dialog-title'>Inclusão de novo tema</DialogTitle>
        <DialogContent style={{ minHeight: '50vh' }}>
          <Grid
            container
            spacing={2}
          >
            <Grid item sm={5}>
              <Field name='tipo_tema' component={Select} label='Tipo do Tema'>
                <MenuItem value=''>
                  <em>Selecione</em>
                </MenuItem>
                { _.map(_.keys(tipos), key => (
                    <MenuItem key={key} value={tipos[key]}>{tipos[key]}</MenuItem>
                  ))
                }
              </Field>
            </Grid>
            <Grid item sm={7}>
              <Field name='titulo' component={InputText} label='Título do Tema' />
            </Grid>

            <Grid item sm={12}>
              <CheckboxGroup name='interessados' title='Público interessado' options={interessados} />
            </Grid>

            <Grid item sm={12}>
              <Field
                name='descricao'
                component={InputText}
                label='Descrição do tema'
                multiline={true}
                rows={3}
              />
            </Grid>

            <Grid item sm={12}>
              <Field
                  defaultOptions={tags.map(f => ({label: f.nome, value: f.id}))}
                  isMulti={true}
                  loadOptions={input => {
                      return Promise.resolve(
                          tags.filter(f =>
                              f.nome &&
                              f.nome.toLowerCase()
                                .includes(input.toLowerCase()))
                              .map(f => ({label: f.nome, value: f.id}))
                              .slice(0, 100))
                  }}
                  name='tags'
                  component={AsyncAutoCompleteSelect}
                  label='Classificação'
              />
            </Grid>

            <Grid item sm={6}>
              <Field
                name='prioridade'
                component={RadioButton}
                label='Prioridade'
              >
                { _.map(prioridades, (prioridade, key) => (
                    <FormControlLabel key={key} value={prioridade.value} control={<Radio />} label={prioridade.label} />
                  ))
                }
              </Field>
            </Grid>
            <Grid item sm={6}>
              <Field
                name='classificacaoTema'
                component={RadioButton}
                label='Classificação do Tema'
              >
                { _.map(classificacaoTema, (classificacaoTema, key) => (
                    <FormControlLabel key={key} value={classificacaoTema.value} control={<Radio />} label={classificacaoTema.label} />
                  ))
                }
              </Field>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => reset() && this.props.handleClose(null)}
            color='default'
          >
            Cancelar
          </Button>
          <Button
            type='submit'
            disabled={!canEdit || submitting}
            color='primary'
          >
            Próximo passo
          </Button>
        </DialogActions>
      </form>
    )
  }
}

const mapStateToProps = ({ politico_sindical: { tema, temaId } }) => ({
  tema: tema,
  temaId: temaId,
  initialValues: {
    ...initialValuesTema,
    ...tema,
    page: steps.TEMA
  }
})

TemaStepForm.propTypes = {
  classes: PropTypes.object.isRequired
}

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    { getTema, createTema, saveTema, setTema, setTemaId }
  )
)(
  reduxForm({
    form: 'tema',
    validate,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
  })(withSnackbar(TemaStepForm))
)
