import React from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import {
  getEscritoriosAdvocacia,
  setEscritorioAdvocaciaId,
  updateAtendimentosByEscritorio,
  setEscritorioAdvocacia,
  saveEscritorioAdvocacia,
  deleteEscritorioAdvocacia,
  updateProcessosByEscritorio
} from "../../../actions/juridico";
import tableI18n from "../../TableLocalization";
import EscritorioForm from "./escritorio";
import ConfirmDialog from "../../commons/ConfirmDialog";
import SindilegisBar from "../../commons/SindilegisBar";
import styles from "./styles";
import Loading from "../../commons/Loading";
import * as routes from '../../../constants/routes'
import MenuButton from "../../commons/MenuButton";
import * as roles from '../../../constants/roles'
import _ from "lodash";

class Escritorios extends React.Component {
  state = {
    open: false,
    confirmOpen: false,
    confirmAction: Function,
    confirmMessage: "",
  };

  componentDidMount() {
    this.props.getEscritoriosAdvocacia();
  }

  handleEscritorioAdvocaciaClickOpen = () => {
    this.setState({ open: true });
  };

  handleEscritorioAdvocaciaClose = (reload) => {
    this.setState({ open: false });
    this.props.setEscritorioAdvocacia(null);
    this.props.setEscritorioAdvocaciaId(null);
    if (reload) {
      this.reloadEscritoriosAdvocacia();
    }
  };

  handleConfirmOpen = (confirmAction, confirmMessage) => {
    this.setState({ confirmOpen: true, confirmAction, confirmMessage });
  };

  handleConfirmClose = (confirm) => {
    if (confirm) {
      this.state.confirmAction();
      this.setState({ confirmOpen: false });
    } else {
      this.setState({ confirmOpen: false });
    }
  };

  reloadEscritoriosAdvocacia() {
    this.props.getEscritoriosAdvocacia();
  }

  succesHandleUpdate = () => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar("Histórico atualizado com sucesso!", {
      variant: "success"
    });
  }

  render() {
    const { classes, escritoriosAdvocacia, loading, location, authUser } = this.props;
    const isEscritorioJuridico = _.includes(
      authUser.roles,
      roles.ESCRITORIO_JURIDICO
    );

    const BUTTON_ROUTES = [
      { label: 'Jurídico', route: routes.JURIDICO },
      { label: 'Gerenciar objetos de processo', route: routes.OBJETO_PROCESSO, condition: isEscritorioJuridico },
      { label: 'Gerenciar processos', route: routes.JURIDICO_PROCESSOS },
      { label: 'Relatório de processos', route: routes.JURIDICO_PROCESSOS_RELATORIO },
    ].filter(button => button.condition !== true)


    return (
      <div className={classes.root}>
        <SindilegisBar />
        <Loading loading={loading} />
        <Container maxWidth="xl">
          <Grid style={{ paddingBottom: 20 }}>
            <MenuButton buttonsRoutes={BUTTON_ROUTES} location={location} />
          </Grid>
          <Grid item xs={12} md={12}>
            <MaterialTable
              columns={[
                { title: "Nome", field: "nome" },
                { title: "E-mail", field: "email" },
                { title: "Destinatário", field: "nomeDestinatario" },
              ]}
              data={escritoriosAdvocacia || []}
              title="Escritórios"
              actions={[
                {
                  icon: "update",
                  tooltip: "Atualizar atendimentos",
                  onClick: async (event, row) => {
                    this.handleConfirmOpen(
                      async () => {
                        await updateAtendimentosByEscritorio(row);
                        this.succesHandleUpdate(true);
                      },
                      "Deseja atualizar o histórico do escritório em atendimentos?"
                    );
                  },
                },
                {
                  icon: "update",
                  tooltip: "Atualizar processos",
                  onClick: async (event, row) => {
                    this.handleConfirmOpen(
                      async () => {
                        await updateProcessosByEscritorio(row);
                        this.succesHandleUpdate(true);
                      },
                      "Deseja atualizar o histórico do escritório em processos?"
                    );
                  },
                },
                {
                  icon: "edit",
                  tooltip: "Editar",
                  onClick: (event, row) =>
                    this.props.setEscritorioAdvocaciaId(row.id) &&
                    this.handleEscritorioAdvocaciaClickOpen(),
                },
                {
                  icon: "delete",
                  tooltip: "Excluir",
                  onClick: (event, row) =>
                    this.handleConfirmOpen(
                      () => this.removeEscritorioAdvocacia(row.id),
                      `Confirma a exclusão permanente do escritório ${row.nome}?`
                    ),
                },
              ]}
              options={{
                actionsColumnIndex: -1,
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100],
                exportButton: true,
                exportAllData: true,
              }}
              localization={tableI18n}
            />
          </Grid>
          <EscritorioForm
            open={this.state.open}
            handleClose={this.handleEscritorioAdvocaciaClose}
          />
          <ConfirmDialog
            open={this.state.confirmOpen}
            message={this.state.confirmMessage}
            onClose={this.handleConfirmClose}
          />
        </Container>
      </div>
    );
  }

  removeEscritorioAdvocacia(id) {
    this.props.deleteEscritorioAdvocacia(id);
  }
}

const mapStateToProps = ({ juridico: { escritoriosAdvocacia, loading } }) => ({
  escritoriosAdvocacia,
  loading,
})




Escritorios.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getEscritoriosAdvocacia,
    setEscritorioAdvocaciaId,
    setEscritorioAdvocacia,
    saveEscritorioAdvocacia,
    deleteEscritorioAdvocacia,
  })
)(withRouter(withSnackbar(Escritorios)));