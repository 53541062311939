import * as auth from "./auth";
import * as db from "./db";
import * as firebase from "./firebase";
import * as functions from "./functions";
import * as storage from "./storage";

const authFailMessage = errorCode => {
  switch (errorCode) {
    case "auth/invalid-email":
      return "E-mail inválido.";
    case "auth/user-disabled":
      return "Usuário desabilitado.";
    case "auth/user-not-found":
      return "Usuário não encontrado.";
    case "auth/wrong-password":
      return "Usuário ou senha inválida.";
    case "auth/email-already-in-use":
      return "E-mail já cadastrado.";
    case "auth/weak-password":
      return "Senha muito fraca";
    default:
      return "Falha ao autenticar.";
  }
};

export { auth, db, firebase, functions, storage, authFailMessage };
