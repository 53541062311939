import React from 'react'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import DeleteIcon from '@material-ui/icons/Delete'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import LinkIcon from '@material-ui/icons/Link'
import SaveIcon from '@material-ui/icons/Save'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Add from '@material-ui/icons/Add'
import Alert from '@material-ui/lab/Alert'
import { withStyles } from '@material-ui/core/styles'
import { withSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { Field, FieldArray, formValueSelector, reduxForm } from 'redux-form'
import { FormControlLabel} from '@material-ui/core'
import Radio from '@material-ui/core/Radio'
import InputText from '../commons/InputText'
import DatePicker from '../commons/DatePicker'
import ConfirmDialog from '../commons/ConfirmDialog'
import TemaLinkFormDialog from './TemaLinkFormDialog'
import { 
  getTema, 
  saveTema, 
  saveAcaoSindilegisTema, 
  createAcaoSindilegisTema, 
  deleteAcaoSindilegisTema,
  incluirLinkAcaoSindilegisTema, 
  incluirDocumentoAcaoSindilegisTema, 
} from '../../actions/politico_sindical'
import { fileUploadCheck, steps, getDocumentosBy, initialValuesTema, hasCurrentItemChanged, ACCEPT_INPUT_FILE, getObjectByKey } from '../../controllers/temaController'
import Switch from '../commons/Switch'
import styles from './styles'
import validate from './validate'
import { storage } from '../../firebase'
import LinkChip from './LinkChip'
import { classificacaoAcoes } from '../../controllers/temaController'
import RadioButton from '../commons/RadioButton'

class TemaStepAcoesSindilegisForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      rootItem: null,
      confirmOpen: false,
      confirmAction: Function,
      confirmMessage: '',
    }
  }
  
  componentDidMount () {
    const { temaId } = this.props
    if (temaId) {
      this.props.getTema(temaId)
    }
  }

  handleFileCapture = (event, acaoSindilegis) => {
    const { temaId } = this.props
    const file = event.target.files[0]

    const documentos = getDocumentosBy(acaoSindilegis)
    const errorsCheck = fileUploadCheck(documentos, file)
    if (_.isEmpty(errorsCheck)) {
      storage.uploadDocumentoAcaoSindilegisTema(temaId, `${acaoSindilegis.id}/${file.name}`, file).then(({ metadata }) => {
        const documento = { nome: file.name, url: metadata.fullPath }
        this.props.incluirDocumentoAcaoSindilegisTema(temaId, acaoSindilegis.id, documento)
        this.props.getTema(temaId)
      })
    } else {
      const { enqueueSnackbar } = this.props
      _.each(errorsCheck, err => {
        enqueueSnackbar(err.msg, { variant: err.variant })
      })
    }
  }

  handleDialogLinkOpen = acaoSindilegis => {
    this.setState({ open: true })
    this.setState({ rootItem: acaoSindilegis })
  }

  handleDialogLinkClose = () => {
    this.setState({ open: false })
    this.setState({ rootItem: null })
  }

  handleSaveLinkAcaoSindilegisTema = (link, acaoSindilegis, callback) => {
    const { temaId } = this.props
    if (temaId) {
      this.props.incluirLinkAcaoSindilegisTema(temaId, acaoSindilegis.id, link)
      this.handleDialogLinkClose()
      this.props.getTema(temaId)
    }
    callback && callback()
  }

  handleDeleteLink(link, acaoSindilegis) {
    const { temaId } = this.props
    let linksAtualizados = {}
    this.handleConfirmOpen(() => {
      if (acaoSindilegis.links && acaoSindilegis.links[link.id]) {
        linksAtualizados = _.omit(acaoSindilegis.links, [link.id])
      }
      this.props.saveAcaoSindilegisTema(acaoSindilegis.id, temaId, { links: linksAtualizados })
      this.props.getTema(temaId)
    }, `Confirma exclusão do link ${link.nome} da ação do Sindilegis ${acaoSindilegis.descricao_acao}?`)
  }

  save(values) {
    const { handleSubmit, temaId } = this.props
    handleSubmit && handleSubmit()
    if (temaId) {
      const hasSomeNewItem = _.some(values['acoes_sindilegis'], ({ id }) => _.isNil(id))
      if (hasSomeNewItem) {
        _.forEach(values['acoes_sindilegis'], acao => {
          _.isNil(acao.id)
            ? this.props.createAcaoSindilegisTema(temaId, acao)
            : this.props.saveAcaoSindilegisTema(acao.id, temaId, acao)
        })
        this.props.saveTema(temaId, { page: steps.ACOES, existe_acoes_sindilegis: true }, () => this.successHandler(true))
      } else {
        this.props.saveTema(temaId, values, () => this.successHandler(true))
      }
    }
  }

  handleSaveAcaoSindilegis (acao) {
    const { temaId } = this.props
    if (temaId && !_.isEmpty(acao)) {
      const { id } = acao
      _.isNil(id)
        ? this.props.createAcaoSindilegisTema(temaId, acao)
        : this.props.saveAcaoSindilegisTema(id, temaId, acao)

      this.props.getTema(temaId)
    }
  }

  handleConfirmClose = confirm => {
    if (confirm) {
      this.state.confirmAction()
      this.setState({ confirmOpen: false })
    } else {
      this.setState({ confirmOpen: false })
    }
  }

  handleConfirmOpen = (confirmAction, confirmMessage) => {
    this.setState({ confirmOpen: true, confirmAction, confirmMessage })
  }

  handleDeleteDocumento(documento, acaoSindilegis) {
    const { temaId } = this.props
    let documentosAtualizados = {}
    this.handleConfirmOpen(() => {
      if (acaoSindilegis.documentos && acaoSindilegis.documentos[documento.id]) {
        documentosAtualizados = _.omit(acaoSindilegis.documentos, [documento.id])
      }

      this.props.saveAcaoSindilegisTema(acaoSindilegis.id, temaId, { documentos: documentosAtualizados })
      storage.removerAnexo(documento.url)
      this.props.getTema(temaId)
    }, `Confirma exclusão do documento ${documento.nome} da ação do Sindilegis ${acaoSindilegis.descricao_acao}?`)
  }

  handleDeleteAcaoSindilegis (acao, removeFieldCallback) {
    const { temaId } = this.props
    const documentos = getDocumentosBy(acao)
    if (!_.isNil(acao) && !_.isNil(acao.id)) {
      this.handleConfirmOpen(() => {
        this.props.deleteAcaoSindilegisTema(acao.id, temaId)
        _.forEach(documentos, ({ url }) => storage.removerAnexo(url))
        removeFieldCallback && removeFieldCallback()
      }, `Confirma exclusão completa da ação do Sindilegis ${acao.descricao_acao}?`)
    } else {
      removeFieldCallback && removeFieldCallback()
    }
  }

  successHandler(reload) {
    const { enqueueSnackbar, handleClose } = this.props
    enqueueSnackbar('Tema salvo com sucesso!', {
      variant: 'success'
    })
    handleClose(reload)
  }

  renderGridActionsIconButtons (fields, index, hasNoItemId) {
    const { tema } = this.props
    const saveBtnDisabled = _.isEmpty(fields.get(index).descricao_acao) || _.isNil(fields.get(index).data_acao)
    const shouldToSaveItem = hasNoItemId || hasCurrentItemChanged(tema, fields.get(index), 'acoes_sindilegis')
    return (
      <Grid container item xs={2} justifyContent='center'>
        <Grid item>
          <Tooltip title='Salvar ação do Sindilegis' arrow>
            <span>
              <IconButton 
                color={'primary'} 
                aria-label={'Salvar'}
                disabled={saveBtnDisabled}
                onClick={() => this.handleSaveAcaoSindilegis(fields.get(index))}
              >
                { shouldToSaveItem
                    ? (<SaveIcon />)
                    : (<CheckCircleIcon />)
                }
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title='Deletar ação do Sindilegis' arrow>
            <IconButton 
              color={'secondary'} 
              aria-label={'Remover'}
              onClick={ () => this.handleDeleteAcaoSindilegis(fields.get(index), () => fields.remove(index)) }
            >
              <DeleteIcon /> 
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    )
  }

  renderDocumentoAnexoChip(item, id, acao) {
    const { canDelete, classes } = this.props
    return canDelete
      ? <Chip
          className={classes.truncateDocumentoAnexoChip}
          key={id} 
          size='small'
          onDelete={() => this.handleDeleteDocumento({ ...item, id }, acao)} 
          onClick={() => storage.downloadAnexo(item.url)} 
          label={item.nome} 
          clickable 
          variant='outlined'
        />
      : <Chip 
      className={classes.truncateDocumentoAnexoChip}
          key={id} 
          size='small'
          onClick={() => storage.downloadAnexo(item.url)} 
          label={item.nome} 
          clickable 
          variant='outlined' 
        />
  }

  renderFileUploadGrid (fields, index, hasNoItemId) {
    const { classes, canDelete } = this.props
    const documentos = getDocumentosBy(fields.get(index))
    const links = getObjectByKey(fields.get(index), 'links')
    const acaoSindilegis = fields.get(index)
    return (
      <React.Fragment>
        <Grid item xs={3}>
          <Tooltip 
            title={
              hasNoItemId 
              ? 'Salve a ação para anexar documentos' 
              : 'Informações da ação do Sindilegis'
            } 
            arrow
          >
            <span className={classes.verticalDivider}>
              <input
                accept={ACCEPT_INPUT_FILE}
                className={classes.inputFileHidden}
                id={index}
                disabled={hasNoItemId}
                onChange={(event) => this.handleFileCapture(event, acaoSindilegis)}
                type='file'
              />
              <label htmlFor={index}>
                <Typography className={classes.anexarDocumentoLabel} variant={'caption'}>
                  <IconButton 
                    color='primary' 
                    component='span'
                    aria-label={'File upload'} 
                    disabled={hasNoItemId}
                  >
                    <CloudUploadIcon />
                  </IconButton>
                </Typography>
              </label>

              <Typography 
                className={classes.anexarDocumentoLabel} 
                variant={'caption'}>
                <IconButton 
                  color='primary' 
                  component='span'
                  aria-label={'Add link'} 
                  disabled={hasNoItemId}
                  onClick={() => this.handleDialogLinkOpen(acaoSindilegis)}
                >
                  <LinkIcon />
                </IconButton>
              </Typography>
            </span>
          </Tooltip>
        </Grid>
        <Grid item container sm={9} spacing={1} className={classes.docsUploadedContainer}>
          {_.map(documentos, (item, key) => (
            <div key={key}>
              <Tooltip title={item.nome} arrow>
                {this.renderDocumentoAnexoChip(item, key, acaoSindilegis)}
              </Tooltip>
            </div>
          ))}
          {
            _.map(links, (item, key) => (
              <LinkChip
                key={key} 
                item={item} 
                id={key} 
                canDelete={canDelete} 
                size='small'
                rootItem={acaoSindilegis} 
                handleDeleteLink={this.handleDeleteLink.bind(this)}
              />
            ))
          }
        </Grid>
      </React.Fragment>
      
    )
  }

  renderAcaoSindilegis ({ fields, meta: { error, submitFailed }}) {
    const { classes } = this.props
    return (
      <Grid container className={classes.itensDestaqueContainer}>
        <Grid item xs={12}>
          <Button type='button' variant={'outlined'} color={'primary'} endIcon={<Add />} onClick={() => fields.push({})}>
            Nova ação
          </Button>
        </Grid>
        <Grid item xs={12}>
          {submitFailed && error && <Alert className={classes.alertMsg} severity='warning'>{error}</Alert>}
        </Grid>
        {fields.map((item, index) => {
          const hasNoItemId = _.isNil(fields.get(index).id)
          return (
          <Grid item container key={index} className={classes.itensDestaque}>
            <Grid container item justifyContent='space-between'>
              <Typography variant={'body2'}>Ação #{index + 1}</Typography>
              {this.renderGridActionsIconButtons(fields, index, hasNoItemId)}
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={3} style={{marginTop: 5}}>
                <Field name={`${item}.data_acao`} component={DatePicker} label='Data da ação' />
              </Grid>
              <Grid item xs={9}>
                <Field name={`${item}.descricao_acao`} component={InputText} label='Descrição da ação' />
              </Grid>
              <Grid container spacing={2} alignItems="center">
              <Grid item xs={6}>
                <Field name={`${item}.classificacao_acao`} component={RadioButton} label='Classificação da ação'>
                  {_.map(classificacaoAcoes, (classificacaoAcoes, key) => (
                    <FormControlLabel key={key} value={classificacaoAcoes.value} control={<Radio />} label={classificacaoAcoes.label} />
                  ))}
                </Field>
              </Grid>
              <Grid item xs={5} sm={5}>
                <Field name={`${item}.comunicacao`} component={Switch} label="Comunicar interessados" />
              </Grid>
            </Grid>
            </Grid>
            <Grid container item spacing={2}>
              {this.renderFileUploadGrid(fields, index, hasNoItemId)}
            </Grid>
          </Grid>
        )}).reverse()}
      </Grid>
    )
  }

  renderDadosAcoesSindilegisGrid () {
    const { hasAcaoSindilegis } = this.props
    return hasAcaoSindilegis && (
      <React.Fragment>
        <Grid item sm={12}>
          <Typography variant={'body1'}>
            Ações
          </Typography>
        </Grid>
        <FieldArray name='acoes_sindilegis' component={this.renderAcaoSindilegis.bind(this)} />
    </React.Fragment>
    )
  }

  render() {
    const {
      classes,
      handleSubmit,
      reset,
      submitting,
      previousPage,
      canEdit,
      canDelete
    } = this.props

    return (
      <React.Fragment>
      <form className={classes.container} onSubmit={handleSubmit(this.save.bind(this))}>
        <DialogTitle id='form-dialog-title'>Inclusão de novo tema</DialogTitle>
        <DialogContent style={{ minHeight: '50vh' }}>
          <Grid
            container
            spacing={2}
          >
            <Grid item sm={12}>
              <Field 
                name='existe_acoes_sindilegis'
                component={Switch}
                label='Existem ações do Sindilegis a respeito do tema?'/>
            </Grid>
            {this.renderDadosAcoesSindilegisGrid()}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => reset() && this.props.handleClose(null)}
            color='default'
          >
            Cancelar
          </Button>
          <Button
            onClick={previousPage}
            color='default'
          >
            Voltar
          </Button>
          <Button
            type='submit'
            disabled={!canEdit || submitting}
            color='primary'
          >
            Concluir
          </Button>
        </DialogActions>
        <ConfirmDialog
          open={this.state.confirmOpen}
          message={this.state.confirmMessage}
          onClose={this.handleConfirmClose}
        />
      </form>
      <TemaLinkFormDialog
        open={this.state.open}
        rootItem={this.state.rootItem}
        handleClose={this.handleDialogLinkClose.bind(this)}
        handleSave={this.handleSaveLinkAcaoSindilegisTema.bind(this)}
        canEdit={canEdit}
        canDelete={canDelete}
      />
      </React.Fragment>
    )
  }
}

const selector = formValueSelector('tema')

const mapStateToProps = state => {
  const { politico_sindical: { tema, temaId } } = state
  return {
    tema: tema,
    temaId: temaId,
    initialValues: { ...initialValuesTema, ...tema, page: steps.TEMA -1 },
    hasAcaoSindilegis: selector(state, 'existe_acoes_sindilegis') || false
  }
}

TemaStepAcoesSindilegisForm.propTypes = {
  classes: PropTypes.object.isRequired
}

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    {
      getTema, 
      saveTema, 
      saveAcaoSindilegisTema, 
      createAcaoSindilegisTema, 
      deleteAcaoSindilegisTema,
      incluirLinkAcaoSindilegisTema,
      incluirDocumentoAcaoSindilegisTema, 
    }
  )
)(
  reduxForm({
    form: 'tema',
    validate,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
  })(withSnackbar(TemaStepAcoesSindilegisForm))
)
