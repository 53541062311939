import { DEPENDENTS_SET, REMOVE_DEPENDENT, DEPENDENTES_COM_TITULAR_SET, DEPENDENT_UPDATED, DEPENDENT_SET } from './types';
import { db } from "../firebase";
import _ from "lodash";
import { reset } from "redux-form";

export const saveDependent = (filiadoId, values, callback) => {
     return dispatch => {
          db.doSaveDependent(filiadoId, values).then(() => {
               dispatch(reset('dependent'))
               callback && callback();
          });
     };
}

export const atualizarDependente = (filiadoId, id, dependent, callback) => {
     return dispatch => {
          db.doUpdateDependent(filiadoId, id, dependent).then(() => {
               dispatch({ type: DEPENDENT_UPDATED, dependent: { id, ...dependent } });
               callback && callback();
          })
     }
}

export const incluirAnexoDependente = (id, path) => {
     return dispatch => {
          db.doIncluirAnexoDependente(id, path)
     };
}

export const listDependentes = () => {
     return dispatch => {

          db.onceGetFiliados().then(snapshot => {
               const filiados = snapshot.val();
               db.onceListDependents().then(snapshot => {
                    const dependentesComTitular = [];
                    const dependents = snapshot.val()

                    Object.keys(dependents).forEach(key => {
                         const filiado = filiados[key];
                         const dependentesFiliado = dependents[key];
                         Object.keys(dependentesFiliado).forEach(id => {
                              dependentesFiliado[id].id = id;
                              if (filiado) {
                                   dependentesFiliado[id].nome_titular = filiado.nome_completo;
                                   dependentesFiliado[id].cpf_titular = filiado.cpf;
                                   dependentesFiliado[id].id_titular = key;
                              }
                         })
                         dependentesComTitular.push(...(_.values(dependentesFiliado)));
                    })

                    dispatch({ type: DEPENDENTES_COM_TITULAR_SET, dependentesComTitular })
               })
          })


     }
}

export const getDependents = (id) => {
     return dispatch => {
          db.onceGetDependents(id).then(snapshot => {
               let dependents = snapshot.val()

               if (dependents) {
                    Object.keys(dependents).map(id => (dependents[id].id = id))
                    dependents = _.values(dependents)
               } else {
                    dependents = [];
               }
               dispatch({ type: DEPENDENTS_SET, dependents })
          })
     }
}

export const setDependents = (dependents) => dispatch => dispatch({ type: DEPENDENTS_SET, dependents })

export const setDependente = (dependent) => dispatch => dispatch({ type: DEPENDENT_SET, dependent })

export const removeDependent = (filiadoId, id) => {
     return dispatch => {
          db.doDeleteDependent(filiadoId, id).then(() => {
               dispatch({ type: REMOVE_DEPENDENT, id });
          });
     }
}