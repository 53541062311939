import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { getFiliados } from "../../actions/filiado";
import tableI18n from "../TableLocalization";
import SindilegisBar from "../commons/SindilegisBar";
import styles from "./styles";
import Loading from "../commons/Loading";
import { formataData } from "../Financial/util";
import CardHeader from "@material-ui/core/CardHeader";
import Card from "@material-ui/core/Card";
import { setFiliadosAComunicar } from "../../actions/comunicacao";
import ComunicacaoDialog from "./comunicacao"
import CustomDialog from "../commons/CustomDialog";
import TooltipText from "../commons/TooltipText";
import ButtonComponent from "../commons/ButtonComponent";

class Comunicacao extends React.Component {

    state = {
        open: false, filiados: [], dialog: {
            open: false,
            tipo: '',
            conteudo: ''
        }
    }

    componentDidMount() {
        this.props.getFiliados()
    }

    handleComunicacaoClose = () => {
        this.setState({
            ...this.state, open: false, filiados: this.state.filiados.map(f => {
                if (f.tableData) {
                    f.tableData.checked = false;
                }
                return f;
            })
        });
    };

    handleOpenDialog = (tipo, conteudo) => {
        this.setState({
            dialog: {
                open: true,
                tipo,
                conteudo
            }
        });
    }

    handleCloseDialog = () => {
        this.setState({
            dialog: {
                open: false,
                tipo: '',
                conteudo: ''
            }
        });
    }

    handleOpenFiliado = (selectedFiliado) => {
        this.handleOpenDialog('Nome Completo', selectedFiliado);
    }

    handleOpenCargo = (selectedCargo) => {
        this.handleOpenDialog('Cargo', selectedCargo);
    }

    handleOpenEndereco = (selectedEndereco) => {
        this.handleOpenDialog('Endereço', selectedEndereco);
    }

    render() {
        const { classes, filiados, loading, filiadosAComunicar } = this.props;
        if (this.state.filiados.length === 0 && filiados.length > 0) {
            this.setState({ ...this.state, filiados })
        }
        const localization = tableI18n;
        localization.toolbar.nRowsSelected = "{0} Filiado(s) selecionado(s)";
        const { dialog } = this.state


        return (
            <div className={classes.root}>
                <SindilegisBar />
                <Loading loading={loading} />
                <Card className={classes.paper} elevation={1} hidden={loading}>
                    <CardHeader title='Comunicação' action={
                        <React.Fragment>
                            <ButtonComponent
                                variant="insert"
                                disabled={filiadosAComunicar.length === 0}
                                onClick={() => this.setState({ ...this.state, open: true })}>
                                Comunicar
                            </ButtonComponent>
                        </React.Fragment>
                    } />
                    <Grid item xs={12} md={12}>
                        <Typography variant="h6" className={classes.title} />
                        <div className={classes.demo}>
                            <MaterialTable
                                columns={[
                                    { title: "Matrícula", field: "matricula" },
                                    {
                                        title: "Nome Completo", field: "nome_completo",
                                        render: rowData => (
                                            <TooltipText
                                                text={rowData.nome_completo}
                                                onClick={() => this.handleOpenFiliado(rowData.nome_completo)}
                                            />
                                        )
                                    },
                                    { title: "Email", field: "email" },
                                    {
                                        title: "Cargo", field: "cargo",
                                        render: rowData => (
                                            <TooltipText
                                                text={rowData.cargo}
                                                onClick={() => this.handleOpenCargo(rowData.cargo)}
                                            />
                                        )
                                    },
                                    { title: "CPF", field: "cpf" },
                                    { title: "Data de nascimento", field: "data_nascimento" },
                                    { title: "Sexo", field: "sexo" },
                                    { title: "Casa", field: "empresa" },
                                    { title: "CEP", field: "cep" },
                                    {
                                        title: "Endereço", field: "logradouro",
                                        render: rowData => (
                                            <TooltipText
                                                text={rowData.logradouro}
                                                onClick={() => this.handleOpenEndereco(rowData.logradouro)}
                                            />
                                        )
                                    },
                                    { title: "Bairro", field: "bairro" },
                                    { title: "Cidade", field: "cidade" },
                                    { title: "UF", field: "estado" },
                                    { title: "Situação", field: "situacao_filiado" },
                                    {/* nome_falecido é somente no db, o correto é o que está no title */ },
                                    { title: "Nome do servidor titular", field: "nome_falecido" },
                                    {/* cpf_falecido é somente no db, o correto é o que está no title */ },
                                    { title: "CPF do titular", field: "cpf_falecido" },
                                    { title: "Celular", field: "tel_celular" },
                                    { title: "Tel. comercial", field: "tel_comercial" },
                                    { title: "Tel. residencial", field: "tel_residencial" },
                                    { title: "Forma de pagamento", field: "forma_pagamento" },
                                    {
                                        title: "Data de cadastro", field: "data_cadastro",
                                        render: (row) => (formataData(row.data_cadastro))
                                    },
                                    {
                                        title: "Cadastro",
                                        field: "cadastro",
                                        lookup: { 'FILIADO': 'Filiado', 'INATIVO': 'Desfiliado' },
                                    },
                                ]}
                                data={this.state.filiados}
                                title=""
                                localization={localization}
                                options={{
                                    filtering: true,
                                    selection: true,
                                    padding: 'dense'
                                }}
                                icons={{ Filter: () => (<span></span>) }}
                                onSelectionChange={(filiadosAComunicar) => { return this.props.setFiliadosAComunicar(filiadosAComunicar) }}

                            />
                        </div>
                    </Grid>
                </Card>
                <ComunicacaoDialog
                    open={this.state.open}
                    handleClose={this.handleComunicacaoClose}
                />
                <CustomDialog
                    open={dialog.open}
                    onClose={this.handleCloseDialog}
                    title={dialog.tipo}
                    content={dialog.conteudo}
                />
            </div>
        );
    }
}

const mapStateToProps = ({ filiado: { filiados, loading }, comunicacao: { filiadosAComunicar } }) =>
    ({ filiados, loading, filiadosAComunicar });


Comunicacao.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withStyles(styles),
    connect(
        mapStateToProps,
        { getFiliados, setFiliadosAComunicar }
    ),
)(Comunicacao);
