import _ from "lodash";
import { EVENTOS_SET, EVENTO_SET, EVENTO_ID_SET, EVENTOS_REQUEST, EVENTO_UPDATED, DELETE_EVENTO } from './types';
import { db } from "../firebase";
import { formataData } from "../components/Financial/util";

export const saveEvento = (id, data, callback) => {
     return dispatch => {
          db.doSaveEvento(id, data).then(() => {
               data.data_inicio = formataData(data.data_inicio);
               data.data_fim = formataData(data.data_fim);
               dispatch({ type: EVENTO_UPDATED, payload: { id, data } });
               callback && callback();
          });
     };
}

export const criarEvento = (data, callback) => {
     return dispatch => {
          db.doCreateEvento(data).then(() => {
               callback && callback();
          });
     };
}

export const getEventos = () => {
     return dispatch => {
          dispatch({ type: EVENTOS_REQUEST });
          db.onceGetEventos().then((snapshot) => {
               let eventos = snapshot.val()
               if (eventos) {
                    Object.keys(eventos).map(id => {
                         eventos[id].id = id
                         return eventos[id]
                    });
                    eventos = _.orderBy(_.values(eventos), [b => b.data_inicio], ['desc']);
                    eventos.forEach(item => {
                         item.data_inicio = formataData(item.data_inicio)
                         item.data_fim = formataData(item.data_fim)
                    })
               } else {
                    eventos = []
               }

               dispatch({ type: EVENTOS_SET, eventos });
          })
     }
}

export const getEvento = (id) => {
     return dispatch => {
          db.onceGetEvento(id).then((snapshot) => {
               const evento = snapshot.val()
               dispatch({ type: EVENTO_SET, evento })
          });
     }
}

export const deleteEvento = (id) => {
     return dispatch => {
          db.doDeleteEvento(id).then(() => {
               dispatch({ type: DELETE_EVENTO, payload: { id } });
          })
     }
}

export const setEvento = (evento) => dispatch => dispatch({ type: EVENTO_SET, evento })

export const setEventoId = (eventoId) => dispatch => dispatch({ type: EVENTO_ID_SET, eventoId })