import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { compose } from "recompose";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import InputText from "../commons/InputText";
import { withSnackbar } from "notistack";
import styles from "./styles";
import { getItemInteracao, criarItemInteracao, saveItemInteracao } from "../../actions/item_interacao";

class ItemInteracaoForm extends Component {


  constructor(props) {
    super(props);
    this.save = this.save.bind(this);
  }

  componentWillReceiveProps({ item_interacao_id }) {
    if(item_interacao_id){
    this.props.getItemInteracao(item_interacao_id);
  }
}

  save(item_interacao) {
    const { item_interacao_id } = this.props;
    if (item_interacao_id) {
      this.props.saveItemInteracao(item_interacao_id, item_interacao, () => this.successHandler())
    } else {
      this.props.criarItemInteracao(item_interacao, () => this.successHandler(true))
    }
  }

  successHandler(reload) {
    const { reset, handleClose, enqueueSnackbar } = this.props;
    enqueueSnackbar("Item de interação salva com sucesso!", {
      variant: "success"
    });
    reset('item_interacao')
    handleClose(reload);
  }

  errorHandler() {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar("Erro ao salvar item de interação.", {
      variant: "error"
    })
  }

  render() {
    const { handleSubmit, pristine, submitting, reset } = this.props;

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose.bind(this, null)}
        fullWidth
        maxWidth="sm"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Item de interação</DialogTitle>
        <form onSubmit={handleSubmit(this.save)}>
          <DialogContent>
            <Grid
              container
              spacing={2}
              hidden={false}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item sm={12}>
                <Field
                  name="nome"
                  component={InputText}
                  label="Nome"
                />
              </Grid>
              <Grid item sm={12}>
                <Field
                  name="descricao"
                  component={InputText}
                  label="Descrição"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => reset() && this.props.handleClose()}
              color="default"
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={pristine || submitting}
              color="primary"
            >
              Salvar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

ItemInteracaoForm.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ item_interacao: { item_interacao, item_interacao_id }, }) => ({
  item_interacao: item_interacao,
  item_interacao_id: item_interacao_id,
  initialValues: item_interacao
});

const validate = values => {
  const errors = {};
  const requiredFields = [
    "nome",
    "descricao"
  ];
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = " Obrigatório";
    }
  });
  return errors;
};

export default compose(
  connect(
    mapStateToProps,
    { getItemInteracao, criarItemInteracao, saveItemInteracao }
  ),
  withStyles(styles)
)(
  reduxForm({
    form: "item_interacao",
    validate,
    enableReinitialize: true,
  })(withSnackbar(ItemInteracaoForm))
);
