import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import styles from "./styles";
import MaterialTable from "material-table";
import { withRouter } from "react-router-dom";
import tableI18n from "../../TableLocalization";
import { getTemas } from "../../../actions/politico_sindical";
import { Grid, Link } from "@material-ui/core";
import DescriptionIcon from '@material-ui/icons/Description'
import { storage } from "../../../firebase";

class ArquivosAcoesSindilegis extends React.Component {
  render() {
    const { handleClose, acaoId, classes, temas } = this.props;
    const tema = temas?.find(
      (tema) => tema.id === this.props.match.params.temaId
    );
    const acaoSindilegis = tema?.acoes_sindilegis;
    const acaoArray = Object.keys(acaoSindilegis || {}).map((id) => ({
      ...acaoSindilegis[id],
      id: id,
    }));
        const acao = acaoArray.find((item) => item.id === acaoId) || [];

    let documentos = [];
    if (acao && acao.documentos) {
      documentos = Object.values(acao.documentos);
    }

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle>{"Ação"}</DialogTitle>
        <DialogContent>
          <div className={classes.demo}>
            <MaterialTable
              columns={[
                {
                  title: "Nome do arquivo",
                  field: "nome",
                  render: (rowData) => (
                    <Link
                      component="button"
                      variant="body2"
                      onClick={() => storage.downloadAnexo(rowData.url)}
                    >
                      <Grid container alignItems="center" spacing={1}>
                        <DescriptionIcon/>
                        {rowData.nome}
                      </Grid>
                    </Link>
                  ),
                },
              ]}
              data={documentos}
              title={"Arquivos"}
              options={{
                actionsColumnIndex: -1,
              }}
              localization={tableI18n}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() =>handleClose(acaoId)} color="default">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ politico_sindical: { temas } }) => ({
  temas: temas,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getTemas })
)(withRouter(ArquivosAcoesSindilegis));
