import { EVENTOS_SET, EVENTO_SET, EVENTO_ID_SET, EVENTOS_REQUEST, EVENTO_UPDATED, DELETE_EVENTO } from "../actions/types";

const INITIAL_STATE = {
  eventos: [],
  evento: null,
  eventoId: null,
  historico: [],
  loading: false
};

function eventoReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case EVENTOS_SET: {
      return { ...state, eventos: action.eventos, loading: false }
    }
    case EVENTOS_REQUEST: {
      return { ...state, loading: true }
    }
    case EVENTO_SET: {
      return { ...state, evento: action.evento, loading: false }
    }
    case EVENTO_ID_SET: {
      return { ...state, eventoId: action.eventoId }
    }
    case EVENTO_UPDATED: {
      const { eventos } = state
      const { id, data } = action.payload
      const index = eventos.findIndex(f => f.id === id)
      const evento = { ...eventos[index], ...data }
      eventos.splice(index, 1, evento)
      return { ...state, eventos: [...eventos] }
    }
    case DELETE_EVENTO: {
      const { eventos } = state
      const index = eventos.findIndex(f => f.id === action.payload.id)
      eventos.splice(index, 1)
      return { ...state, eventos: [...eventos] }
    }
    default:
      return state;
  }
}

export default eventoReducer;
