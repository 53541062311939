import { INTERACOES_SET, INTERACAO_SET, INTERACAO_ID_SET, INTERACOES_REQUEST, INTERACAO_UPDATED, DELETE_INTERACAO } from "../actions/types";

const INITIAL_STATE = {
  interacoes: {},
  interacao: null,
  interacaoId: null,
  historico: [],
  loading: false
};

function interacaoReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case INTERACOES_SET: {
      return { ...state, interacoes: action.interacoes, loading: false }
    }
    case INTERACOES_REQUEST: {
      return { ...state, loading: true }
    }
    case INTERACAO_SET: {
      return { ...state, interacao: action.interacao, loading: false }
    }
    case INTERACAO_ID_SET: {
      return { ...state, interacaoId: action.interacaoId }
    }
    case INTERACAO_UPDATED: {
      const { interacoes } = state
      const { id, data } = action.payload
      const ids = id.split('/');
      interacoes[ids[0]][ids[1]] = { ...interacoes[ids[0]][ids[1]], ...data }
      return { ...state, interacoes: { ...interacoes } }
    }
    case DELETE_INTERACAO: {
      const { interacoes } = state
      const ids = action.payload.id.split('/');
      delete (interacoes[ids[0]][ids[1]]);
      return { ...state, interacoes: { ...interacoes } }
    }
    default:
      return state;
  }
}

export default interacaoReducer;
