export default theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    margin: 20,
    marginTop: 40
  },
  detail: {
    padding: theme.spacing(1),
  },
  button: {
    marginBottom: 10
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
      margin: 0
    }
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  grow: {
    flexGrow: 1
  },
  input: {
    display: 'none'
  }
});
