import React from "react";
import { Field, reduxForm, FieldArray } from "redux-form";
import { compose } from "recompose";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import DatePicker from "../../commons/DatePicker";
import InputText from "../../commons/InputText";
import Select from "../../commons/Select";
import { withSnackbar } from "notistack";
import styles from "../styles";
import * as config from "./config";
import {
  atualizarProcesso,
  criarProcessoAdministrativo,
  getEscritoriosAdvocacia,
} from "../../../actions/juridico";
import { getObjetosProcesso } from "../../../actions/objetoProcesso";
import AsyncAutoCompleteSelect from "../../commons/AsyncAutoComplete";
import { getFiliados } from "../../../actions/filiado";
import Typography from "@material-ui/core/Typography";
import Comentarios from "../comentarios";

class ProcessoAdministrativoForm extends React.Component {
  constructor(props) {
    super(props);
    this.save = this.save.bind(this);
  }
  componentDidMount() {
    this.props.getEscritoriosAdvocacia();
    this.props.getObjetosProcesso();

    if (!this.props.filiados || this.props.filiados.length === 0) {
      this.props.getFiliados();
    }
  }

  save(processoAdministrativo) {
    const { escritoriosAdvocacia, filiados } = this.props;

    if (processoAdministrativo.id) {
      this.props.atualizarProcesso(
        processoAdministrativo,
        filiados,
        escritoriosAdvocacia,
        () => this.successHandler(true)
      );
    } else {
      this.props.criarProcessoAdministrativo(
        processoAdministrativo,
        filiados,
        escritoriosAdvocacia,
        () => this.successHandler(true)
      );
    }
  }

  successHandler(reload) {
    const { handleClose, enqueueSnackbar } = this.props;
    enqueueSnackbar("Processo administrativo salvo com sucesso!", {
      variant: "success",
    });
    this.props.reset();
    handleClose(reload);
  }

  cancelar() {
    this.props.reset();
    this.props.handleClose();
  }

  errorHandler() {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar("Erro ao salvar interação.", {
      variant: "error",
    });
  }

  render() {
    const { handleSubmit, pristine, invalid, submitting, filiados, authUser } =
      this.props;
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose.bind(this, null)}
        fullWidth
        maxWidth="md"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {this.props.processo ? "Editar" : "Novo"} processo administrativo
        </DialogTitle>
        <form onSubmit={handleSubmit(this.save)}>
          <DialogContent>
            <Grid
              container
              spacing={2}
              hidden={false}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item sm={4}>
                <Field
                  name="tribunalOrgao"
                  component={Select}
                  label="Tribunal/Orgão"
                >
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  {config.tribunaisOrgaos.map((t) => (
                    <MenuItem key={t} value={t}>
                      {t}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item sm={4}>
                <Field
                  name="dataProtocolo"
                  component={DatePicker}
                  label="Data de protocolo"
                />
              </Grid>
              <Grid item sm={4}>
                <Field
                  name="numeroProcesso"
                  component={InputText}
                  multiline={false}
                  label="Número do processo"
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              hidden={false}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item sm={12}>
                <Field name="escritorio" component={Select} label="Escritório">
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  {this.props.escritoriosAdvocacia.map((t) => (
                    <MenuItem key={t.nome} value={t.id}>
                      {t.nome}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              hidden={false}
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Grid item sm={12}>
                <Field
                  defaultOptions={[]}
                  isMulti={true}
                  loadOptions={(input) => {
                    if (input.length < 3) {
                      return Promise.resolve([]);
                    }
                    return Promise.resolve(
                      filiados
                        .filter(
                          (f) =>
                            f.nome_completo &&
                            f.nome_completo
                              .toLowerCase()
                              .includes(input.toLowerCase())
                        )
                        .map((f) => ({ label: `${f.nome_completo} - ${f.cpf}`, value: f.id }))
                        .slice(0, 100)
                    );
                  }}
                  name="filiados"
                  component={AsyncAutoCompleteSelect}
                  label="Filiados interessados"
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              hidden={false}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item sm={12}>
                <Field
                  name="objetoProcesso"
                  component={Select}
                  label="Objeto do processo"
                >
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  {this.props.objetosProcesso.map((t) => (
                    <MenuItem
                      key={t.codigo + " - " + t.descricao}
                      value={t.codigo + " - " + t.descricao}
                    >
                      {t.codigo + " - " + t.descricao}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item sm={12}>
                <Typography variant="h6">Comentários registrados</Typography>
                <FieldArray
                  name="comentarios"
                  component={Comentarios}
                  email={authUser?.email}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.cancelar.bind(this)} color="default">
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={pristine || submitting || invalid}
              color="primary"
            >
              Salvar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

ProcessoAdministrativoForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({
  filiado: { filiado, filiadoId, filiados },
  juridico: { escritoriosAdvocacia, processo },
  objetoProcesso: { objetosProcesso },
}) => ({
  initialValues: processo
    ? {
        ...processo,
        escritorio: processo.escritorio.id,
        filiados: processo.filiados
          ? processo.filiados.map((f) => ({
              label: f.nome_completo,
              value: f.id,
            }))
          : undefined,
      }
    : undefined,
  processo,
  escritoriosAdvocacia,
  filiado,
  filiadoId,
  filiados,
  objetosProcesso,
});

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "tribunalOrgao",
    "dataProtocolo",
    "objetoProcesso",
    "numeroProcesso",
    "escritorio",
    "filiados",
  ];
  requiredFields.forEach((field) => {
    if (
      !values[field] ||
      (Array.isArray(values[field]) && values[field].length === 0)
    ) {
      errors[field] = " Obrigatório";
    }
  });

  return errors;
};

export default compose(
  connect(mapStateToProps, {
    atualizarProcesso,
    criarProcessoAdministrativo,
    getEscritoriosAdvocacia,
    getFiliados,
    getObjetosProcesso,
  }),
  withStyles(styles)
)(
  reduxForm({
    form: "processoAdministrativo",
    validate,
    enableReinitialize: true,
  })(withSnackbar(ProcessoAdministrativoForm))
);
