import React from "react";
import {connect} from "react-redux";
import {Field, reduxForm, reset} from "redux-form";
import {compose} from "recompose";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import {withStyles} from "@material-ui/core/styles";
import InputText from "../commons/InputText";
import {withSnackbar} from "notistack";
import styles from "./styles";
import {Divider} from "@material-ui/core";
import {functions} from "../../firebase";
import Loading from "../commons/Loading";

class CreateUser extends React.Component {

    state = {lading: false}

    createUser = (values) => {
        this.setState({loading: true})
        const {enqueueSnackbar, handleClose} = this.props;
        functions.createUser(values).then(() => {
            this.setState({loading: false})
            enqueueSnackbar("Usuário criado com sucesso!", {variant: "success"});
            reset('users');
            handleClose();
        }).catch(() => {
            enqueueSnackbar("Falha ao criar novo usuário. Entre em contato com o administrador do sistema.", {
                variant: "error"
            });
            this.setState({loading: false})
        });
    }

    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
                fullWidth
                maxWidth="md"
                aria-labelledby="form-dialog-title"
            >
                <Loading loading={this.state.loading} height={190}/>
                {!this.state.loading &&
                <React.Fragment>
                    <DialogTitle id="form-dialog-title">Incluir usuário</DialogTitle>
                    {this.renderForm()}
                </React.Fragment>
                }

            </Dialog>
        );
    }

    renderForm() {
        const {classes, handleSubmit, pristine, submitting, reset} = this.props;
        return (
            <DialogContent>
                <Divider/>
                <form className={classes.container} onSubmit={handleSubmit((values) => this.createUser(values))}>
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <Field name="name" component={InputText} label="Nome"/>
                        </Grid>
                        <Grid item xs>
                            <Field name="email" component={InputText} label="E-mail"/>
                        </Grid>
                    </Grid>
                    <DialogActions>
                        <Button
                            onClick={() => reset() && this.props.handleClose(null)}
                            color="default"
                        >
                            Fechar
                        </Button>
                        <Button
                            type="submit"
                            disabled={pristine || submitting}
                            variant="contained"
                            color="primary"
                            className={classes.button}
                        >
                            Criar usuário
                        </Button>
                    </DialogActions>
                </form>
            </DialogContent>
        );
    }
}


CreateUser.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withStyles(styles),
    connect(null, {})
)(reduxForm({
    form: "users",
    enableReinitialize: true
})(withSnackbar(CreateUser)));
