import React from "react";
import { Field, FieldArray, reduxForm } from "redux-form";
import { compose } from "recompose";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Comentarios from "../comentarios";
import { withStyles } from "@material-ui/core/styles";
import TimePicker from "../../commons/TimePicker";
import DatePicker from "../../commons/DatePicker";
import InputText from "../../commons/InputText";
import Select from "../../commons/Select";
import { withSnackbar } from "notistack";
import styles from "../styles";
import * as config from "./config";
import {
  atualizarAtendimento,
  criarAtendimento,
  getProcessos,
  setAtendimento,
  setEscritorioAdvocacia,
  SITUACAO_ATENDIMENTO_PENDENTE_REALIZACAO,
} from "../../../actions/juridico";
import AsyncAutoCompleteSelect from "../../commons/AsyncAutoComplete";
import RadioButton from "../../commons/RadioButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import UploadArquivosAtendimento from "./uploadArquivosAtendimento";
import _ from "lodash";

class AtendimentoForm extends React.Component {
  state = { anexos: [], atendimentosPendentes: [] };

  constructor(props) {
    super(props);
    this.save = this.save.bind(this);
    this.validarAgenda = this.validarAgenda.bind(this);
  }

  componentDidMount() {
    this.props.getProcessos();
    if (this.props.atendimento && this.props.atendimento.anexos) {
      this.setState({ anexos: this.props.atendimento.anexos });
    }
  }

  save(atendimento) {
    const { filiados, escritoriosAdvocacia, authUser } = this.props;
    atendimento.escritorio = escritoriosAdvocacia.find(
      (e) => e.nome === atendimento.escritorio
    );
    atendimento.anexos = this.state.anexos;
    atendimento.agendadoPor = authUser.email
    atendimento.data_cadastro = new Date().getTime()
    if (atendimento.id) {
      this.props.atualizarAtendimento(atendimento, filiados, () =>
        this.successHandler(true)
      );
    } else {
      this.props.criarAtendimento(atendimento, filiados, () =>
        this.successHandler(true)
      );
    }

    this.props.setAtendimento(null);
    this.props.reset();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let atendimentosPendentes = [];
    if (
      this.props.atendimentos &&
      prevProps.atendimentos !== this.props.atendimentos
    ) {
      Object.keys(this.props.atendimentos).forEach((escritorio) => {
        atendimentosPendentes.push(
          ...Object.values(this.props.atendimentos[escritorio]).filter(
            (a) => a.situacao === SITUACAO_ATENDIMENTO_PENDENTE_REALIZACAO
          )
        );
      });
      this.setState({ ...this.state, atendimentosPendentes });
    }
    if (
      this.props.atendimento &&
      prevProps.atendimento !== this.props.atendimento
    ) {
      atendimentosPendentes = atendimentosPendentes.filter(
        (a) => a.id !== this.props.atendimento.id
      );
      this.setState({ ...this.state, atendimentosPendentes });
    }
  }

  successHandler(reload) {
    const { handleClose, enqueueSnackbar } = this.props;
    enqueueSnackbar("Atendimento salvo com sucesso!", {
      variant: "success",
    });
    this.props.reset();
    handleClose(reload);
  }

  errorHandler() {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar("Erro ao salvar interação.", {
      variant: "error",
    });
  }

  validarAgenda(horaFim, values) {
    if (
      this.state.atendimentosPendentes &&
      horaFim &&
      values.data &&
      values.horaInicio
    ) {
      const atendimentosPendentesMesmaData =
        this.state.atendimentosPendentes.filter((a) => {
          const dataAtendimento = new Date(a.data);
          const dataForm = new Date(values.data);
          dataAtendimento.setHours(0, 0, 0, 0);
          dataForm.setHours(0, 0, 0, 0);
          return (
            values.escritorio &&
            a.escritorio &&
            a.escritorio.nome === values.escritorio &&
            dataAtendimento.getTime() === dataForm.getTime()
          );
        });

      if (!atendimentosPendentesMesmaData) {
        return undefined;
      }

      if (
        atendimentosPendentesMesmaData.some((a) => {
          const horaInicioAtendimento = new Date(a.horaInicio);
          const horaFimAtendimento = new Date(a.horaFim);
          const horaInicioForm = new Date(values.horaInicio);
          const horaFimForm = new Date(horaFim);

          horaInicioAtendimento.setFullYear(1970, 1, 1);
          horaFimAtendimento.setFullYear(1970, 1, 1);
          horaInicioForm.setFullYear(1970, 1, 1);
          horaFimForm.setFullYear(1970, 1, 1);

          return (
            (horaInicioAtendimento.getTime() <= horaInicioForm.getTime() &&
              horaInicioForm.getTime() <= horaFimAtendimento.getTime()) || // b starts in a
            (horaInicioAtendimento.getTime() <= horaFimForm.getTime() &&
              horaFimForm.getTime() <= horaFimAtendimento.getTime()) || // b ends in a
            (horaInicioForm.getTime() < horaInicioAtendimento.getTime() &&
              horaFimAtendimento.getTime() < horaFimForm.getTime()) ||
            (horaInicioForm.getTime() <= horaInicioAtendimento.getTime() &&
              horaFimForm.getTime() >= horaFimAtendimento.getTime())
          );
        })
      ) {
        return "Horário conflitante";
      }
    }
    return undefined;
  }

  render() {
    const {
      handleSubmit,
      invalid,
      submitting,
      reset,
      filiados,
      processos,
      authUser,
    } = this.props;
    const escritorio =
      this.props.atendimentoForm &&
      this.props.atendimentoForm.values &&
      this.props.atendimentoForm.values.escritorio;
    const processoEmCurso =
      this.props.atendimentoForm &&
      this.props.atendimentoForm.values &&
      this.props.atendimentoForm.values.processoEmCurso;

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose.bind(this, null)}
        fullWidth
        maxWidth="md"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Assessoria jurídica - {this.props.acao} - atendimento
        </DialogTitle>
        <form onSubmit={handleSubmit(this.save)}>
          <DialogContent>
            <Grid
              container
              spacing={2}
              hidden={false}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item sm={12}>
                <Field name="escritorio" component={Select} label="Escritório">
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  {this.props.escritoriosAdvocacia.map((t) => (
                    <MenuItem key={t.nome} value={t.nome}>
                      {t.nome}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
            </Grid>
            {escritorio && (
              <Grid
                container
                spacing={2}
                hidden={false}
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                <Grid item sm={4}>
                  <Field name="data" component={DatePicker} label="Data" />
                </Grid>
                <Grid item sm={4}>
                  <Field
                    name="horaInicio"
                    component={TimePicker}
                    label="Horario Início"
                  />
                </Grid>
                <Grid item sm={4}>
                  <Field
                    name="horaFim"
                    component={TimePicker}
                    label="Horario Fim"
                    // validate={this.validarAgenda}
                  />
                </Grid>
              </Grid>
            )}
            <Grid
              container
              spacing={2}
              hidden={false}
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Grid item sm={4}>
                <Field
                  name="tipoAtendimento"
                  component={Select}
                  label="Tipo de atendimento"
                >
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  {config.tiposAtendimento.map((t) => (
                    <MenuItem key={t} value={t}>
                      {t}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item sm={8}>
                <Field
                  defaultOptions={[]}
                  isMulti={true}
                  loadOptions={(input) => {
                    if (input.length < 3) {
                      return Promise.resolve([]);
                    }
                    return Promise.resolve(
                      filiados
                        .filter(
                          (f) =>
                            f.nome_completo &&
                            f.nome_completo
                              .toLowerCase()
                              .includes(input.toLowerCase()) &&
                            _.isEmpty(_.trim(f.data_desfiliacao))
                        )
                        .map((f) => ({ label: f.nome_completo, value: f.id }))
                        .slice(0, 100)
                    );
                  }}
                  name="filiados"
                  component={AsyncAutoCompleteSelect}
                  label="Filiados"
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              hidden={false}
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Grid item sm={4}>
                <Field
                  name="processoEmCurso"
                  component={RadioButton}
                  label="Relacionado a processos ou procedimentos?"
                >
                  <FormControlLabel
                    value="Sim"
                    control={<Radio />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value="Não"
                    control={<Radio />}
                    label="Não"
                  />
                </Field>
              </Grid>
              {processoEmCurso === "Sim" && (
                <Grid item sm={8}>
                  <Field
                    defaultOptions={[]}
                    isMulti={true}
                    loadOptions={(input) => {
                      return Promise.resolve(
                        processos
                          .filter(
                            (p) =>
                              (p.numeroProcesso &&
                                p.numeroProcesso
                                  .toLowerCase()
                                  .includes(input.toLowerCase())) ||
                              (p.tipo &&
                                p.tipo
                                  .toLowerCase()
                                  .includes(input.toLowerCase())) ||
                              (_.size(p.filiados) &&
                                p.filiados
                                  .map((it) => it.nome_completo)
                                  .join(" ")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())) ||
                              (p.tribunalOrgao &&
                                p.tribunalOrgao
                                  .toLowerCase()
                                  .includes(input.toLowerCase()))
                          )
                          .map((p) => ({
                            label:
                              p.numeroProcesso +
                              " - " +
                              p.tipo +
                              " - " +
                              p.tribunalOrgao,
                            value: p,
                          }))
                          .slice(0, 100)
                      );
                    }}
                    name="processos"
                    component={AsyncAutoCompleteSelect}
                    label="Processos e procedimentos"
                  />
                </Grid>
              )}
            </Grid>

            <Grid
              container
              spacing={2}
              hidden={false}
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Grid item sm={12}>
                <Field
                  name="descricao"
                  component={InputText}
                  multiline={true}
                  label="Descrição do assunto"
                />
              </Grid>

              <Grid item sm={12}>
                <UploadArquivosAtendimento
                  onChange={(anexos) => {
                    this.setState({ anexos });
                  }}
                  anexos={
                    this.props.atendimento && this.props.atendimento.anexos
                  }
                />
              </Grid>
              <Grid item sm={12}>
                <Typography variant="h6">Comentários registrados</Typography>
                <FieldArray
                  name="comentarios"
                  component={Comentarios}
                  email={authUser?.email}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                setAtendimento(null) && reset() && this.props.handleClose()
              }
              color="default"
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={submitting || invalid}
              color="primary"
            >
              Salvar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

AtendimentoForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({
  form: { atendimentoForm },
  filiado: { filiado, filiadoId, filiados },
  juridico: {
    escritorioAdvocacia,
    escritoriosAdvocacia,
    atendimentos,
    processos,
    atendimento,
  },
}) => ({
  initialValues: atendimento
    ? {
        ...atendimento,
        filiados: atendimento.filiados
          ? atendimento.filiados.map((f) => ({
              label: f.nome_completo,
              value: f.id,
            }))
          : undefined,
        processos: atendimento.processos
          ? atendimento.processos.map((p) => ({
              label:
                p.numeroProcesso + " - " + p.tipo + " - " + p.tribunalOrgao,
              value: p,
            }))
          : undefined,
        escritorio: atendimento.escritorio.nome,
      }
    : undefined,
  atendimento,
  atendimentoForm,
  processos,
  atendimentos,
  escritorioAdvocacia,
  escritoriosAdvocacia,
  filiado,
  filiadoId,
  filiados,
});

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "data",
    "horaInicio",
    "horaFim",
    "tipoAtendimento",
    "filiados",
    "processoEmCurso",
    "escritorio",
  ];

  requiredFields.forEach((field) => {
    if (
      field === "processoEmCurso" &&
      values[field] === "Sim" &&
      _.isEmpty(values["processos"])
    ) {
      errors["processos"] = " Obrigatório";
    }
    if (
      !values[field] ||
      (Array.isArray(values[field]) && values[field].length === 0)
    ) {
      errors[field] = " Obrigatório";
    }
  });

  const horaInicio = values["horaInicio"];
  const horaFim = values["horaFim"];

  if (typeof horaInicio === "number" && typeof horaFim === "number") {
    const horaInicioDate = new Date(horaInicio);
    const horaFimDate = new Date(horaFim);
    horaInicioDate.setFullYear(1970, 1, 1);
    horaFimDate.setFullYear(1970, 1, 1);
    if (horaFimDate.getTime() <= horaInicioDate.getTime()) {
      errors["horaFim"] = " Deve ser maior que horario início";
    }
  }

  return errors;
};

export default compose(
  connect(mapStateToProps, {
    criarAtendimento,
    setAtendimento,
    atualizarAtendimento,
    setEscritorioAdvocacia,
    getProcessos,
  }),
  withStyles(styles)
)(
  reduxForm({
    form: "atendimentoForm",
    validate,
    enableReinitialize: true,
  })(withSnackbar(AtendimentoForm))
);
