import React from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { compose } from "recompose";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import {
  getDocumentosTransparencia,
  setDocumentoTransparenciaId,
  setDocumentoTransparencia,
  saveDocumentoTransparencia,
  deleteDocumentoTransparencia
} from "../../../actions/documento_transparencia"
import tableI18n from "../../TableLocalization";
import DocumentoTransparenciaForm from "./documento_transparencia";
import ConfirmDialog from "../../commons/ConfirmDialog";
import SindilegisBar from "../../commons/SindilegisBar";
import { storage } from "../../../firebase";
import styles from "./styles";
import Loading from "../../commons/Loading";
import * as roles from "../../../constants/roles";
import * as routes from "../../../constants/routes";
import { formataData } from "../../Financial/util";
import ButtonComponent from "../../commons/ButtonComponent";
import { getRoutes } from '../../commons/util'
import MenuButton from '../../commons/MenuButton'

class DocumentosTransparencia extends React.Component {
  state = {
    open: false,
    confirmOpen: false,
    confirmAction: Function,
    confirmMessage: "",
  };


  componentDidMount() {
    this.props.getDocumentosTransparencia()
  }

  handleDocumentoClickOpen = () => {
    this.setState({ open: true });
  };

  handleDocumentoClose = (reload) => {
    this.setState({ open: false });
    this.props.setDocumentoTransparencia(null);
    this.props.setDocumentoTransparenciaId(null);
    if (reload) {
      this.reloadDocumentos();
    }
  };

  handleConfirmOpen = (confirmAction, confirmMessage) => {
    this.setState({ confirmOpen: true, confirmAction, confirmMessage });
  };

  handleConfirmClose = (confirm) => {
    if (confirm) {
      this.state.confirmAction();
      this.setState({ confirmOpen: false });
    } else {
      this.setState({ confirmOpen: false });
    }
  };

  reloadDocumentos() {
    this.props.getDocumentosTransparencia();
  }

  render() {
    const { classes, documentosTransparencia, loading, authUser, location } = this.props;
    const canEdit = _.includes(authUser.roles, roles.ADMIN_FINANCEIRO);
    const currentPath = location.pathname
    const buttonsRoutes = getRoutes(routes, currentPath)

    return (
      <div>
        <SindilegisBar />
        <Loading loading={loading} />
        <Container maxWidth="xl">
        <MenuButton buttonsRoutes={buttonsRoutes} location={location} />
          {canEdit && (
            <Grid
              container
              item
              md={12}
              justifyContent="flex-end"
              spacing={1}
              className={classes.areaOperacoes}
            >
              <Grid item>
                <ButtonComponent
                  variant="insert"
                  onClick={() =>
                    this.handleDocumentoClickOpen()
                  }
                >
                  Inserir Documento
                </ButtonComponent>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <Typography variant="h6" className={classes.title} />
            <div className={classes.demo}>
              <MaterialTable
                columns={[
                  {
                    title: "Nome do arquivo",
                    field: "nome",
                    render: (row) => (
                      <Link
                        component="button"
                        variant="body2"
                        onClick={() => storage.downloadAnexo(row.anexo.url)}
                      >
                        {row.nome}
                      </Link>
                    ),
                  },
                  {
                    title: "Data de carregamento",
                    field: "data_carregamento",
                    render: (row) => formataData(row.data_carregamento),
                  },
                  { title: "Usuário", field: "email" },
                ]}
                data={documentosTransparencia}
                title="Portal da Transparência"
                actions={[
                  {
                    icon: "edit",
                    tooltip: "Editar",
                    disabled: !canEdit,
                    onClick: (event, row) =>
                      this.props.setDocumentoTransparenciaId(row.id) &&
                      this.handleDocumentoClickOpen(),
                  },
                  {
                    icon: "delete",
                    tooltip: "Excluir",
                    disabled: !canEdit,
                    onClick: (event, row) =>
                      this.handleConfirmOpen(
                        () => this.removeDocumento(row.id),
                        `Confirma a exclusão permanente do ${row.nome}?`
                      ),
                  },
                ]}
                options={{
                  actionsColumnIndex: -1,
                }}
                localization={tableI18n}
              />
            </div>
          </Grid>
          <DocumentoTransparenciaForm
            open={this.state.open}
            handleClose={this.handleDocumentoClose}
            email={authUser.email}
          />
          <ConfirmDialog
            open={this.state.confirmOpen}
            message={this.state.confirmMessage}
            onClose={this.handleConfirmClose}
          />
        </Container>
      </div>
    );
  }

  removeDocumento(id) {
    this.props.deleteDocumentoTransparencia(id);
  }
}

const mapStateToProps = ({ documento_transparencia }) =>
  (documento_transparencia);

DocumentosTransparencia.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getDocumentosTransparencia,
    setDocumentoTransparenciaId,
    setDocumentoTransparencia,
    saveDocumentoTransparencia,
    deleteDocumentoTransparencia,
  })
)(withRouter(withSnackbar(DocumentosTransparencia)));
