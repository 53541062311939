import _ from "lodash";
import { USER_SET, USERS_REQUEST, USER_ID_SET, USERS_SET } from "./types";
import { db } from "../firebase";

export const getUsers = () => {
  return (dispatch) => {
    dispatch({ type: USERS_REQUEST });
    db.onceGetUsers().then((snapshot) => {
      let users = snapshot.val();
      Object.keys(users).map((id) => (users[id].id = id));
      users = _.values(users);
      dispatch({ type: USERS_SET, users });
    });
  };
};

export const getUsersAndRoles = () => {
  return (dispatch) => {
    dispatch({ type: USERS_REQUEST });
    db.onceGetUsers().then((snapshot) => {
      db.onceGetUserRoles().then((snapRoles) => {
        const roles = snapRoles.val();
        let users = snapshot.val();
        Object.keys(users).forEach((id) => {
          users[id].id = id;
          if (roles[id]) {
            users[id].roles = _.keys(roles[id]);
          }
        });
        users = _.values(users);
        dispatch({ type: USERS_SET, users });
      });
    });
  };
};

export const saveUser = (id, data, callback) => {
  return (dispatch) => {
    db.doSaveUser(id, data).then(() => {
      callback && callback();
    });
  };
};

export const getUser = (id) => {
  return (dispatch) => {
    db.onceGetUser(id).then((snapshot) => {
      const user = snapshot.val();
      dispatch({ type: USER_SET, user });
    });
  };
};

export const setUserId = (userId) => (dispatch) =>
  dispatch({ type: USER_ID_SET, userId });
