import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import styles from "./styles";
import MaterialTable from "material-table";
import { withRouter } from "react-router-dom";
import { SITUACAO_PROCESSO_EXCLUIDO, getProcessos } from "../../actions/juridico";
import { formatarValor } from "../commons/util";
import tableI18n from "../TableLocalization";

function converterParaNumber(valor) {
  return valor ? Number(valor.replaceAll(".", "").replaceAll(",", ".")) : 0;
}

class ProcessoFiliadoList extends React.Component {
  render() {
    const { classes, processos, filiadoId } = this.props;
    var processoFiltrados = _.filter(
      processos,
      (item) =>
        item.filiados &&
        _.size(_.filter(item.filiados, (f) => f.id === filiadoId)) > 0 && 
        item.situacao !== SITUACAO_PROCESSO_EXCLUIDO
    );
    processoFiltrados = processoFiltrados.map((p) => ({
      ...p,
      empresas: p.filiados
        ? _.uniq(
            p.filiados
              .filter((f) => f.empresa != null && f.empresa.length > 0)
              .map((f) => f.empresa)
          ).join(", ")
        : "",
      ano: p.dataProtocolo ? new Date(p.dataProtocolo).getFullYear() : "",
      valorCausa: p.valorCausa,
      nomeEscritorio: p.escritorio ? p.escritorio.nome : "",
    }));
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        fullWidth
        maxWidth="xl"
        aria-labelledby="processo-filiado-dialog-title"
      >
        <DialogTitle id="processo-filiado-dialog-title">Processos</DialogTitle>
        <DialogContent>
          <div className={classes.demo}>
            <MaterialTable
              columns={[
                {
                  title: "Tipo processo",
                  field: "tipo",
                },
                {
                  title: "Número processo",
                  field: "numeroProcesso",
                },
                {
                  title: "Ano",
                  field: "ano",
                },
                {
                  title: "Escritório",
                  field: "nomeEscritorio",
                },
                {
                  title: "Tribunal/Orgão",
                  field: "tribunalOrgao",
                },
                {
                  title: "Situação",
                  field: "situacao",
                },
                {
                  title: "Valor",
                  field: "valorCausa",
                  render: (row) => (
                    <span>
                      {formatarValor(converterParaNumber(row.valorCausa))}
                    </span>
                  ),
                  customSort: (a, b) =>
                    converterParaNumber(a.valorCausa) -
                    converterParaNumber(b.valorCausa),
                },
              ]}
              data={processoFiltrados}
              title={"Processos"}
              options={{
                actionsColumnIndex: -1,
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100],
                exportButton: true,
                exportAllData: true,
              }}
              localization={tableI18n}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.handleClose(null)} color="default">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ filiado, juridico: { processos } }) => ({
  filiadoId: filiado.filiadoId,
  processos,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getProcessos })
)(withRouter(ProcessoFiliadoList));
