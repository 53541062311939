import { SUPRIMENTOS_SET, SUPRIMENTO_UPDATED, SUPRIMENTO_ID_SET, SUPRIMENTO_SET, DELETE_SUPRIMENTO } from './types';
import { db } from "../firebase";
import { reset } from "redux-form";
import _ from "lodash";

export const getSuprimentos = () => {
     return dispatch => {
          db.onceGetSuprimentos().then((snapshot) => {
               let suprimentos = snapshot.val()
               if (suprimentos) {
                    Object.keys(suprimentos).map(id => (suprimentos[id].id = id));
                    suprimentos = _.values(suprimentos);
               } else {
                    suprimentos = [];
               }
               dispatch({ type: SUPRIMENTOS_SET, suprimentos });
          });
     }
}

export const criarSuprimento = (data, callback) => {
     return dispatch => {
          db.doCreateSuprimento(data).then((ref) => {
               callback && callback(ref.key);
          });
     };
}

export const saveSuprimento = (id, data, callback) => {
     return dispatch => {
          db.doUpdateSuprimento(id, data).then(() => {
               dispatch({ type: SUPRIMENTO_UPDATED, suprimento: { id, ...data } });
               callback && callback();
          });
     };
}


export const setSuprimentoId = (suprimentoId) => dispatch => {
     dispatch({ type: SUPRIMENTO_ID_SET, suprimentoId })
     dispatch(reset('suprimento'))
}

export const setSuprimento = (suprimento) => dispatch => dispatch({ type: SUPRIMENTO_SET, suprimento })

export const getSuprimento = (id) => dispatch => {
     db.onceGetSuprimento(id).then(snapshot => {
          const suprimento = snapshot.val();
          dispatch({ type: SUPRIMENTO_SET, suprimento })
     });
}

export const deleteSuprimento = (id) => {
     return dispatch => {
          db.doDeleteSuprimento(id).then(() => {
               dispatch({ type: DELETE_SUPRIMENTO, payload: { id } });
          })
     }
}