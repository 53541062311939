import { emphasize } from "@material-ui/core/styles/colorManipulator";

export default theme => ({
  input: {
    display: "flex",
    padding: 0,
    height: 'auto',
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden"
  },
  chip: {
    margin: `${theme.spacing(1) / 2}px ${theme.spacing(1) / 4}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1) * 2}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing(1) * 2
  }
});

  // chip: {
  //   margin: theme.spacing(0.5, 0.25),
  // },
  // noOptionsMessage: {
  //   padding: theme.spacing(1, 2),
  // },
  // placeholder: {
  //   position: 'absolute',
  //   left: 2,
  //   bottom: 6,
  //   fontSize: 16,
  // },
  // divider: {
  //   height: theme.spacing(2),
  // },
