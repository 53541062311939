import { FILIADOS_PROCESSO_SET, FILIADO_PROCESSO_SET, FILIADO_PROCESSO_ID_SET, FILIADOS_PROCESSO_REQUEST, FILIADO_PROCESSO_UPDATED,DELETE_FILIADO_PROCESSO} from "../actions/types";

const INITIAL_STATE = {
  filadosProcesso: [],
  filiadoProcesso: null,
  filiadoProcessoId: null,
  historico: [],
  loading: false
};

function filiadosProcessoReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FILIADOS_PROCESSO_SET: {
      return { ...state, filiadosProcesso: action.filiadosProcesso, loading: false }
    }
    case FILIADOS_PROCESSO_REQUEST: {
      return { ...state, loading: true }
    }
    case FILIADO_PROCESSO_SET: {
      return { ...state, filiadoProcesso: action.filiadoProcesso, loading: false }
    }
    case FILIADO_PROCESSO_ID_SET: {
      return { ...state, filiadoProcessoId: action.filiadoProcessoId }
    }
    case FILIADO_PROCESSO_UPDATED: {
      const { filiadosProcesso } = state
      const { id, data } = action.payload
      const index = filiadosProcesso.findIndex(f => f.id === id)
      const filiadoProcesso = { ...filiadosProcesso[index], ...data }
      filiadosProcesso.splice(index, 1, filiadoProcesso)
      return { ...state, filiadosProcesso: [...filiadosProcesso] }
    }
    case DELETE_FILIADO_PROCESSO: {
      const { filiadosProcesso } = state;
      const {filiadoId, processoId} = action.payload;
      const index = filiadosProcesso.findIndex(f => f.filiadoId === filiadoId && f.processoId === processoId);
      if (index !== -1){filiadosProcesso.splice(index, 1)}
      return { ...state, filiadosProcesso: [...filiadosProcesso] }
    }
    default:
      return state;
  }
}

export default filiadosProcessoReducer;