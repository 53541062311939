import _ from "lodash";
import { ITENS_RECEITA_SET, ITEM_RECEITA_SET, ITEM_RECEITA_ID_SET, ITENS_RECEITA_REQUEST, ITEM_RECEITA_UPDATED, DELETE_ITEM_RECEITA } from './types';
import { db } from "../firebase";

export const saveItemReceita = (id, data, callback) => {
     return dispatch => {
          db.doSaveItemReceita(id, data).then(() => {
               dispatch({ type: ITEM_RECEITA_UPDATED, payload: { id, data } });
               callback && callback();
          });
     };
}

export const criarItemReceita = (data, callback) => {
     return dispatch => {
          db.doCreateItemReceita(data).then(() => {
               callback && callback();
          });
     };
}

export const getItensReceita = () => {
     return dispatch => {
          dispatch({ type: ITENS_RECEITA_REQUEST });
          db.onceGetItensReceita().then((snapshot) => {
               let items = snapshot.val()
               if (items) {
                    Object.keys(items).map(id => {
                         items[id].id = id
                         return items[id]
                    });
                    items = _.orderBy(_.values(items), [b => b.nome.toLowerCase()], ['asc']);
               } else {
                    items = []
               }

               dispatch({ type: ITENS_RECEITA_SET, itens_receita: items });
          })
     }
}

export const getItemReceita = (id) => {
     return dispatch => {
          db.onceGetItemReceita(id).then((snapshot) => {
               const item_receita = snapshot.val()
               dispatch({ type: ITEM_RECEITA_SET, item_receita })
          });
     }
}

export const deleteItemReceita = (id) => {
     return dispatch => {
          db.doDeleteItemReceita(id).then(() => {
               dispatch({ type: DELETE_ITEM_RECEITA, payload: { id } });
          })
     }
}

export const setItemReceita = (item_receita) => dispatch => dispatch({ type: ITEM_RECEITA_SET, item_receita })

export const setItemReceitaId = (item_receita_id) => dispatch => dispatch({ type: ITEM_RECEITA_ID_SET, item_receita_id })