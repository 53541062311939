import { FUNCIONARIOS_SET, FUNCIONARIO_SET, FUNCIONARIO_ID_SET, FUNCIONARIOS_REQUEST, FUNCIONARIO_UPDATED, DELETE_FUNCIONARIO } from "../actions/types";

const INITIAL_STATE = {
  funcionarios: [],
  funcionario: null,
  funcionarioId: null,
  historico: [],
  loading: false
};

function funcionarioReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FUNCIONARIOS_SET: {
      return { ...state, funcionarios: action.funcionarios, loading: false }
    }
    case FUNCIONARIOS_REQUEST: {
      return { ...state, loading: true }
    }
    case FUNCIONARIO_SET: {
      return { ...state, funcionario: action.funcionario, loading: false }
    }
    case FUNCIONARIO_ID_SET: {
      return { ...state, funcionarioId: action.funcionarioId }
    }
    case FUNCIONARIO_UPDATED: {
      const { funcionarios } = state
      const { id, data } = action.payload
      const index = funcionarios.findIndex(f => f.id === id)
      const funcionario = { ...funcionarios[index], ...data }
      funcionarios.splice(index, 1, funcionario)
      return { ...state, funcionarios: [...funcionarios] }
    }
    case DELETE_FUNCIONARIO: {
      const { funcionarios } = state
      const index = funcionarios.findIndex(f => f.id === action.payload.id)
      funcionarios.splice(index, 1)
      return { ...state, funcionarios: [...funcionarios] }
    }
    default:
      return state;
  }
}

export default funcionarioReducer;
