import React from "react";
import { Button as MuiButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const styles = {
  primary: {
    textTransform: "none",
    backgroundColor: "#78CDD1",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#c4eaf5",
    },
  },
  secondary: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 10,
    textTransform: "none",
    backgroundColor: "#f68975",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#F58A75",
    },
  },
  default: {
    margin: 10,
    textTransform: "none",
    backgroundColor: "#e0e0e0",
    color: "#000",
    "&:hover": {
      backgroundColor: "#bdbdbd",
    },
  },
  insert: {
    variant: 'contained',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 10,
    textTransform: "none",
    backgroundColor: "#34a8cf",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#79ccd0",
    },
  },
  menu: {
    display: "block",
    textAlign: "left",
    textTransform: "none",
    backgroundColor: "transparent",
    color: "#34a8cf",
    border: "none",
    padding: "0",
    margin: "0",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
};

const ButtonComponent = ({
  classes,
  variant = "default",
  size = "small",
  onClick,
  to,
  children,
  disabled = false,
}) => {
  const buttonClass = classes[variant] || classes.default;

  const buttonProps = {
    className: buttonClass,
    variant: variant === "menu" ? "text" : "contained",
    size,
    onClick: disabled ? null : onClick,
    disabled,
  };

  if (to) {
    return (
      <MuiButton component={Link} to={to} {...buttonProps}>
        {children}
      </MuiButton>
    );
  }

  return <MuiButton {...buttonProps}>{children}</MuiButton>;
};

export default withStyles(styles)(ButtonComponent);
