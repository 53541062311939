import {
  POLITICO_SINDICAL_TEMAS_SET, 
  POLITICO_SINDICAL_TEMA_SET, 
  POLITICO_SINDICAL_TEMA_STEP_SET, 
  POLITICO_SINDICAL_TEMA_ID_SET, 
  POLITICO_SINDICAL_TEMAS_REQUEST, 
  POLITICO_SINDICAL_TEMA_UPDATED, 
  POLITICO_SINDICAL_DELETE_TEMA,
  POLITICO_SINDICAL_TEMA_DESCARTADO,
  POLITICO_SINDICAL_TEMAS_DESCARTADOS,
  POLITICO_SINDICAL_TOTAL_TEMAS_SITUACAO_SET,
  POLITICO_SINDICAL_ITEM_DESTAQUE_TEMA_ID_SET,
  POLITICO_SINDICAL_TOTAL_TEMAS_SITUACAO_DESCARTADO,
  POLITICO_SINDICAL_DADOS_IDENTIFICACAO_PROCESSO_SET,
  POLITICO_SINDICAL_DADOS_IDENTIFICACAO_PROCESSO_DESCARTADO
} from "../actions/types";

const INITIAL_STATE = {
    temas: [],
    situacoes: {},
    tema: undefined,
    temaId: null,
    loading: false,
    page: undefined
};

function politicoSindicalReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case POLITICO_SINDICAL_TEMAS_SET: {
      return { ...state, temas: action.temas, loading: false }
    }
    case POLITICO_SINDICAL_TOTAL_TEMAS_SITUACAO_SET: {
      return { ...state, situacoes: action.situacoes, loading: false }
    }
    case POLITICO_SINDICAL_TEMAS_REQUEST: {
      return {  ...state, loading: true }
    }
    case POLITICO_SINDICAL_TEMA_STEP_SET: {
      return {  ...state, page: action.pageId }
    }
    case POLITICO_SINDICAL_TEMA_UPDATED: {
      const { temas } = state
      const { id, data } = action.payload
      const index = temas.findIndex(f => f.id === id)
      const tema = { ...temas[index], ...data }
      temas.splice(index, 1, tema)
      return { ...state, temas: [...temas] }
    }
    case POLITICO_SINDICAL_TEMA_SET: {
      return { ...state, tema: action.tema, loading: false }
    }
    case POLITICO_SINDICAL_TEMA_ID_SET: {
      return { ...state, temaId: action.temaId }
    }
    case POLITICO_SINDICAL_ITEM_DESTAQUE_TEMA_ID_SET: {
      return { ...state, itemDestaqueId: action.itemDestaqueId }
    }
    case POLITICO_SINDICAL_DADOS_IDENTIFICACAO_PROCESSO_SET: {
      return { ...state, processos: action.processos }
    }
    case POLITICO_SINDICAL_DELETE_TEMA: {
      const { temas } = state
      const index = temas.findIndex(f => f.id === action.payload.id)
      temas.splice(index, 1)
      return { ...state, temas: [...temas] }
    }
    case POLITICO_SINDICAL_TEMAS_DESCARTADOS: {
      return { ...state, temas: [] }
    }
    case POLITICO_SINDICAL_TOTAL_TEMAS_SITUACAO_DESCARTADO: {
      return { ...state, situacoes: {} }
    }
    case POLITICO_SINDICAL_DADOS_IDENTIFICACAO_PROCESSO_DESCARTADO: {
      return { ...state, processos: undefined }
    }
    case POLITICO_SINDICAL_TEMA_DESCARTADO: {
      return { ...state, tema: undefined }
    }
    default:
      return state;
  }
}

export default politicoSindicalReducer
