import React, { useEffect, useState } from "react";
import { withSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { getInteracoes, setInteracaoId, deleteInteracao } from "../../actions/interacao";
import { getFiliados, setFiliadoId } from "../../actions/filiado";
import { formataData } from "../Financial/util";
import tableI18n from "../TableLocalization";
import InteracaoForm from "./interacao";
import Importacao from "./importacao";
import { ITENS_INTERACAO } from "../../constants/routes";
import ConfirmDialog from "../commons/ConfirmDialog";
import SindilegisBar from "../commons/SindilegisBar";
import Loading from "../commons/Loading";
import CustomCard from "../commons/CustomCard";
import styles from "./styles";
import { useHistory } from "react-router-dom";
import { reset } from "redux-form";

const useStyles = makeStyles(styles);

const Interacoes = ({ enqueueSnackbar }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { filiados, loading, interacoes } = useSelector(state => ({
    filiados: state.filiado.filiados,
    loading: state.filiado.loading,
    interacoes: state.interacao.interacoes,
  }));

  const interacao = useSelector(state => state.interacao.interacao);
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [isImportacaoOpen, setIsImportacaoOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(getFiliados());
        dispatch(getInteracoes());
      } catch (error) {
        enqueueSnackbar("Erro ao carregar dados.", { variant: "error" });
      }
    };
    fetchData();
  }, 

  [dispatch, enqueueSnackbar]);

  const handleInteracaoClickOpen = () => {
    setOpen(true);
  };

  const handleImportacaoClickOpen = () => {
    setIsImportacaoOpen(true);
  };

  const handleInteracaoClose = () => {
    setOpen(false);
    dispatch(getInteracoes())
    dispatch(reset("interacao"));
  };

  const handleImportacaoClose = (reload) => {
    if (reload) {
      reloadInteracoes();
    }
    setIsImportacaoOpen(false);
  };

  const handleConfirmOpen = (action, message) => {
    setConfirmOpen(true);
    setConfirmAction(() => action);
    setConfirmMessage(message);
  };

  const handleConfirmClose = (confirm) => {
    if (confirm && confirmAction) {
      confirmAction();
      enqueueSnackbar("Interação removida com sucesso!", { variant: "success" });
    }
    setConfirmOpen(false);
  };

  const reloadInteracoes = () => {
    dispatch(getInteracoes());
  };

  const removeInteracao = (id) => {
    dispatch(deleteInteracao(id));
  };

  const renderDetail = (filiado) => {
    const { id } = filiado;
    const itens = interacoes[id];
    if (itens) {
      return (
        <Grid container spacing={2} className={classes.detail}>
          {Object.keys(itens).map(key => {
            const item = itens[key];
            return (
              <CustomCard
                key={key}
                smSize={3}
                subheader={formataData(item.data)}
                cardContentHeight="150px"
                actionOptions={[
                  {
                    label: 'Editar',
                    action: () => {
                      dispatch(setInteracaoId(`${id}/${key}`));
                      handleInteracaoClickOpen();
                    },
                  },
                  {
                    label: 'Excluir',
                    action: () => handleConfirmOpen(
                      () => removeInteracao(`${id}/${key}`),
                      'Confirma a exclusão permanente da interação?'
                    )
                  }
                ]}
                dataFields={[
                  { label: 'Tipo', value: item?.tipo || '' },
                  { label: 'Descrição', value: item?.descricao || '' },
                  { label: 'Responsável', value: item?.responsavel?.label || 'Nenhum responsável atribuído' },
                  { label: 'Classificação', value: item?.classificacao || 'Nenhuma classificação definida' }
                ]}
              />
            );
          })}
        </Grid>
      );
    } else {
      return (
        <Grid container spacing={1} className={classes.detail} alignItems="center" justifyContent="center">
          <Typography variant="body1">Nenhuma interação cadastrada</Typography>
        </Grid>
      );
    }
  };

  return (
    <div className={classes.root}>
      <SindilegisBar />
      <Loading loading={loading} />
      <Paper className={classes.paper} elevation={1} hidden={loading}>
        <Grid container item md={12} justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              className={classes.button}
              color="default"
              onClick={() => history.push(ITENS_INTERACAO)}
            >Itens de interação</Button>
          </Grid>
          <Grid item>
            <Button
              className={classes.button}
              color="default"
              onClick={handleImportacaoClickOpen}
            >Importar</Button>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="h6" className={classes.title} />
          <div className={classes.demo}>
            <MaterialTable
              columns={[
                {
                  title: "Nome Completo",
                  field: "nome_completo",
                }
              ]}
              data={filiados}
              title="Interações"
              actions={[
                {
                  icon: "add",
                  tooltip: "Adicionar interação",
                  onClick: (event, row) => {
                    dispatch(setFiliadoId(row.id));
                    handleInteracaoClickOpen();
                  }
                }
              ]}
              options={{
                actionsColumnIndex: -1,
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100],
                detailPanelColumnAlignment: "left",
              }}
              localization={tableI18n}
              detailPanel={row => renderDetail(row)}
              onRowClick={(event, rowData, togglePanel) => togglePanel()}
            />
          </div>
        </Grid>
      </Paper>
      <InteracaoForm
        open={open}
        handleClose={handleInteracaoClose}
        initialValues={interacao}
      />
      <Importacao
        open={isImportacaoOpen}
        handleClose={handleImportacaoClose}
      />
      <ConfirmDialog
        open={confirmOpen}
        message={confirmMessage}
        onClose={handleConfirmClose}
      />
    </div>
  );
};

Interacoes.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(Interacoes);
