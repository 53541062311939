import React from "react";
import { Field, reduxForm } from "redux-form";
import { compose } from "recompose";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import InputText from "../commons/InputText";
import Select from "../commons/Select";
import { withSnackbar } from "notistack";
import styles from "./styles";
import { getEvento, criarEvento, saveEvento } from "../../actions/evento";
import { getUsers } from "../../actions/user";
import { cpfMask, cnpjMask } from "../commons/masks";
import DatePicker from "../commons/DatePicker";
import CheckboxGroup from "../commons/CheckboxGroup";
import MultiSelect from "../commons/MultiSelect";

class EventoForm extends React.Component {

  constructor(props) {
    super(props);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    this.props.getUsers();
  }

  componentWillReceiveProps({ eventoId }) {
    if (eventoId) {
    this.props.getEvento(eventoId);
  }
}

  save(evento) {
    const { eventoId } = this.props;
    if (eventoId) {
      this.props.saveEvento(eventoId, evento, () => this.successHandler())
    } else {
      this.props.criarEvento(evento, () => this.successHandler(true))
    }
  }

  successHandler(reload) {
    const { reset, handleClose, enqueueSnackbar } = this.props;
    enqueueSnackbar("Evento salvo com sucesso!", {
      variant: "success"
    });
    reset('evento')
    handleClose(reload);
  }

  errorHandler() {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar("Erro ao salvar evento.", {
      variant: "error"
    })
  }

  handleChangeTipo(tipo) {
    if (tipo === 'F') {
      this.setState({ mask: cpfMask })
    } else {
      this.setState({ mask: cnpjMask })
    }
  }

  render() {
    const { handleSubmit, pristine, submitting, reset } = this.props;

    const areas = [
      { label: 'Defesa', value: 'Defesa' },
      { label: 'Benefício', value: 'Benefício' },
      { label: 'Outra', value: 'Outra' }
    ];

    const publicos_alvo = [
      { label: 'Filiados CD', value: 'Filiados CD' },
      { label: 'Filiados SF', value: 'Filiados SF' },
      { label: 'Filiados TCU', value: 'Filiados TCU' },
      { label: 'Outras pessoas', value: 'Outras pessoas' }
    ];

    const tipos = ['Próprio', 'Patrocinado'];

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose.bind(this, null)}
        fullWidth
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Evento</DialogTitle>
        <form onSubmit={handleSubmit(this.save)}>
          <DialogContent>
            <Grid
              container
              spacing={2}
              alignItems="center"
            >
              <Grid item sm={2}>
                <Field name="tipo" component={Select} label="Tipo">
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  {tipos.map(t => <MenuItem key={t} value={t}>{t}</MenuItem>)}
                </Field>
              </Grid>
              <Grid item sm={6}>
                <Field
                  name="nome"
                  component={InputText}
                  label="Nome"
                />
              </Grid>
              <Grid item sm={4}>
                <CheckboxGroup name="areas" title="Área" options={areas} />
              </Grid>
              <Grid item sm={12}>
                <Field
                  name="descricao"
                  component={InputText}
                  label="Descrição"
                />
              </Grid>
              <Grid item sm={2}>
                <Field
                  name="data_inicio"
                  component={DatePicker}
                  label="Data início"
                />
              </Grid>
              <Grid item sm={2}>
                <Field
                  name="data_fim"
                  component={DatePicker}
                  label="Data fim"
                />
              </Grid>
              <Grid item sm={8}>
                <CheckboxGroup name="publicos_alvo" title="Público-alvo" options={publicos_alvo} />
              </Grid>
              <Grid item sm={4}>
                <Field
                  name="responsaveis"
                  options={this.props.users.filter(u => !_.isEmpty(u.displayName)).map(u => ({ label: u.displayName, value: u.displayName }))}
                  component={MultiSelect}
                  label="Responsáveis"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => reset() && this.props.handleClose()}
              color="default"
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={pristine || submitting}
              color="primary"
            >
              Salvar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

EventoForm.propTypes = {
  classes: PropTypes.object.isRequired
};

const validate = values => {
  const errors = {};
  const requiredFields = [
    "tipo",
    "nome",
  ];
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = " Obrigatório";
    }
  });
  return errors;
};

const mapStateToProps = ({
  evento: { evento, eventoId },
  userState: { users }
}) => ({
  evento: evento,
  eventoId: eventoId,
  users,
  initialValues: evento
});

export default compose(
  connect(
    mapStateToProps,
    { getEvento, criarEvento, saveEvento, getUsers }
  ),
  withStyles(styles)
)(
  reduxForm({
    form: "evento",
    validate,
    enableReinitialize: true,
  })(withSnackbar(EventoForm))
);
