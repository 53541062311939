import React from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import {
  getFuncionarios,
  setFuncionarioId,
  setFuncionario,
  saveFuncionario,
  deleteFuncionario,
} from "../../actions/funcionario";
import tableI18n from "../TableLocalization";
import FuncionarioForm from "./funcionario";
import ConfirmDialog from "../commons/ConfirmDialog";
import SindilegisBar from "../commons/SindilegisBar";
import styles from "./styles";
import Loading from "../commons/Loading";
import { removerMascaraCPF } from "../commons/masks";
import ButtonComponent from "../commons/ButtonComponent";

class Funcionarios extends React.Component {
  state = {
    open: false,
    confirmOpen: false,
    confirmAction: Function,
    confirmMessage: "",
  };

  componentDidMount() {
    this.props.getFuncionarios();
  }

  handleFuncionarioClickOpen = () => {
    this.setState({ open: true });
  };

  handleFuncionarioClose = (reload) => {
    this.setState({ open: false });
    this.props.setFuncionario(null);
    this.props.setFuncionarioId(null);
    if (reload) {
      this.reloadFuncionarios();
    }
  };

  handleConfirmOpen = (confirmAction, confirmMessage) => {
    this.setState({ confirmOpen: true, confirmAction, confirmMessage });
  };

  handleConfirmClose = (confirm) => {
    if (confirm) {
      this.state.confirmAction();
      this.setState({ confirmOpen: false });
    } else {
      this.setState({ confirmOpen: false });
    }
  };

  reloadFuncionarios() {
    this.props.getFuncionarios();
  }

  render() {
    const { classes, funcionarios, loading } = this.props;

    return (
      <div className={classes.root}>
        <SindilegisBar />
        <Loading loading={loading} />
        <Container maxWidth="xl">
          <Grid
            container
            item
            md={12}
            justifyContent="flex-end"
            spacing={2}
            className={classes.areaOperacoes}
            style={{ marginLeft: 20 }}
          >
            <Grid item>
              <ButtonComponent
                variant="insert"
                onClick={() => this.handleFuncionarioClickOpen()}
              >
                Cadastrar funcionário
              </ButtonComponent>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="h6" className={classes.title} />
            <div className={classes.demo}>
              <MaterialTable
                columns={[
                  { title: "Nome", field: "nome" },
                  { title: "CPF", field: "id" },
                  { title: "UF", field: "uf" },
                  { title: "Nascimento", field: "data_nascimento" },
                  { title: "Celular", field: "celular" },
                  { title: "E-mail", field: "email" },
                ]}
                data={funcionarios || []}
                title="Funcionários"
                actions={[
                  {
                    icon: "edit",
                    tooltip: "Editar",
                    onClick: (event, row) =>
                      this.props.setFuncionarioId(removerMascaraCPF(row.id)) &&
                      this.handleFuncionarioClickOpen(),
                  },
                  {
                    icon: "delete",
                    tooltip: "Excluir",
                    onClick: (event, row) =>
                      this.handleConfirmOpen(
                        () => this.removeFuncionario(row.id),
                        "Confirma a exclusão permanente do funcionário?"
                      ),
                  },
                ]}
                options={{
                  actionsColumnIndex: -1,
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 50, 100],
                  exportButton: true,
                  exportAllData: true,
                }}
                localization={tableI18n}
              />
            </div>
          </Grid>
          <FuncionarioForm
            open={this.state.open}
            handleClose={this.handleFuncionarioClose}
          />
          <ConfirmDialog
            open={this.state.confirmOpen}
            message={this.state.confirmMessage}
            onClose={this.handleConfirmClose}
          />
        </Container>
      </div>
    );
  }

  removeFuncionario(id) {
    this.props.deleteFuncionario(removerMascaraCPF(id));
  }
}

const mapStateToProps = ({ funcionario: { funcionarios, loading } }) => ({
  funcionarios,
  loading,
});

Funcionarios.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getFuncionarios,
    setFuncionarioId,
    setFuncionario,
    saveFuncionario,
    deleteFuncionario,
  })
)(withRouter(withSnackbar(Funcionarios)));
