import React from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
} from "@material-ui/core";
import CustomMenu from "./Menu";

const CustomCard = ({
  smSize,
  minHeight,
  subheader,
  cardContentHeight,
  dataFields,
  customContent,
  style,
  classes,
  minWidth,
  actionOptions,
}) => {
  return (
    <Grid item sm={smSize} style={style}>
      <Card
        className={classes}
        style={{ minHeight: minHeight || "auto", minWidth: minWidth || "auto" }}
      >
        <CardHeader
          style={{ paddingBottom: "5px", paddingLeft: "12px" }}
          action={actionOptions?.length ? <CustomMenu options={actionOptions}/> : null}
          subheader={subheader}
        />
        <CardContent style={{ height: cardContentHeight, overflow: "auto" }}>
          {dataFields.map(({ label, value }) => (
            <Typography key={label} variant="body2" color="textSecondary">
              <strong>{label}:</strong> {value}
            </Typography>
          ))}
          {customContent && (
            <Grid style={{ paddingTop: 12 }}>{customContent}</Grid>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default CustomCard;
