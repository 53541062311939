export const SIGN_IN = "/";
export const DASHBOARD_FILIADOS = "/filiados/dashboard";
export const DASHBOARD_CASAS = '/filiados/dashboard-casas'
export const ACCOUNT = "/account";
export const PROFILE = "/profile";
export const PASSWORD_FORGET = "/pw-forget";
export const FINANCIAL = "/financial";
export const RECEITAS = "/financial/receitas";
export const EXPENSES = "/financial/expenses";
export const PROJECAO = "/financial/projecao";
export const RELATORIO_DESPESAS = "/financial/relatorio";
export const DESPESAS_A_LIQUIDAR = "/financial/despesas/:data_inicial/:data_final";
export const GRAFICOS_RECEITAS = "/financial/receitas/graficos";
export const ITENS_DESPESA = "/financial/itensdespesa";
export const ACOMPANHAMENTO_ORCAMENTARIO = "/financial/acompanhamento";
export const BENEFICIARIOS = "/financial/beneficiarios";
export const APROVADOR = "/financial/despesas/aprovar";
export const GRAFICOS = "/financial/graficos";
export const DOCUMENTOS = "/financial/documentos";
export const SUPRIMENTOS = "/financial/suprimentos";
export const CONTRATOS = "/financial/contratos";
export const TRANSPARENCIA = "/financial/transparencia"
export const ROLES = "/roles";
export const FILIADOS_RELATORIO = "/filiados/relatorio";
export const RELATORIO_DEPENDENTES = "/filiados/dependentes/relatorio";
export const EVENTOS = "/eventos";
export const INTERACOES = "/interacoes";
export const ITENS_INTERACAO = "/interacoes/itens";
export const FUNCIONARIOS = "/funcionarios";
export const ESCRITORIOS = "/juridico/escritorios";
export const ITENS_RECEITA = "/financial/itensreceita";
export const ESCRITORIO_JURIDICO = "/escritorio";
export const JURIDICO = "/juridico";
export const JURIDICO_ATENDIMENTO = "/juridico/atendimentos";
export const JURIDICO_ATENDIMENTOS_AGENDADOS = "/juridico/atendimentos/agendados";
export const JURIDICO_ATENDIMENTOS_PENDENTES = "/juridico/atendimentos/pendentes";
export const JURIDICO_PROCESSOS = "/juridico/processos";
export const JURIDICO_PROCESSOS_RELATORIO = "/processos/relatorio";
export const OBJETO_PROCESSO = "/juridico/objetos";
export const COMUNICACAO = "/comunicacao";
export const FILIADO = "/filiado";
export const PROCESSOS_FILIADO = "/filiado/processos";
export const FILIADOS = "/filiados";
export const POLITICO_SINDICAL = "/sindical";
export const POLITICO_SINDICAL_TEMAS = "/sindical/temas";
export const POLITICO_SINDICAL_TEMA = "/sindical/tema/:temaId/:sectionIndex?";
export const PESQUISA_DE_SATISFACAO = "/Satisfacao"
export const AUTORIDADES = "/Autoridades"
export const PERFIL_AUTORIDADE = "/Autoridades/:politicoId"
export const CONSIGNACAO = '/Consignacao'
export const SINDEX = '/Sindex'
export const BRB = '/Brb'
