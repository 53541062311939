import React from "react";
import { Field, reduxForm } from "redux-form";
import { compose } from "recompose";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Select from "../commons/Select";
import MenuItem from "@material-ui/core/MenuItem";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import InputText from "../commons/InputText";
import { withSnackbar } from "notistack";
import styles from "./styles";
import {
  getFuncionario,
  criarFuncionario,
  saveFuncionario,
} from "../../actions/funcionario";
import { cpfMask, dateMask, removerMascaraCPF } from "../commons/masks";
import * as config from "../UserDetail/config";

class FuncionarioForm extends React.Component {
  constructor(props) {
    super(props);
    this.save = this.save.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {
    if (this.props.funcionarioId)
      this.props.getFuncionario(this.props.funcionarioId);
  }

  save(funcionario) {
    const { funcionarioId } = this.props;
    funcionario.id = removerMascaraCPF(funcionario.id);
    if (funcionarioId) {
      this.props.saveFuncionario(funcionarioId, funcionario, () =>
        this.successHandler()
      );
    } else {
      this.props.criarFuncionario(funcionario, () => this.successHandler(true));
    }
  }

  successHandler(reload) {
    const { reset, handleClose, enqueueSnackbar } = this.props;
    enqueueSnackbar("Funcionario salvo com sucesso!", {
      variant: "success",
    });
    reset("funcionario");
    handleClose(reload);
  }

  errorHandler() {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar("Erro ao salvar funcionario.", {
      variant: "error",
    });
  }

  render() {
    const { handleSubmit, handleClose, pristine, submitting, reset } =
      this.props;

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose.bind(this, null)}
        fullWidth
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Funcionário</DialogTitle>
        <form onSubmit={handleSubmit(this.save)}>
          <DialogContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item sm={6}>
                <Field name="nome" component={InputText} label="Nome" />
              </Grid>
            </Grid>
            <Grid item sm={4}>
              <Field name="id" component={InputText} label="CPF" {...cpfMask} />
            </Grid>
            <Grid item sm={2}>
              <Field
                name="data_nascimento"
                component={InputText}
                label="Nascimento"
                {...dateMask}
              />
            </Grid>
            <Grid item sm={2}>
              <Field name="celular" component={InputText} label="Celular" />
            </Grid>
            <Grid item sm={4}>
              <Field name="email" component={InputText} label="E-mail" />
            </Grid>
            <Grid item sm={1}>
              <Field name="uf" component={Select} label="Estado">
                <MenuItem value="">
                  <em>Selecione</em>
                </MenuItem>
                {config.estados.map((e) => (
                  <MenuItem key={e} value={e}>
                    {e}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => reset() && handleClose()} color="default">
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={pristine || submitting}
              color="primary"
            >
              Salvar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

FuncionarioForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "id",
    "nome",
    "uf",
    "data_nascimento",
    "celular",
    "email",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = " Obrigatório";
    }
  });
  return errors;
};

const mapStateToProps = ({ funcionario: { funcionario, funcionarioId } }) => ({
  funcionario: funcionario,
  funcionarioId: funcionarioId,
  initialValues: funcionario,
});

export default compose(
  connect(mapStateToProps, {
    getFuncionario,
    criarFuncionario,
    saveFuncionario,
  }),
  withStyles(styles)
)(
  reduxForm({
    form: "funcionario",
    validate,
    enableReinitialize: true,
  })(withSnackbar(FuncionarioForm))
);
