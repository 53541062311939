import {
  CONSIGNACOES_REQUEST, CONSIGNACOES_SET
} from "../actions/types";
const INITIAL_STATE = {
  consignacoes: [],
  consignacao: null,
  consignacaoId: null,
  historico: [],
  loading: false
};

function consignacao(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CONSIGNACOES_SET: {
      return { ...state, consignacoes: action.consignacoes, loading: false }
    }
    case CONSIGNACOES_REQUEST: {
      return { ...state, loading: true }
    }
    default:
      return state;
  }
}

export default consignacao;
