export default (theme) => ({
    root: {
      flexGrow: 1,
      maxWidth: 752
    },
    demo: {
      backgroundColor: theme.palette.background.paper
    },
    title: {
      margin: `${theme.spacing(1) * 4}px 0 ${theme.spacing(1) * 2}px`
    }
  });